import React, { useState } from "react";
import Title from "../../components/title/Title";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ShowLaundry } from "../../API/LaundryAPI";
import ViewLaundryCartModal from "../../Components/ViewLaundryCartModal";
import { Accordion, AccordionTab } from "primereact/accordion";
import TaxInclusive from "../../Components/TaxInclusive.";
import LaundryCarosle from "../../Components/LaundryCarosle";
import { Button } from "react-bootstrap";
import LaundryLoader from "../../Components/Loaders/LaundryLoader";
import BannerHome from "../../components/banner-home/BannerHome";
import BannerServices from "../../components/banner-home/BannerServices";
import "./HotelServices.scss";
import NewFooter from "../../NewFooter";

const LaundryService = () => {
  const location = useLocation();
  const params = useParams();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
  };
  const laundry_uuid = params.laundry_uuid;
  const lang = localStorage.getItem("lang");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  let la;
  let images;
  let bgColor;
  let color;
  let text;
  let price_expression;
  let laundryId;
  let service_fee;
  let discount_percentage;
  let vat;
  let min_amount;
  let use_banners_in_popup = false;
  const { data, isLoading, error } = ShowLaundry(
    params.room_uuid,
    laundry_uuid,
    lang
  );
  if (!isLoading) {
    if (data.data.status === false) {
      navigate(`/${room_uuid}/qr-scan`);
    } else {
      la = data.data.data;
      images = la?.header_images;
      use_banners_in_popup = la?.use_banners_in_popup;
      price_expression = la?.price_expression;
      color = data.data.data.color;
      bgColor = data.data.data.header_color;
      text = data.data.data.center_header;
      laundryId = la.laundry.id;
      vat = la.laundry.vat;
      min_amount = la.laundry.min_amount;
      service_fee = la.laundry.service_fee;
      discount_percentage = la.laundry.discount_percentage;
    }
  }

  return (
    <div className="food">
      <Title title={"Laundry"} />
      <main className=" pad-top-0">
        {isLoading ? (
          <LaundryLoader />
        ) : (
          <>
            <BannerServices headerImages={images} />
            <div className="full-div padd-div" style={{ marginTop: "45px" }}>
              <Button
                className="reg-btn big d-flex justify-content-center align-items-center laundary-add-btn"
                onClick={handleShow}
              >
                <i className="fa-solid fa-plus"></i>
                <span>{t("Add Item")}</span>
                <span></span>
              </Button>
              <div className="view-cart-body text-center">
                <h3 className="text-center">
                  <TaxInclusive />
                </h3>
              </div>
              <Accordion
                multiple
                expandIcon="fa fa-plus"
                collapseIcon="fa fa-minus"
                className="mb-4"
              >
                {la.laundry.disclaimers_name &&
                la.laundry.disclaimers_name?.length > 0 ? (
                  <AccordionTab
                    key={"Disclaimer"}
                    header={t("Disclaimer")}
                    disabled={false}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: la.laundry.disclaimers_name,
                      }}
                    />
                  </AccordionTab>
                ) : null}
                {la.laundry.taxiDisclaimer_name &&
                la.laundry.taxiDisclaimer_name != "" ? (
                  <AccordionTab
                    key={"Disclaimer1"}
                    header={t("Disclaimer Name")}
                    disabled={false}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: la.laundry.taxiDisclaimer_name,
                      }}
                    />
                  </AccordionTab>
                ) : null}
                {la.laundry.termsAndCondition_name &&
                la.laundry.termsAndCondition_name != "" ? (
                  <AccordionTab
                    key={"Disclaimer3"}
                    header={t("Terms and Conditions")}
                    disabled={false}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: la.laundry.termsAndCondition_name,
                      }}
                    />
                  </AccordionTab>
                ) : null}
              </Accordion>
            </div>
          </>
        )}
        <NewFooter />
        <ViewLaundryCartModal
          show={show}
          handleClose={handleClose}
          laundryId={laundryId}
          laundry_uuid={laundry_uuid}
          itemChange={null}
          vat={vat}
          service_fee={service_fee}
          discount_percentage={discount_percentage}
          min_amount={min_amount}
          data={data}
          images={images}
          use_banners_in_popup={use_banners_in_popup}
          theme={true}
        />
      </main>
    </div>
  );
};

export default LaundryService;
