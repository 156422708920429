import React from 'react'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const CarRentalLoader = () => {
    const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10,]
    return (
        <>
            <div className="add-post-container add-cart">
                <div className="add-cart-post new spa ">
                    <div className="add-cart-post-inner">
                        <div className="animated-background bg-skimmer"></div>
                        <span
                            className="img-pnl navigate"

                        >
                            <img src="" />
                        </span>
                        <div
                            className="txt-pnl navigate"

                        // onClick={() => navigate("/SpaAddService")}
                        >
                            <h2></h2>
                            <p></p>
                            <div className="flex-div align-item-center">
                                <h3></h3>
                                <Link
                                    to=""
                                    className="reg-btn service"
                                >
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ul className="display-list car-list two-items v2">
                {
                    items.map((item, idx) => {
                        return (
                            <li key={idx}>
                                <Button >
                                    <div
                                        className="img-pnl"

                                    ></div>
                                    {/* <img src={car.images[0]} alt="" /> */}
                                    <div
                                        className="animated-background bg-skimmer"
                                    ></div>
                                    <p>

                                    </p>
                                </Button>
                            </li>
                        )
                    })
                }

            </ul>
        </>
    )
}

export default CarRentalLoader