import React from "react";
import { Link } from "react-router-dom";
import PharmacyCartPost from "./PharmacyCartPost";
import { Button } from "react-bootstrap";
import Post1 from "../assets/images/Pharmacy/Medicine.png";
function ViewPharmacyCartModal() {
  return (
    <>
      <div className="add-post-container full-post">
        <div className="add-cart-post">
          <div className="add-cart-post-inner">
            <span className="img-pnl">
              <img src={Post1} alt="Post" />
            </span>
            <div
              className="txt-pnl navigate"
              // onClick={() => navigate("/PharmacyOrderPayment")}
            >
              <h2>Brufen Rapid 400Mg</h2>

              <p>
                - 10 Purple Orchids
                <br></br>- Wrapped in Pink Paper
                <br></br>- Tied with Pink Ribbon
              </p>
              <div className="flex-div">
                <h3>AED 110.00</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="check-list-container">
        <div className="full-div padd-div">
          <Link
            className="reg-btn big w-100 flex-div"
            to="/PharmacyOrderPayment"
          >
            <div>
              <i className="fa fa-plus-square"></i> Add to Cart
            </div>
            <div>AED 30.00</div>
          </Link>
        </div>
      </div>
    </>
  );
}
export default ViewPharmacyCartModal;
