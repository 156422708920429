import React, { useEffect } from "react";
import HeaderInner from "../../Components/HeaderInner";
import CarRentalLoader from "../../Components/Loaders/CarRentalLoader";
import Search from "../../Components/Search";
import { Zoom } from "react-reveal";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ShowScanRoomDining } from "../../API/In-Room-DiningAPI";
import Carosle from "../../Components/Carosle";
import SearchSc from "../../Components/SearchSc";
import CombImg from "../../assets/images/cambo_meal.jpeg";

const InRoomDiningScan = () => {
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const params = useParams();
  const navigate = useNavigate();
  const isbooking = rawPath.includes("RestaurantBooking");
  const lang = localStorage.getItem("lang");
  let restaurant;
  let restaurantCategories;
  let images = [];
  let isVideo = false;
  let bgColor;
  let color;
  let has_combo_meals;

  let text;
  let r_uuid;
  let languages;
  let terms_and_conditions;
  const { data, isLoading, error } = ShowScanRoomDining(room_uuid, lang);

  if (!isLoading) {
    console.log("stata", data.data);
    if (data.data.status == false) {
      // navigate(`/${room_uuid}/qr-scan`);
    } else if (!error) {
      restaurant = data.data.data;
      r_uuid = restaurant.restaurant.id;
      has_combo_meals = restaurant.has_combo_meals;

      restaurantCategories = restaurant.Restaurant_menuCategoriesResource;
      isVideo = restaurant.restaurant.real_video ? true : false;
      terms_and_conditions = restaurant.restaurant.terms_and_conditions;

      if (restaurant.restaurant.real_video) {
        if (restaurant.restaurant?.images?.length > 0) {
          images = [
            { image: restaurant.restaurant.real_video },
            ...restaurant.restaurant?.images,
          ];
        }
      } else {
        if (restaurant.restaurant?.images?.length > 0) {
          images = restaurant.restaurant?.images;
        }
      }
      color = data.data.data.color;
      languages = data.data.data.languages;
      bgColor = data.data.data.headerColor;
      text = data.data.data.center_header;

      if (restaurantCategories?.length == 1) {
        if (restaurantCategories[0]?.count_sub_categories != 0) {
          navigate(
            `/${room_uuid}/restaurant-menu/cat/${restaurantCategories[0].id}`,
            { replace: true }
          );
        } else {
          navigate(
            `/${room_uuid}/restaurant-menu/${restaurantCategories[0].id}`,
            { replace: true }
          );
        }
      }
      localStorage.setItem("currency", data.data.data.currency);
      document.documentElement.style.setProperty("--color", color);
      document.documentElement.style.setProperty("--color_t", `${color}b3`);
      document.documentElement.style.setProperty("--header_color", bgColor);
      // window.scrollTo({
      //   top: 0,
      //   left: 0,
      // });
    }
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  return (
    <>
      <HeaderInner headerName={text} scan={true} languages={languages} />
      <main className="bg-white pad-top-0 resturant_category">
        {isLoading ? (
          <CarRentalLoader />
        ) : (
          <>
            {images?.length > 0 && (
              <header className="img-header m-0">
                <Carosle headerImages={images} isVideo={isVideo} />
              </header>
            )}

            <SearchSc
              placeholder="Search for Categories..."
              room_uuid={room_uuid}
              text={text}
            />
            {terms_and_conditions != "" &&
              (terms_and_conditions != undefined ? (
                <>
                  {/* <p className="text-center">Terms & Conditions</p> */}
                  <div
                    className="mx-4 divhtml"
                    dangerouslySetInnerHTML={{
                      __html: terms_and_conditions,
                    }}
                  />
                </>
              ) : null)}
            <ul className="display-list two-items restaurant-list mt-2 v2">
              {has_combo_meals && (
                <Zoom key={"001"}>
                  <li className={"restaurant-option"}>
                    <Link to={`/${room_uuid}/restaurant-menu/combo-meals`}>
                      <div className="closed-tag"></div> <div></div>
                      <div className="img-pnl">
                        <img src={CombImg} alt="" />
                      </div>
                      <div className="bg-layer"></div>
                      <p>Combo Meals</p>
                    </Link>
                  </li>
                </Zoom>
              )}
              {restaurantCategories
                ? restaurantCategories.map((category, idx) => {
                    console.log(category.name, encodeURI(category.image));
                    return (
                      <Zoom key={idx}>
                        <li
                          className={
                            category?.is_closed
                              ? "closed restaurant-option"
                              : "restaurant-option"
                          }
                          // /:room_uuid/RestaurantBooking/RestaurantMenu/:id/:restaurnat_id/:cat_id
                        >
                          <Link
                            to={
                              category.count_sub_categories != 0
                                ? `/${room_uuid}/restaurant-menu/cat/${category.id}`
                                : `/${room_uuid}/restaurant-menu/${category.id}`
                            }
                          >
                            <div className="closed-tag"></div> <div></div>
                            {category?.is_closed && (
                              <span className="open_at">
                                {category.opens_at
                                  ? `Opens at ${category.opens_at}`
                                  : `Closed`}
                              </span>
                            )}
                            <div
                              className="img-pnl"
                              // style={{
                              //   backgroundImage: `url(${category.image})`,
                              // }}
                            >
                              <img src={encodeURI(category.image)} alt="" />
                            </div>
                            {/* {console.log(category.image.replace(/ /g, "%20"))} */}
                            <div className="bg-layer"></div>
                            <p>{category.name}</p>
                          </Link>
                        </li>
                      </Zoom>
                    );
                  })
                : null}
            </ul>
          </>
        )}
      </main>
    </>
  );
};

export default InRoomDiningScan;
