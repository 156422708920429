import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Zoom } from "react-reveal";
import HeaderInner from "../../Components/HeaderInner";
import axios from "../../axios";
import PharmacyLoader from "../../Components/Loaders/PharmacyLoader";
import { ShowPharmacies, ShowPharmacy } from "../../API/Pharmacy";
import PharmacyComponent from "../../Components/PharmacyComponent";
import SpaLoader from "../../Components/Loaders/SpaLoader";

function Pharmacy() {
  const [enableClass, setEnableClass] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [pharmacies, setPharmacies] = useState();
  const [title, setTitle] = useState();
  // const [parmacy, setParmacy] = useState({ id: "", uuid: "" });
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  setTimeout(() => {
    setEnableClass(false);
  }, 3000);
  const navigate = useNavigate();
  const lang = localStorage.getItem("lang");

  let parmacy = { id: "", uuid: "" };
  let pharmacies_;
  let pharmacies;
  let bgColor;
  let color;
  let text;
  const { data, isLoading, error } = ShowPharmacy(room_uuid, lang);
  if (!isLoading) {
    if (data.data.status === false) {
      navigate(`/${room_uuid}/qr-scan`);
    } else {
      pharmacies_ = data.data.data;

      pharmacies = pharmacies_.pharmacies;
      if (pharmacies.length > 0) {
        parmacy.id = pharmacies_.pharmacies[0].id;
        parmacy.uuid = pharmacies_.pharmacies[0].uuid;
        color = pharmacies_.color;
        bgColor = pharmacies_.header_color;
        text = pharmacies_.title;
        // console.log("ShowPharmacy", pharmacies_);
        if (pharmacies?.length == 1) {
          navigate(
            `/${room_uuid}/pharmacy/category/${pharmacies[0].uuid}/id/${pharmacies[0].id}`,
            { replace: true }
          );
        }
      } else {
        navigate(`/${room_uuid}/qr-scan`);
      }
    }
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  // useEffect(() => {
  //   const getData = async () => {
  //     setLoading(true)
  //     axios.get(`/${room_uuid}/pharmacies`).then((res) => {
  //       const data = res.data.data;
  //       setParmacy({
  //         id: data.pharmacies[0].id,
  //         uuid: data.pharmacies[0].uuid,
  //       });
  //       axios
  //         .get(`/${room_uuid}/pharmacy/${data.pharmacies[0].uuid}`)
  //         .then((res) => {
  //           const data = res.data.data;
  //           setPharmacies(data.categories);
  //           setTitle(data?.title)
  //           console.log(data);
  //         });
  //       // setPharmacies(data.categories);
  //       console.log(data);
  //       setLoading(false)
  //     });
  //   };
  //   getData();
  // }, []);
  // console.log("tit",);
  return (
    <>
      <HeaderInner headerName={text} bgColor={bgColor} color={color} />
      <main className="home-main bg-white back-white pad-top-0">
        {isLoading ? (
          <SpaLoader />
        ) : pharmacies.length > 1 ? (
          pharmacies?.map((pharmacie, idx) => {
            return (
              <div key={idx}>
                <Zoom>
                  <div
                    className="con-img-container v3"
                    onClick={() =>
                      navigate(
                        `/${room_uuid}/pharmacy/category/${pharmacie.uuid}/id/${pharmacie.id}`
                      )
                    }
                  >
                    <div className="con-img">
                      <img src={pharmacie.image} alt="" />
                      <p></p>
                    </div>
                  </div>
                  <div className="con-text-container">
                    <p>{pharmacie.name}</p>
                    <hr className="mb-0 dark" />
                  </div>
                </Zoom>
              </div>
            );
          })
        ) : null}
      </main>
    </>
  );
}
export default Pharmacy;
