import React from "react";
import { ShowPharmacies } from "../API/Pharmacy";
import PharmacyLoader from "./Loaders/PharmacyLoader";
import { Link, useNavigate } from "react-router-dom";
import { Zoom } from "react-reveal";
import { ShowSupermarketCategories } from "../API/Supermarket";
import ImageSuperMarket from "./ImageSuperMarket";
import Search from "./Search";
import SupermarketCarosle from "./SupermarketCarosle";
import BannerServices from "../components/banner-home/BannerServices";

const SubermarketComponent = ({ room_uuid, ph_uuid, theme }) => {
  console.log("phuuid", ph_uuid);
  const lang = localStorage.getItem("lang");
  const navigate = useNavigate();
  let pharmacies;
  let bgColor;
  let color;
  let text;
  let ph_id;
  let headerImages;
  const { data, isLoading, error } = ShowSupermarketCategories(
    room_uuid,
    ph_uuid,
    lang
  );
  if (!isLoading) {
    if (data.data.status === false) {
      navigate(`/${room_uuid}/qr-scan`);
    } else {
      pharmacies = data.data.data.categories;
      color = data.data.data.color;
      bgColor = data.data.data.header_color;
      text = data.data.data.center_header;
      headerImages = data.data.data?.supermarket?.banners;
      ph_id = data.data.data?.supermarket?.id;
      console.log("ShowSupermarketCategories", pharmacies);
    }
  }

  return (
    <>
      {isLoading ? (
        <PharmacyLoader />
      ) : (
        <>
          {headerImages?.length > 0 &&
            (theme ? (
              <BannerServices headerImages={headerImages} />
            ) : (
              <header className="img-header m-0 mb-4">
                <SupermarketCarosle headerImages={headerImages} />
              </header>
            ))}
          <Search
            placeholder="Search for Categories..."
            room_uuid={room_uuid}
            category="Supermarket"
          />
          <span className="supermarket_name">Categories</span>

          <ul className="display-list three-items suber_market_cat v2 v3">
            {pharmacies
              ? pharmacies.map((pharmacy, i) => {
                  return (
                    <>
                      <li key={i}>
                        <Zoom>
                          <Link
                            className="super_link"
                            to={
                              pharmacy?.show_categories == 0 &&
                              pharmacy.activeSubCategories.length > 0
                                ? `/${room_uuid}/SupermarketSubMenu/${ph_id}/${ph_uuid}/${pharmacy.id}?all`
                                : pharmacy.activeSubCategories.length > 0
                                ? `/${room_uuid}/SupermarketSubCategory/${ph_uuid}/${pharmacy.id}`
                                : `/${room_uuid}/SupermarketSubMenu/${ph_id}/${ph_uuid}/${pharmacy.id}`
                            }
                            state={{ theme: theme }}
                          >
                            <div className="img-pnl">
                              <ImageSuperMarket src={pharmacy.image} />
                            </div>
                            {/* <img src={pharmacy.image} alt="" /> */}
                            {/* <div></div> */}
                            {/* <div className="bg-layer"></div> */}
                            <p className="text-black">{pharmacy.name}</p>
                          </Link>
                        </Zoom>
                      </li>
                    </>
                  );
                })
              : null}
          </ul>
        </>
      )}
    </>
  );
};

export default SubermarketComponent;
