import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import HeaderInner from "../../Components/HeaderInner";
import CarBookModal from "../../Components/CarBookModal";
import Iframe from "react-iframe";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import axios from "../../axios";
import PhoneInput from "react-phone-input-2";
import { object, string, number, date, InferType } from "yup";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";
import Taxipic from "../../assets/images/taxipic.jpg";
import { InputText } from "primereact/inputtext";
import VerificationInput from "react-verification-input";
import { useTranslation } from "react-i18next";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import ReactGA from "react-ga4";
import TaxInclusive from "../../Components/TaxInclusive.";
import MillenniumTaxi from "../../Components/MillenniumTaxi";
import Title from "../../components/title/Title";
import BannerServices from "../../components/banner-home/BannerServices";

function HHTaxi() {
  const toast = useRef(null);
  const inputRef = useRef(null);

  const navigate = useNavigate();
  const [phone, setPhone] = useState();
  const [selectedItem, setSelectedItem] = useState("");
  const [timeInput, setTimeInput] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [dateInput, setDateInput] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [taxiTypes, setTaxiTypes] = useState();
  const [taxi, setTaxi] = useState({
    email: "",
    phone: "",
    company_name: "",
    company_id: "",
  });
  const [address, setAddress] = useState("");
  const [lng, setLng] = useState("");
  const [lat, setLat] = useState("");
  const [taxiBook, setTaxiBook] = useState({
    date: "",
    time: "",
    taxi_type: "",
    email: "",
    phone: "",
  });

  const [showRequired, setShowRequired] = useState(false);
  const [verification_code, setVerification_code] = useState("");
  // console.log("verification_code", verification_code.length);
  const handleCloseMin = () => {
    setShowRequired(false);
    setVerification_code("");
  };

  const handelPlaceChange = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      setAddress(place.formatted_address);
      setLng(place.geometry.location.lng());
      setLat(place.geometry.location.lat());
      ReactGA.event({
        category: "Select Location in taxi page",
        action: "Click",
        value: `location: ${place.formatted_address}`,
      });
      // console.log("address", place.formatted_address);
      // console.log("lat", place.geometry.location.lat());
      // console.log("lng", place.geometry.location.lng());
    }
  };

  const [company, setCompany] = useState({
    company_id: null,
    company_name: "",
  });
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const params = useParams();
  const lang = localStorage.getItem("lang");
  const hh_uuid = params.hh_uuid;
  let taxiSchema = object({
    phone: string(),
    email: string().email(),
    date: string(),
    time: string(),
    address: string(),
    taxi_type: number(),

    // createdOn: date().default(() => new Date()),
  });
  const handleTime = (e) => {
    setTimeInput(e.target.value);
  };
  const handleDate = (e) => {
    setDateInput(e.target.value);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTaxiBook((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const [errPhone, setErrPhone] = useState("");
  const [errTaxi, setErrTaxi] = useState("");
  const [errAddress, setErrAddress] = useState("");
  // const [loading, setLoadign] = useState(false)

  const checkInput = () => {
    if (taxiBook.phone == "") {
      setErrPhone("The phone field is required.");
    } else {
      setErrPhone("");
    }
    if (taxiBook.address == "") {
      setErrAddress("The address field is required.");
    } else {
      setErrAddress("");
    }
    if (selectedItem == "") {
      setErrTaxi("The Car type is required.");
    } else {
      setErrTaxi("");
    }
  };
  useEffect(() => {
    const currentTime = new Date();

    // Format the time as HH:mm (hours and minutes)
    let hours = (currentTime.getHours() + 1).toString().padStart(2, "0");

    const minutes = currentTime.getMinutes().toString().padStart(2, "0");

    const currentDate = new Date();
    // console.log("formattedTime", formattedTime);
    // Format the date as YYYY-MM-DD (required by input type="date")

    const formattedDate = currentDate.toISOString().slice(0, 10);
    let day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear().toString().padStart(2, "0");
    console.log("hours", hours);
    if (hours == "24") {
      hours = "01";
      // console.log("day", day);
      day = day + 1;
    }
    const formattedTime = `${hours}:${minutes}`;
    const formatDate = `${year}-${month}-${day}`;
    setDateInput(formatDate);
    // console.log("formatDate", formatDate);
    setTimeInput(formattedTime);
  }, []);
  const currentTimem = new Date(`${dateInput}T${timeInput}`);
  const dubaiTime = currentTimem.toLocaleString("en-US", {
    timeZone: "Asia/Dubai",
  });

  const dateObj = new Date(dubaiTime);
  const hoursD = dateObj.getHours().toString().padStart(2, "0");
  const minutesD = (dateObj.getMinutes() + 1).toString().padStart(2, "0");
  const time = `${hoursD}:${minutesD}`;
  const handleBook = async () => {
    checkInput();
    if (taxiBook.phone != "" && selectedItem != "") {
      const taxi_type = taxiTypes.find((item) => item.name === selectedItem);
      setIsDisabled(true);
      // console.log(selectedItem, taxiTypes, taxi_type);
      // console.log("taxi_type", selectedItem);
      const booked = {
        phone: taxiBook.phone,
        address: address,
        email: taxiBook.email,
        date: dateInput,
        time: time,
        taxi_type: selectedItem.id,
        company_name: company.company_name,
        company_id: company.company_id,
        verification_code: verification_code,
        lat: lat.toString(),
        lng: lng.toString(),
      };
      const anser = await taxiSchema.validate(booked);
      axios
        .post(`/${room_uuid}/hh_taxi/hh-taxibooking-book`, anser, {
          headers: {
            lang: lang,
          },
        })
        .then((res) => {
          setIsDisabled(false);

          console.log("res", res);
          if (res.data.status == false) {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: res.data.message,
              life: 4000,
            });
          }
          if (res.data.status) {
            if (res.data?.data == "OTP Required") {
              setShowRequired(true);
              ReactGA.event({
                category: "Open popup OTP after send api ",
                action: "Click",
                value: ``,
              });
              // toast.current.show({
              //   severity: "error",
              //   summary: "Error",
              //   detail: "OTP Required",
              //   life: 4000,
              // });
            } else {
              navigate(`/${room_uuid}/HKOrderPlaced`);
            }
          }
        })
        .catch((err) => {
          setIsDisabled(false);
          console.log(err);
        });
      // navigate("/HCOrderPlaced");
    }
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      axios
        .get(`/${room_uuid}/hh_taxi/booking-page/${hh_uuid}`, {
          headers: {
            lang: lang,
          },
        })
        .then((res) => {
          const data = res.data.data;
          setTaxiTypes(data.taxiTypes);
          setCompany({
            company_name: data.company.name,
            company_id: data.company.id,
          });
          console.log("MY", data);
          setLoading(false);
        })
        .catch((err) => {
          if (err?.response.status == 404) {
            navigate(`/not-found`);
          }
        });
    };
    getData();
  }, []);
  console.log("see", selectedItem);
  const is_grand_millennium_barsha_hotel = localStorage.getItem(
    "is_grand_millennium_barsha_hotel"
  );

  const new_design = localStorage.getItem("new_design");
  const theme = new_design == "true";
  // <HeaderInner headerName={""} />
  return (
    <div className={theme ? "food" : ""}>
      {theme ? <Title title={"TAXI"} /> : <HeaderInner headerName={"TAXI"} />}
      <main className="home-main bg-white back-white book-table pad-top-0 taxii">
        <Toast ref={toast} position="bottom-center" />

        {is_grand_millennium_barsha_hotel == "true" ? (
          <MillenniumTaxi />
        ) : loading ? (
          <SpaLoader />
        ) : (
          <>
            {theme ? (
              <BannerServices headerImages={[Taxipic]} />
            ) : (
              <div className="con-order-img">
                <img src={Taxipic} alt="" />
              </div>
            )}
            <div className="spacer-40"></div>
            <div className="taxi-input">
              <div className="bk-date">
                <LoadScript
                  onLoad={false}
                  googleMapsApiKey="AIzaSyCZh3onn9LU3YC0YVKrM1slDxYCDhqoX4k"
                  id={`google-map-script-${lang}`}
                  libraries={["places"]}
                >
                  <StandaloneSearchBox
                    onLoad={(ref) => (inputRef.current = ref)}
                    onPlacesChanged={handelPlaceChange}
                  >
                    <Form.Group
                      className="mb-3 i-f-small row align-items-center justify-content-start"
                      controlId="formBasicnamem"
                    >
                      <p className="col-3 mb-0 mr-2 ">{t("Destination")}</p>
                      <Form.Control
                        id="destination"
                        type="text"
                        name="address"
                        placeholder={t("Enter Your Destination")}
                        className="col-8 three "
                      />
                    </Form.Group>
                  </StandaloneSearchBox>
                </LoadScript>

                {errAddress != "" && (
                  <span className="err_msg ml-2">{t(`${errAddress}`)} </span>
                )}
              </div>
              <div className="bk-date">
                <Form.Group
                  className="mb-3 i-f-small row align-items-center justify-content-start"
                  controlId="formBasicname"
                >
                  <p className="col-3 m-0 ">{t(`Booking Date`)}</p>

                  <Form.Control
                    type="date"
                    value={dateInput}
                    min={new Date().toISOString().slice(0, 10)}
                    date-format="DD/MM/YYYY"
                    onChange={handleDate}
                    placeholder="Enter Your Email"
                    className="col-8 ml-2 date-input dt-input three "
                  />
                </Form.Group>
              </div>
              <div className="bk-time mb-4">
                <Form.Group
                  className="mb-3 i-f-small row align-items-center justify-content-start "
                  controlId="formBasicname"
                >
                  <p className="col-3 m-0 no-break">{t(`Booking Time`)}</p>
                  <Form.Control
                    type="time"
                    value={timeInput}
                    onChange={handleTime}
                    placeholder="Enter Your Email"
                    className="col-8 ml-2 time-input dt-input three "
                  />
                </Form.Group>
              </div>
              <div className="con-email mb-4 px-4">
                <Form.Group
                  className="mb-3 i-f-small row align-items-center justify-content-start"
                  controlId="formBasicname"
                >
                  <Form.Control
                    type="email"
                    placeholder={t(`Your Email (Optional)`)}
                    name="email"
                    value={taxiBook.email}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </div>
              <div className="con-phone">
                <Form.Group className="mb-3 i-f-small pl-2 pr-4 w-100">
                  <PhoneInput
                    country={"ae"}
                    containerclassName="ml-4 "
                    value={taxiBook.phone}
                    onChange={(phone) => {
                      setTaxiBook((prev) => ({ ...prev, phone }));
                    }}
                  />
                </Form.Group>
                {errPhone != "" && (
                  <span className="err_msg ml-2">{t(`${errPhone}`)}</span>
                )}
              </div>
            </div>
            <Row>
              <Col xs="12" className="car-text d-flex ">
                <p> {t(`Car Type`)}</p>
              </Col>
              <Col xs="11" className="car-type car-type-drop ml-2">
                <div
                  className={
                    isOpen
                      ? "f-dropdown selectDropdown open"
                      : "f-dropdown selectDropdown"
                  }
                  onClick={() => (isOpen ? setIsOpen(false) : setIsOpen(true))}
                >
                  <select
                    class="f-control f-dropdown"
                    placeholder="Car type"
                    style={{ color: "#000000 !important" }}
                    name="taxi_type"
                    id="products"
                  >
                    <option value=""></option>
                  </select>
                  <ul style={isOpen ? {} : { display: "none" }}>
                    {taxiTypes &&
                      taxiTypes.map((item) => {
                        return (
                          <li
                            class=""
                            key={item.id}
                            onClick={() => {
                              setSelectedItem(item);
                              ReactGA.event({
                                category: "Select car type in taxi page",
                                action: "Click",
                                value: `location: ${item.name}`,
                              });
                            }}
                          >
                            <a data-val="1">
                              <img src={item.icon} />
                              <span>{item.name}</span>
                            </a>
                          </li>
                        );
                      })}
                  </ul>
                  <span>
                    {selectedItem ? (
                      <>
                        <img src={selectedItem.icon} />
                        <span>{selectedItem.name}</span>
                      </>
                    ) : (
                      <span>{t(`Choose the car type`)}</span>
                    )}
                  </span>
                </div>
              </Col>
              {errTaxi != "" && (
                <span
                  className="err_msg taxi_err"
                  style={{ marginTop: "2px", marginLeft: "33px" }}
                >
                  {t(`${errTaxi}`)}
                </span>
              )}
            </Row>
            <div className="padd-div text-center mt-5">
              <Button
                className="reg-btn order-btn"
                onClick={handleBook}
                disabled={isDisabled}
              >
                {t("Book")}
              </Button>
            </div>
            <div className="padd-div text-center">
              <h3 className="">
                <TaxInclusive />
              </h3>
            </div>
            <div className="spacer-40 my-5"></div>
          </>
        )}
        {/* <Iframe
          url="https://54ssl.mobilityae.com"
          width="640px"
          height="320px"
          id=""
          className=""
          display="block"
          position="relative"
        /> */}
        {/* <iframe
          style={{ height: "80vh" }}
          src="https://54ssl.mobilityae.com"
          title="Web Frame"
          width="100%"
          height="100vh"
          frameborder="0"
          // allowfullscreen
        ></iframe> */}
        <Modal
          show={showRequired}
          onHide={handleCloseMin}
          backdrop="static"
          keyboard={false}
          className={theme ? "food-modal" : ""}
          style={{ minHeight: "150px", height: "auto", marginTop: "150px" }}
        >
          <Modal.Body
            style={{
              padding: "20px",
              height: "auto",
            }}
          >
            <div className="otp_div">
              <h5>OTP Verification</h5>
              <p>
                Please enter the OTP sent to your
                {taxiBook.email !== ""
                  ? ` email (${taxiBook.email}) and `
                  : " "}
                phone number ({taxiBook.phone}) to complete the verification
                process. Thank you!
              </p>
              <VerificationInput
                // onChange={(e) => setVerification_code(e)}

                inputField={{
                  onChange: (e) => {
                    setVerification_code(e.nativeEvent.target.value);
                  },
                }}
              />
              <div className="butons_opt">
                <Button
                  className="canc reg-btn order-btn"
                  onClick={handleCloseMin}
                  disabled={isDisabled}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  className="reg-btn order-btn"
                  onClick={handleBook}
                  disabled={isDisabled || verification_code.length != 6}
                >
                  {t("verify")}
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    </div>
  );
}
export default HHTaxi;
