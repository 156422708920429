import React, { useEffect, useState } from "react";
import HeaderInner from "../../Components/HeaderInner";
import BookingCard from "./BookingCard";
import axios from "../../axios";
import { useLocation, useNavigate } from "react-router-dom";
import RestaurantsLoader from "../../Components/Loaders/RestaurantsLoader";
import { ShowRestaurantBooking } from "../../API/RestaurantBookingAPI";
import ErrorPage from "../../Components/ErrorPage";

const RestaurantBooking = () => {
  const location = useLocation();
  const rawPath = location.pathname;
  let room_uuid = rawPath.split("/")[1];
  const lang = localStorage.getItem("lang");
  const navigate = useNavigate();
  // console.log("lang", lang);
  let notFound = "";
  let restaurants;
  let menu;
  let color;
  let bgColor;
  let text;
  const { data, isLoading, error } = ShowRestaurantBooking(room_uuid, lang);
  if (!isLoading) {
    if (data.data.status === false) {
      navigate(`/${room_uuid}/qr-scan`);
    } else {
      menu = data.data.data;
      restaurants = menu?.restaurants;
      color = menu?.color;
      bgColor = menu?.header_color;
      text = menu?.center_header;
      // console.log("restaurants", restaurants);
    }
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  // useEffect(() => {
  //   const getData = async () => {
  //     setLoading(true)
  //     axios
  //       .get(
  //         `/e3468ac1-b9ff-4234-b982-756f9d3b7688/restaurant-menu/restaurants-booking`
  //       )
  //       .then((res) => {
  //         const data = res.data.data;

  //         // console.log("MY", data);
  //         setRestaurants(data.restaurants);
  //         setLoading(false)
  //       });
  //   };
  //   getData();
  // }, []);

  const showData = () => {
    if (isLoading) {
      return <RestaurantsLoader />;
    }
    if (error) {
      return <ErrorPage des={error} />;
    }
    // if (notFound) {
    //   return <ErrorPage des={notFound} />;
    // }
    return restaurants?.map((restaurant) => {
      return (
        <>
          <BookingCard restaurant={restaurant} />
          <hr />
        </>
      );
    });
  };
  return (
    <>
      <HeaderInner bgColor={bgColor} color={color} headerName={text} />
      <main
        className="bg-white  pad-top-0 restaurant-booking"
        style={{ paddingTop: "100px" }}
      >
        <div className="container p-2">{showData()}</div>
      </main>
    </>
  );
};

export default RestaurantBooking;
