import React, { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import EntertainmentCarosle from "../../Components/EntertainmentCarosle";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import axios from "../../axios";
import { object, string, number, date, InferType } from "yup";
import { useEffect } from "react";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import ReactGA from "react-ga4";
import "../../pages/search/Search.scss";
import { useTranslation } from "react-i18next";
import TaxInclusive from "../../Components/TaxInclusive.";
import Title from "../../components/title/Title";
import NewFooter from "../../NewFooter";
import BannerHome from "../../components/banner-home/BannerHome";
import BannerServices from "../../components/banner-home/BannerServices";

function HH_HouseKeepingDetail() {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    ReactGA.event({
      category: "Open popup hh-houskeeping",
      action: "Click",
      value: `hh-houskeeping: ${name}`,
    });
  };
  const [headerImage, setHeaderImage] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [description, setDescription] = useState();
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  // const theme = location.state?.theme;
  const new_design = localStorage.getItem("new_design");
  const theme = new_design == "true";

  const { t, i18n } = useTranslation();

  const params = useParams();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [times, settimes] = useState("");
  const [img, setImg] = useState("");
  const [timeInput, setTimeInput] = useState();
  const [dateInput, setDateInput] = useState();
  const [errName, setErrName] = useState("");
  const [errPhone, setErrPhone] = useState("");
  const [errEmail, setErrEmail] = useState("");
  const [errCount, setErrCount] = useState("");
  const [total_coast, setTotal_coast] = useState();
  const [count, setCount] = useState(2);
  const [hhData, setHHdata] = useState();
  const [email, setEmail] = useState("");
  const [selectTime, setSlectTime] = useState();
  const [note, setNote] = useState();
  const [maxDate, setMaxDate] = useState();
  const [minDate, setMinDate] = useState();
  const [requiredInputs, setRequiredInputs] = useState(false);

  useEffect(() => {
    const currentTime = new Date();

    // Format the time as HH:mm (hours and minutes)
    let hours = (currentTime.getHours() + 1).toString().padStart(2, "0");

    const minutes = currentTime.getMinutes().toString().padStart(2, "0");

    const currentDate = new Date();
    // console.log("formattedTime", formattedTime);
    // Format the date as YYYY-MM-DD (required by input type="date")

    const formattedDate = currentDate.toISOString().slice(0, 10);
    let day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear().toString().padStart(2, "0");
    console.log("hours", hours);
    if (hours == "24") {
      hours = "01";
      // console.log("day", day);
      day = day + 1;
    }
    const formattedTime = `${hours}:${minutes}`;
    const formatDate = `${year}-${month}-${day}`;
    setDateInput(formatDate);
    // console.log("formatDate", formatDate);
    setTimeInput(formattedTime);
  }, []);
  const currentTimem = new Date(`${dateInput}T${timeInput}`);
  const dubaiTime = currentTimem.toLocaleString("en-US", {
    timeZone: "Asia/Dubai",
  });

  const dateObj = new Date(dubaiTime);
  const hoursD = dateObj.getHours().toString().padStart(2, "0");
  const minutesD = (dateObj.getMinutes() + 1).toString().padStart(2, "0");
  const time = `${hoursD}:${minutesD}`;
  const checkValEmail = email.length > 0 && !/\S+@\S+\.\S+/.test(email);

  // const checkInputs = () => {
  //   if (email.length > 0 && !/\S+@\S+\.\S+/.test(email)) {
  //     setErrorEmail("The email must be a valid email address.");
  //   } else {
  //     setErrorEmail("");
  //   }
  // };
  // console.log("!/S+@S+.S+/.test(email)) ", !checkValEmail);
  const checkInputs = () => {
    if (count < 2) {
      setErrCount("The hours count must be at least 2");
    } else {
      setErrCount("");
    }
    if (checkValEmail) {
      setErrEmail("The email must be a valid email address.");
    } else {
      setErrEmail("");
    }
    if (phone == "") {
      setErrPhone("The phone field is required.");
    } else {
      setErrPhone("");
    }
  };
  const currency = localStorage.getItem("currency");

  const dataForm = {
    service_id: params.id,
    name: name,
    email: email,
    phone: phone.phone,
    date: dateInput,
    time: selectTime,
    hours_count: count,
    note: note,
  };
  const handleBook = async (e) => {
    e.preventDefault();
    checkInputs();
    if (count >= 2 && !checkValEmail && phone != "") {
      // const anser = await userSchema.validate(entertainment);
      setIsDisabled(true);
      axios
        .post(`/${room_uuid}/hh-housekeeping/book-service`, dataForm, {
          headers: {
            lang: lang,
          },
        })
        .then((res) => {
          // console.log("res", res);
          if (res.data.status) {
            navigate(`/${room_uuid}/HH_HouseKeepingOrderPlaced`, {
              state: {
                metadata: {
                  res: res.data.data,
                },
              },
            });
          }
          setIsDisabled(false);
        })
        .catch((err) => {
          setIsDisabled(false);
          console.log(err);
        });
    }
    // navigate("/HCOrderPlaced");
    // }
  };
  const lang = localStorage.getItem("lang");
  // console.log("des", description);

  // let img;
  // let name;

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      axios
        .get(
          `/${room_uuid}/hh-housekeeping/services/${params.company_uuid}/${params.id}`,
          {
            headers: {
              lang: lang,
            },
          }
        )
        .then((res) => {
          if (res.data.status === false) {
            navigate(`/${room_uuid}/qr-scan`);
          } else {
            const data = res.data.data;
            console.log("es.data", res.data);
            setHeaderImage(data.banners);
            setImg(data.banners[0]);
            setHHdata(data?.service);
            settimes(data?.times);
            setSlectTime(data?.times[0]);
            setTotal_coast(data?.servicePrice);
            setMaxDate(data?.maxDate);
            setMinDate(data?.minDate);
            setRequiredInputs(data?.required);
            console.log("hhDara", hhData);
          }
          setLoading(false);
        })
        .catch((err) => {
          if (err?.response.status == 404) {
            navigate(`/not-found`);
          }
        });
    };
    getData();
  }, []);
  // console.log("mindta", minDate);
  // <HeaderInner headerName={"Housekeeping"} />
  // console.log("maxDate", maxDate);
  return (
    <div className={theme ? "food" : ""}>
      {theme ? (
        <Title title={"Housekeeping"} />
      ) : (
        <HeaderInner headerName={"Housekeeping"} />
      )}
      <main className="home-main bg-white back-white pad-top-0">
        {loading ? (
          <SpaLoader />
        ) : (
          <>
            {theme ? (
              <BannerServices headerImages={[headerImage]} />
            ) : (
              <EntertainmentCarosle arr={headerImage} />
            )}

            <div className="padd-div f-16">
              <h4 style={{ fontSize: "16px", marginTop: "30px" }}>
                {hhData?.name}
              </h4>
              <div
                dangerouslySetInnerHTML={{ __html: hhData?.description }}
              ></div>
            </div>

            <div className="padd-div text-center mt-4">
              <Button
                className="reg-btn large more-pad font-dark"
                onClick={handleShow}
              >
                {t("Book")}
              </Button>
            </div>

            <div className="padd-div text-center mb-70">
              <h3 className="">
                <TaxInclusive />
              </h3>
            </div>
          </>
        )}
        <Modal
          show={show}
          onHide={handleClose}
          className={`custom-width ${theme ? "food-modal" : ""}`}
        >
          <Modal.Body>
            <button
              type="button"
              className="button close-pop"
              onClick={handleClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24.054"
                height="24.625"
                viewBox="0 0 24.054 24.625"
              >
                <defs></defs>
                <path
                  id="Icon_ionic-ios-close-circle"
                  data-name="Icon ionic-ios-close-circle"
                  className="cls-50"
                  d="M15.4,3.375A12.17,12.17,0,0,0,3.375,15.687,12.17,12.17,0,0,0,15.4,28,12.17,12.17,0,0,0,27.429,15.687,12.17,12.17,0,0,0,15.4,3.375Zm3.047,16.77L15.4,17.025l-3.047,3.12a.913.913,0,0,1-1.307,0,.959.959,0,0,1,0-1.338l3.047-3.12-3.047-3.12a.959.959,0,0,1,0-1.338.913.913,0,0,1,1.307,0L15.4,14.35l3.047-3.12a.913.913,0,0,1,1.307,0,.966.966,0,0,1,0,1.338l-3.047,3.12,3.047,3.12a.966.966,0,0,1,0,1.338A.9.9,0,0,1,18.449,20.145Z"
                  transform="translate(-3.375 -3.375)"
                ></path>
              </svg>
            </button>
            <div className="body-container">
              <div className="img-pnl">
                <img className="" src={img} />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    margin: "0",
                  }}
                >
                  <h4 style={{ fontSize: "16px", marginTop: "30px" }}>
                    {hhData?.name}
                  </h4>
                  <div dangerouslySetInnerHTML={{ __html: hhData?.time }}></div>
                </div>
              </div>

              <div className="content-pnl">
                <Form onSubmit={handleBook}>
                  <div className="input-pnlm">
                    <div className="bk-date">
                      <Form.Group
                        className="mb-3 i-f-small row align-items-center justify-content-start"
                        controlId="formBasicname"
                      >
                        <p className="col-4 m-0 ">{t(`Booking Date`)}</p>

                        <Form.Control
                          type="date"
                          value={minDate}
                          min={minDate}
                          max={maxDate}
                          onChange={(e) => setDateInput(e.target.value)}
                          // defaultValue={dateInput}
                          className="col-8  date-input dt-input three "
                        />
                      </Form.Group>
                    </div>
                    <div className="bk-time mb-4">
                      <Form.Group
                        className="mb-3 i-f-small row align-items-center justify-content-start "
                        controlId="formBasicname"
                      >
                        <p className="col-4 m-0 no-break">
                          {t(`Booking Time`)}
                        </p>
                        <select className="col-8  time-input dt-input three  form-control">
                          {Object.values(times).map((time) => {
                            // console.log("times", time);
                            return <option value={time}>{time}</option>;
                          })}
                        </select>
                      </Form.Group>
                    </div>
                    <div className="bk-date">
                      <Form.Group
                        className="mb-3 i-f-small row align-items-center justify-content-start"
                        controlId="formBasicname"
                      >
                        <p className="col-4 m-0 ">{t(`Hours Count`)}</p>
                        <Form.Control
                          type="number"
                          placeholder={t(` Hours Count (min: 2)`)}
                          name="email"
                          id="count"
                          className="col-8 text-center"
                          value={count}
                          min={2}
                          onChange={(e) => setCount(e.target.value)}
                          // value={taxiBook.email}
                          // onChange={handleInputChange}
                        />
                      </Form.Group>
                      {errCount != "" && (
                        <span className="err_msg">{errCount}</span>
                      )}
                    </div>
                    <div className="bk-date">
                      <Form.Group
                        className="mb-3 i-f-small row align-items-center justify-content-start"
                        controlId="formBasicname"
                      >
                        <p className="col-4 m-0 ">{t(`Your Email`)}</p>
                        <Form.Control
                          type="email"
                          placeholder={t(
                            `Your Email ${!requiredInputs ? "(Optional)" : ""}`
                          )}
                          name="email"
                          required={requiredInputs}
                          className="col-8"
                          onChange={(e) => setEmail(e.target.value)}
                          // value={taxiBook.email}
                          // onChange={handleInputChange}
                        />
                      </Form.Group>
                    </div>
                    {errEmail != "" && (
                      <span className="err_msg">{errEmail}</span>
                    )}

                    <div className="bk-date">
                      <Form.Group
                        className="mb-3 i-f-small row align-items-center justify-content-start"
                        controlId="formBasicname"
                      >
                        <p className="col-4 m-0 ">{t(`Your Name`)}</p>
                        <Form.Control
                          type="text"
                          required={requiredInputs}
                          placeholder={t(
                            `Your Name ${!requiredInputs ? "(Optional)" : ""}`
                          )}
                          name="email"
                          className="col-8"
                          onChange={(e) => setName(e.target.value)}
                          // value={taxiBook.email}
                          // onChange={handleInputChange}
                        />
                      </Form.Group>
                    </div>

                    <div className="con-phone">
                      <Form.Group className="mb-3 i-f-small   w-100">
                        <PhoneInput
                          country={"ae"}
                          containerclassName=" "
                          placeholder="00144"
                          // onChange={(e)=>setPhone(e.)}
                          // value={taxiBook.phone}
                          onChange={(phone) => {
                            setPhone((prev) => ({ ...prev, phone }));
                          }}
                        />
                      </Form.Group>
                      {errPhone != "" && (
                        <span className="err_msg">{errPhone}</span>
                      )}
                      {/* {errPhone != "" && (
                          <span className="err_msg ml-2">
                            {t(`${errPhone}`)}
                          </span>
                        )} */}
                      <Form.Group
                        className="mb-3 mt-4"
                        controlId="formBasicname"
                      >
                        <p className="mb-1">
                          {t("Do you have any specific cleaning instructions?")}
                        </p>
                        <Form.Control
                          className="textarya"
                          rows={2}
                          onChange={(e) => setNote(e.target.value)}
                          as="textarea"
                          placeholder={t(
                            "Example: Key under the mat, ironing, window cleaning, etc."
                          )}
                          style={{ height: "72px" }}
                        />
                      </Form.Group>
                    </div>
                    <h6 className="total_coast">
                      Total Coast: {total_coast} {currency}
                    </h6>
                  </div>
                  <div className="spacer-40"></div>
                  <div className="padd-div text-center mb-2 ">
                    <Button
                      type="submit"
                      className="reg-btn book fw-normal"
                      // onClick={handleBook}
                      disabled={isDisabled}
                    >
                      {t(`Book`)}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
      {theme && <NewFooter />}
    </div>
  );
}
export default HH_HouseKeepingDetail;
