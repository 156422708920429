import React from 'react';
function Searchbar() {
    return (
        <>
            <div className='Search-bar'>
                <input className='form-control' type="search" placeholder="Search"/>
            </div>
        </>
    );
}
export default Searchbar; 