import axios from "axios";
import { API_URL } from "../constants/api";
import { useQuery } from "react-query";

const APIURL = axios.create({
  baseURL: API_URL,
});

const Pharmacy = async (uuid, lang) => {
  return await APIURL.get(`/${uuid}/pharmacies`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowPharmacy = (uuid, lang) => {
  return useQuery(`pharmacy-${lang}`, () => Pharmacy(uuid, lang));
};

const Pharmacies = async (uuid, ph_uuid, lang) => {
  return await APIURL.get(`/${uuid}/pharmacy/categories/${ph_uuid}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowPharmacies = (uuid, ph_uuid, lang) => {
  return useQuery(`pharmacies-${lang}`, () => Pharmacies(uuid, ph_uuid, lang));
};
export const PharmacySubMenuAPI = async ({
  room_uuid,
  pharmacy_uuid,
  selected,
  pageParam,
  lang,
}) => {
  return await APIURL.get(
    `/${room_uuid}/pharmacy/${pharmacy_uuid}?cat=${selected}&page=${pageParam}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        lang: lang,
      },
    }
  );
};

const PharmacySubCatAPI = async (room_uuid, pharmacy_uuid, cat_id, lang) => {
  return await APIURL.get(
    `/${room_uuid}/pharmacy/categories/${pharmacy_uuid}?cat=${cat_id}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        lang: lang,
      },
    }
  );
};
export const ShowPharmacySubCatAPI = (uuid, pharmacy_uuid, cat_id, lang) => {
  return useQuery(`PharmacySubCatAPI-${cat_id}`, () =>
    PharmacySubCatAPI(uuid, pharmacy_uuid, cat_id, lang)
  );
};
// export const ShowPharmacySubMenu = (uuid, ph_uuid, id, page) => {
//     return useQuery(`pharmacy_sub_menu-${id}`, () => PharmacySubMenu(uuid, ph_uuid, id, page));
// }

const PharmacyItem = async (uuid, f_id, lang) => {
  return await APIURL.get(`/${uuid}/pharmacy/get-item-pharmacy/${f_id}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowPharmacyItem = (uuid, f_id, lang) => {
  return useQuery(`PharmacyItem-${lang}-${f_id}`, () =>
    PharmacyItem(uuid, f_id, lang)
  );
};
