import React from "react";
import { Link } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import FlowerCartPost from "./FlowerCartPost";
import Post1 from "../assets/images/Flowers/Flower.png";
function ViewFlowerCartModal() {
  return (
    <>
      <div className="add-post-container full-post">
        <div className="add-cart-post">
          <div className="add-cart-post-inner">
            <span className="img-pnl">
              <img src={Post1} alt="Post" />
            </span>
            <div
              className="txt-pnl"
              // onClick={() => navigate("/FlowerOrderPayment")}
            >
              <h2>Amethyst</h2>
              <p>
                - 10 Purple Orchids
                <br></br>- Wrapped in Pink Paper
                <br></br>- Tied with Pink Ribbon
              </p>
              <div className="flex-div">
                <h3>AED 635.25</h3>
                <div>
                  <Button className="reg-btn small">Add To Cart</Button>
                </div>
                <div className="count-order-amount">
                  <Button className="small">
                    <i className="fa fa-minus"></i>
                  </Button>
                  <p>1</p>
                  <Button className=" small">
                    <i className="fa fa-plus"></i>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="full-heading flex grey-bg m-0">
        <h3> Card Message </h3>
        <h2 className="rd">Required</h2>
      </div>
      <div className="full-div padd-div">
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control type="text" placeholder="Enter  Card Message " />
          </Form.Group>
        </Form>
      </div>

      <div className="check-list-container">
        <div className="full-div">
          <Link className="reg-btn big w-100 flex-div" to="/FlowerOrderPayment">
            <div>
              <i className="fa fa-plus-square"></i> Add to Cart
            </div>
            <div>AED 30.00</div>
          </Link>
        </div>
      </div>
    </>
  );
}
export default ViewFlowerCartModal;
