import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { Zoom } from "react-reveal";
import { ShowEntertainments } from "../../API/EntertainmentAPI";
import { ShowLuxurioss } from "../../API/LuxuriosAPI";

function Luxurioss() {
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const euuid = rawPath.split("categories/")[1];
  const navigate = useNavigate();

  const lang = localStorage.getItem("lang");

  let flowers;
  let bgColor;
  let color;
  let text;
  let entertainmentCategories;
  const { data, isLoading, error } = ShowLuxurioss(room_uuid, euuid, lang);
  if (!isLoading) {
    if (error) {
      if (error?.response.status == 404) {
        navigate(`/not-found`);
      }
    } else {
      if (data.data.status === false) {
        navigate(`/${room_uuid}/qr-scan`);
      } else {
        flowers = data.data.data;
        entertainmentCategories = flowers.luxurios_categories;
        color = flowers.color;
        bgColor = flowers.header_color;
        text = flowers.title;

        console.log("ShowEntertainmentss", flowers);
      }
    }
  }

  // useEffect(() => {
  //   const getData = async () => {
  //     setLoading(true)
  //     axios
  //       .get(
  //         `/${room_uuid}/entertainment/categories/1bb893d8-e5b0-42ba-a684-7f563c2e8ca5`
  //       )
  //       .then((res) => {
  //         const data = res.data.data;
  //         setEntertainmentCategories(data.entertainment_categories);
  //         setTitle(data?.title)
  //         console.log("data,entertainment/companies", data);
  //         setLoading(false)
  //       });
  //   };
  //   getData();
  // }, []);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  return (
    <>
      <HeaderInner headerName={text} />
      <main className="home-main bg-white pt-3">
        <div className="">
          {isLoading ? (
            <SpaLoader />
          ) : entertainmentCategories ? (
            entertainmentCategories.map((x, idx) => {
              return (
                <div id={x.name} key={idx}>
                  <Zoom>
                    <div
                      className="con-img-container v3"
                      onClick={() =>
                        navigate(`/${room_uuid}/luxurios-detail/${x.uuid}`)
                      }
                    >
                      <div className="con-img">
                        <img src={x.image} alt="" />
                        <p></p>
                      </div>
                    </div>
                    <div className="con-text-container">
                      <p>{x.name}</p>
                      <hr className="mb-0 dark" />
                    </div>
                  </Zoom>
                </div>
              );
            })
          ) : null}
        </div>
      </main>
    </>
  );
}
export default Luxurioss;
