import axios from "axios";
import { API_URL } from "../constants/api";
import { useQuery } from "react-query";

const APIURL = axios.create({
  baseURL: API_URL,
});

const HHTaxi = async (uuid, lang) => {
  return await APIURL.get(`/${uuid}/hh_taxi/hh-taxibooking-companies`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowHHTaxi = (uuid, lang) => {
  return useQuery(`hh-taxi-${lang}`, () => HHTaxi(uuid, lang));
};

const HHTaxiPage = async (uuid, hh_uuid, lang) => {
  return await APIURL.get(`/${uuid}/florists/categories/${hh_uuid}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowHHTaxiPage = (uuid, hh_uuid, lang) => {
  return useQuery(`taxi-page-${lang}-${hh_uuid}`, () =>
    HHTaxiPage(uuid, hh_uuid, lang)
  );
};
