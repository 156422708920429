import React, { useEffect, useRef, useState } from "react";
import HeaderInner from "../../Components/HeaderInner";
import AddCartPost from "../../Components/AddCartPost";
import Search from "../../Components/Search";
import Carosle from "../../Components/Carosle";
import { ShowScanCategory } from "../../API/In-Room-DiningAPI";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import useDraggableScroll from "use-draggable-scroll";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import SearchSc from "../../Components/SearchSc";

const CategoryScan = () => {
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const params = useParams();
  const navigate = useNavigate();
  const lang = localStorage.getItem("lang");

  const [selected, setSelected] = useState(params.cat_id);
  const ref = useRef(null);
  const { onMouseDown } = useDraggableScroll(ref);
  let restaurant;
  let restaurantCategories;
  let images = [];
  let isVideo = false;
  let bgColor;
  let color;
  let text;
  let terms_and_conditions;
  let r_uuid;
  let restaurantInfo;
  const { data, isLoading, error } = ShowScanCategory(
    room_uuid,
    lang,
    selected
  );

  if (!isLoading) {
    console.log("stata", data.data);
    if (data.data.status == false) {
      navigate(`/${room_uuid}/qr-scan`);
    } else if (!error) {
      restaurant = data.data.data;
      r_uuid = restaurant.restaurant.uuid;
      restaurantInfo = restaurant.restaurant;
      terms_and_conditions = restaurant.restaurant.terms_and_conditions;
      restaurantCategories = restaurant.Restaurant_menuCategoriesResource;
      isVideo = restaurant.restaurant.real_video ? true : false;
      if (restaurant.restaurant.real_video) {
        if (restaurant.restaurant?.images?.length > 0) {
          images = [
            { image: restaurant.restaurant.real_video },
            ...restaurant.restaurant.images,
          ];
        }
      } else {
        if (restaurant.restaurant?.images?.length > 0) {
          images = restaurant.restaurant.images;
        }
      }
      color = data.data.data.color;
      bgColor = data.data.data.headerColor;
      text = data.data.data.center_header;
      document.documentElement.style.setProperty("--color", color);
      document.documentElement.style.setProperty("--color_t", `${color}b3`);

      document.documentElement.style.setProperty("--header_color", bgColor);
      // window.scrollTo({
      //   top: 0,
      //   left: 0,
      // });
    }
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  const handelNav = (id) => {
    // console.log("iddd", id);
    navigate(`/${room_uuid}/restaurant-menu/categories/${id}`);
  };

  useEffect(() => {
    if (ref.current) {
      // console.log("ref.current", ref.current.children);

      const lists = ref.current.children;
      // console.log("ref", ref.current.children);
      // ref.current.children;
      Object.values(lists).map((list, idx) => {
        if (list.classList.contains("active")) {
          const itemWidth = list.offsetWidth;
          const scrollPosition = idx * itemWidth;

          ref.current.scrollTo({
            left: scrollPosition,
            behavior: "smooth",
          });
          //     // Your logic when the "active" class is present
          //     // console.log('The first child has the "active" class');
        }
      });
    }
  }, [data]);
  return (
    <>
      <HeaderInner scan={true} headerName={text} />
      {isLoading ? (
        <SpaLoader />
      ) : (
        <main className="bg-white pad-top-0">
          {images?.length > 0 && (
            <header className="img-header m-0">
              <Carosle headerImages={images} isVideo={isVideo} />
            </header>
          )}
          <SearchSc
            placeholder="Search for Categories..."
            room_uuid={room_uuid}
            text={text}
          />
          {terms_and_conditions != "" &&
            (terms_and_conditions != undefined ? (
              <>
                {/* <p className="text-center">Terms & Conditions</p> */}
                <div
                  className="mx-2"
                  dangerouslySetInnerHTML={{
                    __html: terms_and_conditions,
                  }}
                />
              </>
            ) : null)}

          <ul
            className="inline-list search"
            ref={ref}
            onMouseDown={onMouseDown}
          >
            {data?.data?.data?.categories?.map((category, idx) => {
              // console.log("category", category.count_sub_categories);
              return category.count_sub_categories == 0 ? (
                <li
                  className={selected == category?.id && "active"}
                  onMouseDown={() => {
                    setSelected(category?.id);
                  }}
                  key={idx}
                >
                  <Button>{category?.name}</Button>
                </li>
              ) : (
                <li
                  className={selected == category?.id && "active"}
                  key={idx}
                  onClick={(id) => handelNav(category?.id)}
                >
                  <Button>{category?.name}</Button>
                </li>
              );
            })}
          </ul>

          {data?.data?.data?.items?.map((item) => {
            return (
              <div className={`add-post-container add-cart`}>
                <AddCartPost
                  item={item}
                  restaurantInfo={restaurantInfo}
                  //   restaurantId={restaurant_id}
                  isOffCategory={true}
                  restaurant_is_closed={true}
                  isbooking={true}
                />
              </div>
            );
          })}
        </main>
      )}
    </>
  );
};

export default CategoryScan;
