import React from "react";
import { ShowCheckUUID } from "./API/HomeMenuAPI";
import { useNavigate, useParams } from "react-router-dom";
import HomeLoader from "./Components/Loaders/HomeLoader";

const CheckIsFound = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { data, isLoading, error } = ShowCheckUUID(params.room_uuid);
  if (!isLoading) {
    if (data.data.status == true) {
      if (data?.data?.data?.is_active) {
        if (data.data.data.is_restaurant_table) {
          localStorage.setItem("in_dining_logo", data.data.data.logo);
          localStorage.setItem("currency", data.data.data.currency || "AED");
          navigate(
            `/${data.data.data.room}/rstauran-menu-categories/${data.data.data.restaurant_uuid}`,
            {
              state: {
                metadata: {
                  languages: data.data.data.lang,
                },
              },
            }
          );
        } else {
          navigate(`/${data.data.data.room}/qr-code`);
          localStorage.setItem("in_dining_logo", data.data.data.logo);
          localStorage.setItem("new_design", data.data.data.use_new_design);
          localStorage.setItem("currency", data.data.data.currency || "AED");
        }
      } else {
        navigate(`/room_not_active`);
      }
    } else {
      navigate(`/not-found`);
    }
  }

  return isLoading && <HomeLoader />;
};

export default CheckIsFound;
