import axios from "axios";
import { API_URL } from "../constants/api";
import { useQuery } from "react-query";

const APIURL = axios.create({
  baseURL: API_URL,
});

const SearchAPI = async (uuid, cat, search, lang) => {
  return await APIURL.get(
    `/${uuid}/search?search_category=${cat}&search=${search}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        lang: lang,
      },
    }
  );
};
export const ShowSearchAPI = (uuid, cat, search, lang) => {
  return useQuery(`search-${cat}-${search}-${lang}`, () =>
    SearchAPI(uuid, cat, search, lang)
  );
};
