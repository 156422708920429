import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Form, Modal } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import Post1 from "../../assets/images/Pharmacy/Medicine.png";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { addToCart } from "../../utils/cartUtils";
import axios from "../../axios";
import { useCartStore } from "../../store/useStore";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { Zoom } from "react-reveal";
import HeaderInner from "../../Components/HeaderInner";
import Search from "../../Components/Search";
import { ShowRestaurantItem } from "../../API/In-Room-DiningAPI";

function RestaurantItem() {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [modalImage, setModalImage] = useState(Post1);
  const [showCart, setShowCart] = useState(true);
  const [count, setCount] = useState(1);
  const location = useLocation();
  const rawPath = location.pathname;
  const params = useParams();
  const room_uuid = rawPath.split("/")[1];
  const currency = localStorage.getItem("currency");
  let newpice = 0;
  const [extras, setExtras] = useState([]);
  const [priceOption, setPriceOption] = useState(0);
  const [optionsIds, setOptionsIds] = useState({});
  const lang = localStorage.getItem("lang");

  const handleInvalid = (event, index) => {
    event.target.setCustomValidity("Please select 1 option at least");
  };
  const handleHandleOptionsChanges = (
    event,
    optionName,
    optionId,
    type,
    price,
    idx,
    max_op
  ) => {
    event.target.setCustomValidity("");
    // console.log("max_op", max_op);

    if (type == "radio") {
      // if (state == "remove") {
      //   const { [optionName]: _, ...rest } = optionsIds;

      //   setOptionsIds(rest);
      // } else {
      setOptionsIds((prev) => ({
        ...prev,
        [optionName]: optionId,
      }));
      // }
    } else {
      const idex = document.getElementById(`idx${idx}`);
      var inputNodes = idex.getElementsByTagName("INPUT");
      var inputLang = idex.querySelectorAll(
        'input[type="checkbox"]:checked'
      ).length;
      // console.log("inputNodes", inputNodes);
      Object.values(inputNodes).map((inp) => {
        if (inputLang >= 1) {
          inp.required = false;
          inp.setCustomValidity("");
          // if (inp.checked == false) {
          //   inp.disabled = true;
          // }
          // inp.disabled = true;
          // inp.disabled = false;
        } else if (inputLang == 0) {
          inp.required = true;
          inp.setCustomValidity("Please select 1 option at least");
        }

        // else {
        //   if (inp.checked == false) {
        //     inp.disabled = false;
        //   }
        // }
        console.log("inputLang", inputLang);
        if (inputLang == max_op) {
          if (inp.checked == false) {
            inp.disabled = true;
          }
        } else {
          if (inp.checked == false) {
            inp.disabled = false;
          }
        }
      });
      // if (extras.includes(optionId)) {
      //   setPriceOption(priceOption - Number(price));

      //   const tempExtras = JSON.parse(JSON.stringify(extras));
      //   const filtered = tempExtras.filter((temp) => {
      //     return temp !== optionId;
      //     console.log("filtered", filtered);
      //   });
      //   setExtras(filtered);
      // } else {
      //   setPriceOption(priceOption + Number(price));

      //   setExtras((prev) => [optionId, ...prev]);
      // }
      const index = extras.findIndex(
        (obj) => JSON.stringify(obj) === JSON.stringify(optionId)
      );

      if (index !== -1) {
        // If found, remove the object from the array
        extras.splice(index, 1);
        setPriceOption(priceOption - Number(price));
      } else {
        // If not found, add the object to the array
        extras.push(optionId);
        setPriceOption(priceOption + Number(price));
      }
    }
  };
  const { cart, setCart } = useCartStore((state) => ({
    cart: state.cart,
    setCart: state.setCart,
  }));
  let item;
  let categoryClose = false;
  let reasturantClose = false;
  let reasturantOrder = false;
  let hide = false;
  const { data, isLoading } = ShowRestaurantItem(room_uuid, params.f_id);

  if (!isLoading) {
    const fData = data.data.data;
    item = fData?.item;

    console.log("dataaa", item);
    categoryClose = item.Categories[0].is_closed;
    reasturantClose = item.restaurant.is_closed;
    reasturantOrder = item.restaurant.can_order;
    hide = reasturantOrder && !categoryClose;

    console.log("hide", hide);
    // if (categoryClose == true) {
    //   hide = true;
    // } else if (reasturantClose) {
    //   hide = true;
    // } else if (reasturantOrder) {
    //   hide = true;
    // } else {
    //   hide = false;
    // }
  }
  const queryClient = useQueryClient();

  const { t, i18n } = useTranslation();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCartClose = () => {
    setShowCart(false);
  };
  const handleCartShow = () => setShowCart(true);

  const handleIncrease = () => {
    setCount((prev) => {
      if (prev <= 1) {
        return 1;
      }
      return prev - 1;
    });
  };
  const handleDecrease = () => {
    setCount((prev) => prev + 1);
  };

  // useEffect(() => {
  //   const checkboxes = document.getElementsByClassName("checkboxes");
  //   console.log("checkboxes");
  //   Object.values(checkboxes).map((check) => {
  //     console.log("check", check.children[0].required);
  //     if (check.children[0].required == true) {
  //       check.children[0].setCustomValidity("Please select 1 option at least");
  //     }
  //   });
  // }, [data]);
  const addToCartV2 = (e) => {
    e.preventDefault();
    const optionsArray = [...Object.values(optionsIds), ...extras];
    const formatArray = optionsArray.map((option) => {
      return {
        extra_id: option.extra_id,
        option_id: option.id,
        price: option.price,
      };
    });
    // console.log(item);
    setLoading(true);
    axios
      .post(`/${room_uuid}/restaurant/order/order-item`, {
        restaurant_id: parseInt(params.c_id),
        item_id: item.id,
        quantity: count,
        extras: formatArray,
      })
      .then((res) => {
        setLoading(false);
        queryClient.invalidateQueries("checkout");
        const data = res.data;
        handleCartClose();
        // setRestaurantItems(data.items);
        // setHeaderName(data.title);
        console.log(data);
      });
  };
  extras?.map((ext) => {
    newpice = newpice + ext.price;
  });
  newpice = newpice * count;
  console.log("newpice", newpice);
  return (
    <>
      <HeaderInner headerName={"Restaurant"} />
      <main
        className="home-main bg-white   flowers"
        style={{ paddingTop: "100px !important" }}
      >
        {!isLoading && (
          <>
            <Search
              placeholder={lang == "EN" ? item?.display_name : item?.name}
            />
            <div className="add-cart-post new v2">
              <div className="add-cart-post-inner">
                <span
                  className="img-pnl navigate"
                  onClick={() => {
                    handleShow();
                    setModalImage(item.image);
                  }}
                >
                  <img src={item.image} alt="Post" />
                </span>
                <div
                  className="txt-pnl navigate"
                  // onClick={() => navigate("/PharmacyOrderPayment")}
                  onClick={handleCartShow}
                >
                  <h2>{lang == "EN" ? item?.display_name : item?.name}</h2>

                  <p className="f-12 fw-400 mt-0 ">
                    {item.description || item.description === ""
                      ? item.description
                      : item.translations[0].description
                      ? item.translations[0].description
                      : ""}
                  </p>
                  <div className="flex-div">
                    <h3>
                      {item.price == "0.00" ? (
                        <span style={{ fontSize: "10px" }}>
                          {t("Price upon selection")}
                        </span>
                      ) : (
                        `${currency} ${item.price}`
                      )}
                    </h3>
                    {hide && (
                      <Button className="add-button">{t("Add To Cart")}</Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </main>
      {item && (
        <Modal
          show={show}
          // style={{ maxWidth: "430px" }}
          className="custom-width img v2"
          onHide={handleClose}
        >
          <Button className="close-btn img" onClick={handleClose}>
            <div className="close-btn-wrap"></div>
            <AiOutlineCloseCircle />
          </Button>
          <Modal.Body>
            <div className="modal-img-container v2">
              <Image src={item.image} alt="Post" />
            </div>
          </Modal.Body>
        </Modal>
      )}

      {item && (
        <Modal
          show={showCart}
          className="custom-width v2"
          onHide={handleCartClose}
        >
          <Button className="close-btn" onClick={handleCartClose}>
            <AiOutlineCloseCircle />
          </Button>
          <Modal.Body>
            <div className="modal-img-container br">
              <Image src={item.image} alt="Post" />
            </div>
            <div className="modal-text-container sub-menu">
              <div className="pl-4">
                <h2>{lang == "EN" ? item?.display_name : item?.name}</h2>
                <p className="text-gray">
                  {item.description ? item.description : ""}
                </p>
              </div>
              <div className="price-container px-4 pt-2 pb-3">
                <p>
                  {currency} {item.price}
                </p>
                <div className="count-order-amount">
                  <Button className="small" onClick={handleIncrease}>
                    <i className="fa fa-minus"></i>
                  </Button>
                  <p>{count}</p>
                  <Button className=" small" onClick={handleDecrease}>
                    <i className="fa fa-plus"></i>
                  </Button>
                </div>
              </div>
              <form action="" onSubmit={addToCartV2}>
                {item?.extrasWithOptions?.length > 0
                  ? item?.extrasWithOptions.map((opti, idx) => {
                      return (
                        <>
                          <div className="extras-heading" key={idx}>
                            <p className="text">{opti.name}</p>
                            <p className="required">
                              {" "}
                              {opti?.is_required ? t("Required") : ""}
                            </p>
                          </div>
                          {opti.extra_type_name == "text" && (
                            <div className="extra_input">
                              <input
                                type="text"
                                id={opti.extra_id}
                                className="ex_class"
                              />
                            </div>
                          )}
                          <div className="extras-items" id={`idx${idx}`}>
                            {opti?.option
                              ? opti?.option.map((extra, index) => {
                                  return (
                                    <div className="extras-item" key={index}>
                                      <p className="item-text">{extra.name}</p>
                                      <div className="res_options">
                                        {extra?.option_has_price && (
                                          <p>{`+ ${extra.currency} ${extra.price}`}</p>
                                        )}

                                        {opti.extra_type_name == "radio" ? (
                                          <Form.Check
                                            type={opti.extra_type_name}
                                            name={opti.name}
                                            id={opti.name}
                                            className="item-check"
                                            required={opti.is_required}
                                            // disabled={
                                            //   extras.length >= 2 &&
                                            //   !extras.includes(extra.name)
                                            // }
                                            onChange={(event) =>
                                              handleHandleOptionsChanges(
                                                event,
                                                opti.name,
                                                (extra = {
                                                  ...extra,
                                                  extra_id: opti.extra_id,
                                                }),
                                                opti.extra_type_name,
                                                extra.price
                                              )
                                            }
                                            aria-label="radio 1"
                                          />
                                        ) : (
                                          <Form.Check
                                            type={opti.extra_type_name}
                                            name={extra.name}
                                            id={extra.name}
                                            className="item-check"
                                            required={
                                              index == 0
                                                ? opti.is_required
                                                : false
                                            }
                                            onInvalid={handleInvalid}
                                            onChange={(event) =>
                                              handleHandleOptionsChanges(
                                                event,
                                                opti.name,
                                                (extra = {
                                                  ...extra,
                                                  extra_id: opti.extra_id,
                                                }),
                                                opti.extra_type_name,
                                                extra.price,
                                                idx,
                                                opti.max_options
                                              )
                                            }
                                            aria-label="radio 1"
                                          />
                                        )}
                                      </div>
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                        </>
                      );
                    })
                  : null}
                <div className="full-div mt-4 modal-add-cart">
                  {hide && (
                    <Button
                      // onClick={() =>
                      //   addToCart({
                      //     props: { item, cart, setCart, count, handleCartClose },
                      //   })
                      // }
                      type="submit"
                      className="reg-btn big w-100 flex-div"
                      disabled={loading}
                    >
                      <div className="plus-container">
                        <i className="fa fa-plus-square"></i>{" "}
                        <span>{t("Add To Cart")}</span>
                      </div>
                      <div>
                        {item.price == "0.00" && priceOption == 0 ? (
                          <span style={{ fontSize: "12px" }}>
                            {t("Price upon selection")}
                          </span>
                        ) : (
                          `${currency} ${parseFloat(
                            newpice + item.price * count
                          ).toFixed(2)}`
                        )}
                      </div>
                    </Button>
                  )}
                </div>
              </form>
              {/* <div className="full-div padd-div mt-4">
                {hide == false && (
                  <Button
                    onClick={addToCartV2}
                    className="reg-btn big w-100 flex-div"
                    disabled={loading}
                  >
                    <div>
                      <i className="fa fa-plus-square mx-2"></i>
                      {t("Add To Cart")}
                    </div>
                    <div>
                      {" "}
                      <span className="fw-600">
                        {parseFloat(item.price * count).toFixed(2)}
                      </span>
                    </div>
                  </Button>
                )}
              </div> */}
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
export default RestaurantItem;
