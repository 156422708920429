import React, { useEffect } from "react";
import RestaurantsLoader from "../../Components/Loaders/RestaurantsLoader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ShowScanResturants } from "../../API/In-Room-DiningAPI";
import HeaderInner from "../../Components/HeaderInner";
import { Bounce } from "react-reveal";
import CarosleRestaurant from "../../Components/CarosleRestaurant";

const RestaurantsScan = () => {
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const navigate = useNavigate();
  const lang = localStorage.getItem("lang");
  let restaurants;
  let bgColor;
  let color;
  let text;
  let headerImg = [];
  const { data, isLoading, error } = ShowScanResturants(room_uuid, lang);

  if (!isLoading) {
    if (data.data.status == false) {
      navigate(`/${room_uuid}/qr-scan`);
    } else if (!error) {
      restaurants = data.data.data.restaurants;
      color = data.data.data.color;
      bgColor = data.data.data.headerColor;
      text = data.data.data.center_header;
      document.documentElement.style.setProperty("--color", color);
      document.documentElement.style.setProperty("--color_t", `${color}b3`);

      document.documentElement.style.setProperty("--header_color", bgColor);
      //   color = data.data.data.color;
      //   bgColor = data.data.data.header_color;
      //   text = data.data.data.center_header;
      // console.log("restaurants", restaurants);
      // headerImg = restaurants
    }
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  return (
    <>
      <HeaderInner headerName={"Restaurant Booking"} scan={true} />
      <main className="bg-white " style={{ paddingTop: "100px!important" }}>
        {isLoading ? (
          <RestaurantsLoader />
        ) : (
          <>
            {restaurants?.length > 1
              ? restaurants.map((restaurant, idx) => {
                  return (
                    <div
                      className={`general-post-container restaurant-post ${
                        restaurant.is_closed
                          ? "closed restaurant-option"
                          : "restaurant-option"
                      }`}
                      key={idx}
                    >
                      <Bounce left>
                        <Link
                          to={`/${restaurant.uuid}/restaurant-menu/categories`}
                          className="general-post"
                        >
                          <div className="closed-tag"></div>
                          <div className="img-pnl ">
                            {/* <img
                                src={restaurant.image}
                                alt="Post"
                                className="visible"
                              /> */}
                            {
                              // headerImg.push(restaurant.image)
                            }
                            <CarosleRestaurant
                              headerImages={[restaurant.image]}
                            />
                          </div>
                          <div className="txt-pnl">
                            <h2>{restaurant.name}</h2>
                            <p>{restaurant.cuisine_name}</p>
                          </div>
                        </Link>
                      </Bounce>
                    </div>
                  );
                })
              : null}
          </>
        )}

        {/*    <div className="general-post-container restaurant-post">
          <Link to="/RestaurantMenu" className="general-post">
            <div className="closed-tag"></div>
            <div
              className={
                timer ? "img-pnl smooth" : "img-pnl animated-background smooth"
              }
            >
              <img src={Post1} alt="Post" className={timer ? "visible" : ""} />
            </div>
            <div className="txt-pnl">
              <h2 className={!timer && "animated-background"}>
                {timer && "In-Room Dining"}
              </h2>
              <p className={!timer && "animated-background"}>
                {timer && "International"}
              </p>
            </div>
          </Link>
        </div>
        <div className="general-post-container restaurant-post">
          <Link to="/RestaurantMenu" className="general-post">
            <div className="closed-tag"></div>
            <div
              className={
                timer ? "img-pnl smooth" : "img-pnl animated-background smooth"
              }
            >
              <img src={Post2} alt="Post" className={timer ? "visible" : ""} />
            </div>
            <div className="txt-pnl">
              <h2 className={!timer && "animated-background"}>
                {timer && "Cafe Society"}
              </h2>
              <p className={!timer && "animated-background"}>
                {timer && "Pizza, Eastern, Western"}
              </p>
            </div>
          </Link>
        </div>
        <div className="general-post-container restaurant-post">
          <Link to="/RestaurantMenu" className="general-post">
            <div className="closed-tag"></div>
            <div
              className={
                timer ? "img-pnl smooth" : "img-pnl animated-background smooth"
              }
            >
              <img src={Post3} alt="Post" className={timer ? "visible" : ""} />
            </div>
            <div className="txt-pnl">
              <h2 className={!timer && "animated-background"}>
                {timer && "Hawa Bahri Restaurant"}
              </h2>
              <p className={!timer && "animated-background"}>
                {timer && "Eastern Cuisine"}
              </p>
            </div>
          </Link>
        </div> */}
      </main>
    </>
  );
};

export default RestaurantsScan;
