import React from "react";
import { Link } from "react-router-dom";

const PharmacyLoader = () => {
  const items = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  ];
  return (
    <ul className="display-list three-items v2 v3 loading">
      {items.map((item, idx) => {
        return (
          <li key={idx}>
            <Link to="">
              <div className="img-pnl"></div>
              <img src="" alt="" />
              <div className="animated-background bg-skimmer"></div>
              <div className="bg-layer"></div>
              <p></p>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default PharmacyLoader;
