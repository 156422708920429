import axios from "axios";
import { API_URL } from "../constants/api";
import { useQuery } from "react-query";

const APIURL = axios.create({
  baseURL: API_URL,
});

const Checkout = async (uuid, lang) => {
  return await APIURL.get(`/${uuid}/orders/checkout`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowCheckout = (uuid, lang) => {
  return useQuery(`checkout`, () => Checkout(uuid, lang));
};
