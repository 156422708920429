import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Zoom } from "react-reveal";
import HeaderInner from "../../Components/HeaderInner";
import axios from "../../axios";
import PharmacyLoader from "../../Components/Loaders/PharmacyLoader";
import { ShowPharmacies, ShowPharmacy } from "../../API/Pharmacy";
import PharmacyComponent from "../../Components/PharmacyComponent";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import Title from "../../components/title/Title";
import NewFooter from "../../NewFooter";
import Overllay from "../../assets/new_imgs/Rectangle 1072.png";

function PharmacyService() {
  const [enableClass, setEnableClass] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [pharmacies, setPharmacies] = useState();
  const [title, setTitle] = useState();
  // const [parmacy, setParmacy] = useState({ id: "", uuid: "" });
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const params = useParams();
  setTimeout(() => {
    setEnableClass(false);
  }, 3000);
  const navigate = useNavigate();
  const lang = localStorage.getItem("lang");

  let parmacy = { id: "", uuid: "" };
  let pharmacies_;
  let pharmacies;
  let bgColor;
  let color;
  let text;
  const { data, isLoading, error } = ShowPharmacy(room_uuid, lang);
  if (!isLoading) {
    if (data.data.status === false) {
      navigate(`/${room_uuid}/qr-scan`);
    } else {
      pharmacies_ = data.data.data;

      pharmacies = pharmacies_.pharmacies;
      if (pharmacies.length > 0) {
        parmacy.id = pharmacies_.pharmacies[0].id;
        parmacy.uuid = pharmacies_.pharmacies[0].uuid;
        color = pharmacies_.color;
        bgColor = pharmacies_.header_color;
        text = pharmacies_.title;
        // console.log("ShowPharmacy", pharmacies_);
        if (pharmacies?.length == 1) {
          navigate(
            `/${room_uuid}/pharmacy/category/${pharmacies[0].uuid}/id/${pharmacies[0].id}`,
            { replace: true, state: { theme: true } }
          );
        }
      } else {
        navigate(`/${room_uuid}/qr-scan`);
      }
    }
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  return (
    <div className="food">
      <Title title={text} />
      <div className="box_food">
        {isLoading ? (
          <SpaLoader />
        ) : pharmacies.length > 0 ? (
          pharmacies?.map((pharmacie, idx) => {
            return (
              <div key={idx}>
                <Link
                  key={idx}
                  to={`/${room_uuid}/pharmacy/category/${pharmacie.uuid}/id/${pharmacie.id}`}
                  state={{ theme: true }}
                >
                  <img src={pharmacie?.image} alt="" className="booking_img" />
                  <img src={Overllay} alt="" className="overlay" />
                  <div className="text_food">
                    <h5 className="">{pharmacie.name}</h5>
                  </div>{" "}
                </Link>
                {/* <Zoom>
                  <div
                    className="con-img-container v3"
                    onClick={() =>
                      navigate(
                        `/${room_uuid}/pharmacy/category/${pharmacie.uuid}/id/${pharmacie.id}`
                      )
                    }
                  >
                    <div className="con-img">
                      <img src={pharmacie.image} alt="" />
                      <p></p>
                    </div>
                  </div>
                  <div className="con-text-container">
                    <p>{pharmacie.name}</p>
                    <hr className="mb-0 dark" />
                  </div>
                </Zoom> */}
              </div>
            );
          })
        ) : null}
      </div>
      <NewFooter />
    </div>
  );
}
export default PharmacyService;
