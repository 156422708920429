import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Navbar, NavDropdown, Nav } from "react-bootstrap";
import Cart from "../assets/images/icons/icon-cart.png";
import { LinkContainer } from "react-router-bootstrap";
import axios from "../axios";
import logo from "../assets/images/logo.png";
import { useCartStore } from "../store/useStore";
import { useTranslation } from "react-i18next";
import { ShowCheckout } from "../API/CheckoutAPI";
import { changeLanguage } from "i18next";
function HeaderInnerInDining({
  logo,
  headerName,
  color,
  bgColor,
  text,
  scan,
  languages,
}) {
  const logo_in_dining = localStorage.getItem("in_dining_logo");
  const [orders, setOrders] = useState(0);
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const notHome = rawPath.split("/")[2];
  // console.log("notHome", rawPath.includes("/restaurant-menu/categories"));
  let show = false;
  if (notHome == "rstauran-menu-categories") {
    show = true;
  }

  // console.log("logo",);
  const { cartCount, setCartCount } = useCartStore((state) => ({
    cartCount: state.cartCount,
    setCartCount: state.setCartCount,
  }));

  let roomNumber;
  let flattenedArray;
  let cart_count = 0;
  let check;
  if (room_uuid) {
    const { data, isLoading, error } = ShowCheckout(room_uuid);

    if (!isLoading) {
      check = data?.data?.data;
      // console.log("check", check);
      const tempItems = check?.order?.map((order) => {
        return order?.items.map((aa) => aa);
      });
      if (tempItems) {
        flattenedArray = [].concat(...tempItems);
        // console.log("flattenedArray", flattenedArray);
        cart_count =
          flattenedArray?.length == 0
            ? 0
            : flattenedArray
                ?.map((item) => item?.quantity)
                .reduce((acc, curr) => (acc += curr));
      }
    }
  }

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  // console.log("lang", i18n.language);
  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
  };
  const lang = localStorage.getItem("lang");
  // localStorage.setItem("lang", lang);

  const isSelct = languages?.filter((language) => {
    console.log(
      "language.code == lang",
      language.code == lang,
      "language.code == lang",
      language.code == lang
    );
    if (language.code == lang) {
      return language;
    }
  });

  console.log("isSelct", isSelct);
  return (
    <>
      <Navbar style={{ backgroundColor: bgColor }}>
        <div className="d-flex align-items-center">
          {show ? (
            <NavDropdown
              sty
              title={
                isSelct && (
                  <>
                    <img
                      alt="United States"
                      className="flag"
                      src={isSelct[0].image}
                    />

                    <span style={{ color: color }}>{isSelct[0].code}</span>
                  </>
                )
              }
              id="basic-nav-dropdown"
              className=""
            >
              {languages?.map((language, idx) => {
                return (
                  <NavDropdown.Item
                    style={{ color: color }}
                    onClick={() => changeLang(language.code)}
                  >
                    <img
                      alt="United States"
                      className="flag"
                      src={language.image}
                    />
                    <span>{language.code}</span>
                  </NavDropdown.Item>
                );
              })}
            </NavDropdown>
          ) : (
            <div
              className="back-btn-container"
              onClick={() => {
                location.pathname.includes("/qr-scan")
                  ? navigate(-2)
                  : navigate(-1);
              }}
            >
              <div className="back-btn">
                {" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="13"
                  fill="none"
                  className="bi bi-chevron-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    stroke="none"
                    stroke-width="1.3"
                    d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z "
                  ></path>
                </svg>
                <p>{t("back")}</p>
              </div>
            </div>
          )}
        </div>
        <Navbar.Brand>
          {logo_in_dining != undefined ? (
            <img
              src={logo_in_dining}
              style={{ width: "235px", height: "60px", objectFit: "contain" }}
              alt="Logo"
            />
          ) : (
            ""
          )}
          {/* <h6>{text}</h6> */}
        </Navbar.Brand>

        <LinkContainer to={`/${room_uuid}/Checkout-dining`}>
          <Nav.Link className="cart-container mr-2">
            <div className="cart-count">
              <p className="count">{cart_count}</p>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33.853"
              height="33.853"
              viewBox="0 0 33.853 33.853"
            >
              <g
                id="Group_37949"
                data-name="Group 37949"
                transform="translate(-400 574)"
              >
                <path
                  id="Path_292"
                  data-name="Path 292"
                  d="M19.367,38.349A2.349,2.349,0,1,1,17.019,36,2.354,2.354,0,0,1,19.367,38.349Z"
                  transform="translate(395.676 -584.61)"
                  fill="none"
                ></path>
                <path
                  id="Path_293"
                  data-name="Path 293"
                  d="M36.7,38.349A2.352,2.352,0,1,1,34.349,36,2.36,2.36,0,0,1,36.7,38.349Z"
                  transform="translate(390.569 -584.61)"
                  fill="none"
                ></path>
                <path
                  id="Path_294"
                  data-name="Path 294"
                  d="M32.185,7.037a.929.929,0,0,0-.741-.367H9.722L9.3,5.324a.922.922,0,0,0-.621-.607L4.82,3.532a.944.944,0,1,0-.55,1.806l3.371,1.03L11.95,19.979l-1.53,1.255-.127.127a2.49,2.49,0,0,0-.071,3.174,2.585,2.585,0,0,0,2.123.945H28.038a.942.942,0,1,0,0-1.883H12.2a.613.613,0,0,1-.529-.324.633.633,0,0,1,0-.614l2.264-1.883H28.447a.938.938,0,0,0,.938-.712L32.4,7.834A.937.937,0,0,0,32.185,7.037Z"
                  transform="translate(398.939 -575.028)"
                  fill="none"
                ></path>
                <path
                  id="Path_296"
                  data-name="Path 296"
                  d="M0,0H33.853V33.853H0Z"
                  transform="translate(400 -574)"
                  fill="none"
                ></path>
              </g>
            </svg>
          </Nav.Link>
        </LinkContainer>
      </Navbar>
    </>
  );
}
export default HeaderInnerInDining;
