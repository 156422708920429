import React from "react";
import "./Title.scss";
import { useNavigate, useParams } from "react-router-dom";
import { t } from "i18next";

const Title = ({ title }) => {
  const params = useParams();
  const navigate = useNavigate();

  return (
    <h4
      className="title__"
      onClick={() => navigate(`/${params.room_uuid}/qr-code`)}
    >
      {t(`${title || ""}`)}
    </h4>
  );
};

export default Title;
