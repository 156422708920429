import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import SpaCarosle from "../../Components/SpaCarosle";
import axios from "../../axios";
import Green from "../../assets/images/icons/green-leaf.png";
import { Button, Modal } from "react-bootstrap";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import { Accordion, AccordionTab } from "primereact/accordion";
import StationaryButton from "./StationaryButton";
import TaxInclusive from "../../Components/TaxInclusive.";
function Stationary() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  const [service, setService] = useState([]);
  const [countSer, setCountSer] = useState([]);

  const [showError, setShowError] = useState(false);
  const [loading, setLaoding] = useState(false);
  const [title, setTitle] = useState();
  const [hcServices, setHcServices] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const { t, i18n } = useTranslation();

  console.log("services", service);

  const toggleService = (input, name, working_times) => {
    console.log("working_times", working_times);
    if (service.includes(input)) {
      ReactGA.event({
        category: "Add HouseKeeping",
        action: "Click",
        value: `HouseKeeping: ${name}`,
      });
      setService((prev) => {
        return prev.filter((x) => x !== input);
      });
    } else {
      setService((prev) => [...prev, input]);
    }
    console.log(service);
  };
  const lang = localStorage.getItem("lang");
  const currency = localStorage.getItem("currency");

  const handleBook = () => {
    let resault = [];
    // console.log("serviceeee", Object.values(service));
    // console.log("count", countSer[1]);
    Object.values(service).map((item) => {
      resault = [
        ...resault,
        {
          ["service_id"]: item,
          ["quantity"]: countSer[item],
        },
      ];
      // console.log("id", item, "  count", countSer[item]);
    });
    console.log("resault", resault);
    if (service.length <= 0) {
      setShowError(true);
    } else {
      setIsDisabled(true);
      axios
        .post(
          `/${room_uuid}/stationary/book_service`,
          {
            services: resault,
          },
          {
            headers: {
              lang: lang,
            },
          }
        )
        .then((res) => {
          setIsDisabled(false);
          if (res.data.status) {
            navigate(`/${room_uuid}/HKOrderPlaced`);
          }
        })
        .catch((err) => {
          setIsDisabled(false);
          console.log(err);
        });
    }
  };

  useEffect(() => {
    const getData = async () => {
      setLaoding(true);
      axios
        .get(`/${room_uuid}/stationary`, {
          headers: {
            lang: lang,
          },
        })
        .then((res) => {
          if (res.data.status === false) {
            navigate(`/${room_uuid}/qr-scan`);
          } else {
            const data = res.data.data;
            setHcServices(data.stationaryServices);
            setTitle(data.title);
            console.log(data);
            setLaoding(false);
          }
        })
        .catch((err) => {
          if (err?.response.status == 404) {
            navigate(`/not-found`);
          }
        });
    };
    getData();
  }, []);
  // const price = 100;
  return (
    <>
      <HeaderInner headerName={title} />
      <main className="home-main bg-white back-white pad-top-0 house">
        {loading ? (
          <SpaLoader />
        ) : (
          <>
            <SpaCarosle noDots={true} autoPlay={true} stationary={true} />
            <div className="housekeeping-container">
              <p className="service">{t("Choose a Service")}</p>
              <div className="service-container">
                {hcServices &&
                  hcServices.map((prop, idx) => {
                    const {
                      id,
                      name,
                      is_green,
                      activeSubCategories,
                      price,
                      description,
                      working_times,
                    } = prop;

                    return (
                      <>
                        <StationaryButton
                          item={prop}
                          key={idx}
                          service={service}
                          setService={setService}
                          countSer={countSer}
                          setCountSer={setCountSer}
                        />
                      </>
                    );
                  })}
              </div>
              {showError ? (
                <p className="no-service">{t("Choose a service")} </p>
              ) : null}
              <div className="book">
                <Button
                  onClick={handleBook}
                  disabled={isDisabled || service.length == 0}
                  className="reg-btn"
                >
                  {t("Submit Order")}
                </Button>
                <h3 className="text-center">
                  <TaxInclusive />
                </h3>
              </div>
            </div>
          </>
        )}
      </main>
    </>
  );
}
export default Stationary;
