import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "./banner.scss";
import Over from "../../assets/new_imgs/foods/Group-38666-1.png";
const BannerHome = ({
  className,
  headerImages,
  isFirst,
  isVideo,
  isOverllay,
}) => {
  const [intervalz, setIntervalz] = useState(isFirst ? 10000 : 3000); //initial state here represents the interval for first image.

  const onChange = (index, item) => {
    setIntervalz(item.props["data-interval"]);
  };
  if (isVideo) {
    if (headerImages.length >= 3) {
      const first = headerImages[0];
      const second = headerImages[1];
      const images = headerImages.slice(2);
      headerImages = [second, first, ...images];
    }
  }
  return (
    <div className={className}>
      <Carousel
        className="banner noSelect mb-2"
        swipeable={true}
        onChange={onChange}
        autoPlay
        interval={intervalz}
        infiniteLoop={true}
        showIndicators={false}
        showArrows={false}
        showThumbs={false}
        emulateTouch={true}
      >
        {headerImages?.map((slide) => {
          if (
            ["mp4", "avi", "mkv", "mov", "webm"].includes(
              slide.image.split(".").pop().toLowerCase()
            )
          ) {
            return (
              <div
                className="carousel-item-custom noSelect"
                data-interval={10000}
              >
                <video
                  width="100%"
                  height="165"
                  autoPlay
                  muted
                  playsinline=""
                  loop
                >
                  <source src={slide.image} type="video/mp4" />
                </video>
                {isOverllay && <img src={Over} alt="" className="ove" />}
              </div>
            );
          }

          return slide.link ? (
            <a href={slide.link}>
              <div
                className="carousel-item-custom noSelect"
                data-interval={3000}
              >
                <img src={slide.image} alt="Slide" />
                {isOverllay && <img src={Over} alt="" className="ove" />}
              </div>
            </a>
          ) : (
            <div className="carousel-item-custom noSelect" data-interval={3000}>
              <img src={slide.image} alt="Slide" />
              {isOverllay && <img src={Over} alt="" className="ove" />}
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default BannerHome;
