import { useEffect, useState } from "react";
import {
  HashRouter,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import "react-toastify/dist/ReactToastify.css";
import "aos/dist/aos.css";

import Home from "./Home";
import HomeMenu from "./HomeMenu";
import SearchPage from "./SearchPage";
import Whatsappchat from "./Whatsappchat";

import Extra from "./Extra";

import Restaurants from "./Pages/In-Room-Dining/Restaurants";
import RestaurantMenu from "./Pages/In-Room-Dining/RestaurantMenu";
import RestaurantSubMenu from "./Pages/In-Room-Dining/RestaurantSubMenu";
import RestaurantOrderPayment from "./Pages/In-Room-Dining/RestaurantOrderPayment";
import RestaurantOrderPlaced from "./Pages/In-Room-Dining/RestaurantOrderPlaced";

import Spa from "./Pages/Spa/Spa";
import SpaAddService from "./Pages/Spa/SpaAddService";
import SpaOrderPlaced from "./Pages/Spa/SpaOrderPlaced";

import Entertainment from "./Pages/Entertainment/Entertainment";
import EntertainmentDetail from "./Pages/Entertainment/EntertainmentDetail";
import EntertainmentOrderPlaced from "./Pages/Entertainment/EntertainmentOrderPlaced";

import CarRental from "./Pages/Car-Rental/CarRental";
import CarRentalOrderPlaced from "./Pages/Car-Rental/CarRentalOrderPlaced";

import Flowers from "./Pages/Flowers/Flowers";
import FlowersSubMenu from "./Pages/Flowers/FlowersSubMenu";
import FlowerOrderPayment from "./Pages/Flowers/FlowerOrderPayment";
import FlowerOrderPlaced from "./Pages/Flowers/FlowerOrderPlaced";

import Pharmacy from "./Pages/Pharmacy/Pharmacy";
import PharmacySubMenu from "./Pages/Pharmacy/PharmacySubMenu";
import PharmacyOrderPayment from "./Pages/Pharmacy/PharmacyOrderPayment";
import PharmacyOrderPlaced from "./Pages/Pharmacy/PharmacyOrderPlaced";

import Laundry from "./Pages/Laundry/Laundry";
import LaundryOrderPayment from "./Pages/Laundry/LaundryOrderPayment";
import LaundryOrderPlaced from "./Pages/Laundry/LaundryOrderPlaced";

import { PrimeReactProvider } from "primereact/api";
import Footer from "./Components/Footer";
import HouseKeeping from "./Pages/House-Keeping/HouseKeeping";
import HKOrderPlaced from "./Pages/House-Keeping/HKOrderPlaced";
import RestaurantBooking from "./Pages/Restaurant-Booking/RestaurantBooking";
import BookTable from "./Pages/Restaurant-Booking/BookTable";
import Concierge from "./Pages/Concierge/Concierge";
import ConciergeOrder from "./Pages/Concierge/ConciergeOrder";
import HealthCare from "./Pages/HealthCare/HealthCare";
import HealthCareOrder from "./Pages/HealthCare/HealthCareOrder";
import HCOrderPlaced from "./Pages/HealthCare/HCOrderPlaced";
import Taxi from "./Pages/Taxi-Booking/Taxi";
import MyOrders from "./Pages/My-Orders/MyOrders";
import Feedback from "./Pages/Feedback/Feedback";
import AdboutUs from "./Pages/About-Us/AboutUs";
import { ToastContainer } from "react-toastify";
import SalonAtHome from "./Pages/SalonAtHome/SalonAtHome";
import SATAddService from "./Pages/SalonAtHome/SATAddService";
import SATOrderPlaced from "./Pages/SalonAtHome/SATOrderPlaced";
import Checkout from "./Pages/Checkout/Checkout";
import HHTaxi from "./Pages/HH-Taxi-Booking/HHTaxi";
import Valet from "./Pages/Concierge/Valet";
import Other from "./Pages/Concierge/Other";
import ErrorPage from "./Components/ErrorPage";
import NotFoundPage from "./Pages/NotFoundPage";
import Entertainments from "./Pages/Entertainment/Entertainments";
import PharmacyCategories from "./Pages/Pharmacy/PharmacyCategories";
import FlowersCategories from "./Pages/Flowers/FlowersCategories";
import { useTranslation } from "react-i18next";
import Supermarket from "./Pages/Supermarket/Supermarket";
import SupermarketCategories from "./Pages/Supermarket/SupermarketCategories";
import SupermarketSubMenu from "./Pages/Supermarket/SupermarketSubMenu";
import SupermarketOrderPayment from "./Pages/Supermarket/SupermarketOrderPayment";
import SupermarketOrderPlaced from "./Pages/Supermarket/SupermarketOrderPlaced";
import HealthCares from "./Pages/HealthCare/HealthCares";
import { ShowSupermarketCategories } from "./API/Supermarket";
import SupermarketSubCategory from "./Pages/Supermarket/SupermarketSubCategories";
import FlowerItem from "./Pages/Flowers/FlowerItem";
import PharmacyItem from "./Pages/Pharmacy/PharmacyItem";
import SupermarketItem from "./Pages/Supermarket/SupermarketItem";
import RestaurantItem from "./Pages/In-Room-Dining/RestaurantItem";
import SalonAtHomeUUID from "./Pages/SalonAtHome/SalonAtHomeUUID";
import Shuttle from "./Pages/Concierge/Shuttle";
import HHTaxiCompany from "./Pages/HH-Taxi-Booking/HHTaxiCompany";
import Firestick from "./Pages/Concierge/Firestick";
import GuestDirectory from "./GuestDirectory";
import CheckoutCallback from "./Pages/Checkout/CheckoutCallback";
import SalonAtHomeCallback from "./Pages/Checkout/SalonAtHomeCallback";
import EntertainmentCallback from "./Pages/Checkout/EntertainmentCallback";
import LaundryCallback from "./Pages/Checkout/LaundryCallback";
import CheckIsFound from "./CheckIsFound";
import ScanQR from "./ScanQR";
import InRoomDiningScan from "./Pages/In-Room-Dining/InRoomDiningScan";
import RestaurantsScan from "./Pages/In-Room-Dining/RestaurantsScan";
import CategoryScan from "./Pages/In-Room-Dining/CategoryScan";
import InRoomDiningScanSub from "./Pages/In-Room-Dining/InRoomDiningScanSub";
import SearchPageScan from "./SearchPageScan";
import HH_HouseKeeping from "./Pages/HH_HousKeeping/HH_HouseKeeping";
import HH_HouseKeepings from "./Pages/HH_HousKeeping/HH_HouseKeepings";
import HH_HouseKeepingDetail from "./Pages/HH_HousKeeping/HH_HouseKeepingDetail";
import HH_HouseKeepingOrderPlaced from "./Pages/HH_HousKeeping/HH_HouseKeepingOrderPlaced";
import Layout from "./Components/Layout";
import InDiningScan from "./Pages/In-Room-Dining/InDiningScan";
import InDiningSubCat from "./Pages/In-Room-Dining/InDiningSubCat";
import InDiningRestaurantSubMenu from "./Pages/In-Room-Dining/InDiningRestaurantSubMenu";
import RestaurantSubCat from "./Pages/In-Room-Dining/RestaurantSubCat";
import Maintenance from "./Pages/Maintenance/Maintenance";
import Maintenances from "./Pages/Maintenance/Maintenances";
import MaintenanceOrderPlaced from "./Pages/Maintenance/MaintenanceOrderPlaced";
import NotActiveRoom from "./Pages/NotActiveRoom";
import ComboMealsItems from "./Pages/In-Room-Dining/ComboMealsItems";
import ScanComboMealsItems from "./Pages/In-Room-Dining/ScanComboMealsItems";
import ScanQrCode from "./ScanQrCode";
import Notification from "./Components/Notification";
import PharmacySubCategory from "./Pages/Pharmacy/PhamacySubCategories";
import Stationary from "./Pages/Stationary/Stationary";
import MeetingEquipment from "./Pages/MeetingEquipment/MeetingEquipment";
import RequestMessenger from "./Pages/Concierge/RequestMessenger";
import Luxurios from "./Pages/Luxurios/Luxurios";
import Luxurioss from "./Pages/Luxurios/Luxurioss";
import LuxuriosDetail from "./Pages/Luxurios/LuxuriosDetail";
import LuxuriosOrderPlaced from "./Pages/Luxurios/LuxuriosOrderPlaced";
import NewHome from "./NewHome";
import FoodAndBeverages from "./pages/food-and-beverages/FoodAndBeverages";
import NewRestaurantBooking from "./pages/food-and-beverages/NewRestaurantBooking";
import InRoomDining from "./pages/food-and-beverages/InRoomDining";
import InDiningCategories from "./pages/food-and-beverages/InDiningCategories";
import InDiningSubCategories from "./pages/food-and-beverages/InDiningSubCategories";
import InDiningItems from "./pages/food-and-beverages/InDiningItems";
import Cart from "./pages/checkout/Cart";
import RestaurantOrder from "./pages/food-and-beverages/RestaurantOrder";
import Search from "./pages/search/Search";
import HotelServices from "./pages/hotel-services/HotelServices";
import LaundryService from "./pages/hotel-services/LaundryService";
import LaundryOrder from "./pages/hotel-services/LaundryOrder";
import LaundryPayment from "./pages/hotel-services/LaundryPayment";
import ConceirgeServices from "./pages/hotel-services/ConceirgeServices";
import SpaService from "./pages/hotel-services/SpaService";
import SalonService from "./pages/hotel-services/SalonService";
import HhHousekeeping from "./pages/hotel-services/HhHousekeeping";
import PharmacyService from "./pages/external-services/PharmacyService";
import ExternalServices from "./pages/external-services/ExternalServices";
import FlowersService from "./pages/external-services/FlowersService";
import SupermarketService from "./pages/external-services/SupermarketService";
import QrHome from "./QrHome";
import EntertainmentService from "./pages/external-services/EntertainmentService";
import CarsService from "./pages/external-services/CarsService";
import SpaAndWellness from "./pages/spa-and-wellness/SpaAndWellness";
import HealthCareService from "./pages/spa-and-wellness/HealthCareService";

function App() {
  const location = useLocation();
  const [hideFooter, setHideFooter] = useState(false);
  // const location = useLocation()
  useEffect(() => {
    if (
      location.pathname.includes("/RestaurantSubMenu") ||
      location.pathname.includes("/PharmacySubMenu") ||
      location.pathname.includes("/FlowersSubMenu") ||
      location.pathname.includes("/SupermarketSubMenu") ||
      location.pathname.includes("/restaurant-menu/categories") ||
      location.pathname.includes("/restaurant-menu/cat") ||
      location.pathname.includes("/restaurants/search") ||
      location.pathname.includes("/restaurant-menu") ||
      location.pathname.includes("/qr-scan") ||
      location.pathname.includes("/not-found")
    ) {
      setHideFooter(true);
    } else {
      setHideFooter(false);
    }
  }, [location.pathname]);
  const { t, i18n } = useTranslation();
  localStorage.setItem("lang", i18n.language);
  // const lang = localStorage.getItem("lang");

  // console.log("lang", lang);
  // const langu = localStorage.getItem("langu");
  const lang = localStorage.getItem("lang");
  return (
    <>
      <div
        className={`container-wrap ${i18n.language == "AR" ? "ar_lang" : ""}`}
      >
        <div
          className="content"
          style={location.pathname === "/" ? { overflowY: "hidden" } : null}
        >
          <ToastContainer position="top-right" />
          <Routes>
            <Route path="/:room_uuid" element={<CheckIsFound />} />

            <Route path="/" element={<Layout />}>
              <Route path=":room_uuid/qr-code" element={<QrHome />} />
              <Route path=":room_uuid/qrcode" element={<NewHome />} />
              <Route
                path=":room_uuid/food-and-beverages"
                element={<FoodAndBeverages />}
              />
              <Route
                path=":room_uuid/food-and-beverages/restaurant-booking"
                element={<NewRestaurantBooking />}
              />
              <Route
                path=":room_uuid/food-and-beverages/in-room-dining"
                element={<InRoomDining />}
              />
              <Route
                path=":room_uuid/food-and-beverages/restaurant-booking/:restaurant_uuid"
                element={<InDiningCategories />}
              />
              <Route
                path=":room_uuid/food-and-beverages/in-room-dining/:restaurant_uuid"
                element={<InDiningCategories />}
              />
              <Route
                path=":room_uuid/food-and-beverages/order"
                element={<RestaurantOrder />}
              />
              <Route
                path=":room_uuid/food-and-beverages/in-room-dining/:restaurant_uuid/:cat"
                element={<InDiningSubCategories />}
              />
              <Route
                path=":room_uuid/food-and-beverages/restaurant-booking/:restaurant_uuid/:cat"
                element={<InDiningSubCategories />}
              />
              <Route
                path=":room_uuid/food-and-beverages/in-room-dining/:restaurant_uuid/category/:cat"
                element={<InDiningItems />}
              />
              <Route
                path=":room_uuid/food-and-beverages/restaurant-booking/:restaurant_uuid/category/:cat"
                element={<InDiningItems />}
              />
              <Route path=":room_uuid/cart" element={<Cart />} />
              <Route path=":room_uuid/search" element={<Search />} />

              <Route
                path=":room_uuid/hotel-services"
                element={<HotelServices />}
              />
              <Route
                path=":room_uuid/hotel-services/laundry/:laundry_uuid"
                element={<LaundryService />}
              />
              <Route
                path=":room_uuid/hotel-services/spa/:spa_uuid"
                element={<SpaService />}
              />
              <Route
                path=":room_uuid/hotel-services/salon"
                element={<SalonService />}
              />
              <Route
                path=":room_uuid/hotel-services/conceirge"
                element={<ConceirgeServices />}
              />
              <Route
                path="/:room_uuid/hotel-services/laundry/order/:laundry_uuid/:laundry_id/v/:vat/fee/:fee/min/:min"
                element={<LaundryOrder />}
              />
              <Route
                path="/:room_uuid/hotel-services/laundry/order"
                element={<LaundryPayment />}
              />
              <Route
                path="/:room_uuid/hotel-services/housekeeping"
                element={<HouseKeeping />}
              />
              <Route
                path="/:room_uuid/external-services/hh-housekeeping"
                element={<HhHousekeeping />}
              />
              <Route
                path=":room_uuid/food-and-beverages/in-room-dining"
                element={<InRoomDining />}
              />
              <Route
                path=":room_uuid/spa-wellness"
                element={<SpaAndWellness />}
              />
              <Route
                path=":room_uuid/spa-wellness/health_care_and_wellness"
                element={<HealthCare />}
              />
              <Route
                path=":room_uuid/external-services"
                element={<ExternalServices />}
              />
              <Route
                path=":room_uuid/external-services/pharmacy"
                element={<PharmacyService />}
              />
              <Route
                path=":room_uuid/external-services/flowers"
                element={<FlowersService />}
              />
              <Route
                path=":room_uuid/external-services/supermarket"
                element={<SupermarketService />}
              />
              <Route
                path=":room_uuid/external-services/entertainment"
                element={<EntertainmentService />}
              />
              <Route
                path=":room_uuid/external-services/cars"
                element={<CarsService />}
              />
              <Route
                path=":room_uuid/external-services/taxi"
                element={<Taxi />}
              />
              <Route
                path=":room_uuid/external-services/hh-taxi"
                element={<HHTaxiCompany />}
              />

              <Route exact index element={<NotFoundPage />} />
              <Route exact path=":room_uuid/menu" element={<Home />} />
              <Route
                exact
                path=":room_uuid/GuestDirectory"
                element={<GuestDirectory />}
              />
              <Route path=":room_uuid/SearchPage" element={<SearchPage />} />
              <Route path="/Whatsappchat" element={<Whatsappchat />} />
              <Route
                path="/:room_uuid/restaurant/categories/:cat_uuid"
                element={<Restaurants />}
              />
              <Route
                path="/:room_uuid/restaurant-menu/categories"
                element={<InRoomDiningScan />}
              />
              <Route
                path="/:room_uuid/restaurant-menu/cat/:cat_id"
                element={<InRoomDiningScanSub />}
              />
              <Route
                path="/:room_uuid/restaurant-menu/restaurants-booking"
                element={<RestaurantsScan />}
              />
              <Route
                path="/:room_uuid/restaurant/combo-meals/:restaurant_uuid"
                element={<ComboMealsItems />}
              />
              <Route
                path="/:room_uuid/restaurant-menu/combo-meals"
                element={<ScanComboMealsItems />}
              />
              <Route
                path="/:room_uuid/restaurant-menu/:cat_id"
                element={<CategoryScan />}
              />
              <Route
                path="/:room_uuid/restaurants"
                element={<RestaurantBooking />}
              />
              <Route
                path="/:room_uuid/restaurants/search"
                element={<SearchPageScan />}
              />
              {/* <Route
              path="/:room_uuid/restaurants?b=kit"
              element={<Restaurants />}
            /> */}
              <Route
                path="/:room_uuid/RestaurantMenu/:id/:restaurnat_id"
                element={<RestaurantMenu />}
              />
              <Route
                path="/:room_uuid/rstauran-menu-categories/:id"
                element={<InDiningScan />}
              />
              <Route
                path="/:room_uuid/RestaurantBooking/RestaurantMenu/:id/:restaurnat_id"
                element={<RestaurantMenu />}
              />
              <Route
                path="/:room_uuid/RestaurantSubCat/:id/:cat_id"
                element={<RestaurantSubCat />}
              />
              <Route
                path="/:room_uuid/rstauran-menu-subcategories/:id/:cat_id"
                element={<InDiningSubCat />}
              />
              <Route
                path="/:room_uuid/RestaurantBooking/RestaurantSubCat/:id/:cat_id"
                element={<RestaurantSubCat />}
              />
              <Route
                path="/:room_uuid/RestaurantSubMenu/:r_uuid/:c_id"
                element={<RestaurantSubMenu />}
              />
              <Route
                path="/:room_uuid/rstauran-menu-items/:r_uuid/:c_id"
                element={<InDiningRestaurantSubMenu />}
              />
              <Route
                path="/:room_uuid/RestaurantBooking/RestaurantSubMenu/:r_uuid/:c_id"
                element={<RestaurantSubMenu />}
              />
              <Route
                path="/RestaurantOrderPayment"
                element={<RestaurantOrderPayment />}
              />
              <Route
                path="/:room_uuid/RestaurantOrderPlaced"
                element={<RestaurantOrderPlaced />}
              />
              <Route
                path="/:room_uuid/salon_at_home"
                element={<SalonAtHome />}
              />
              <Route
                path="/:room_uuid/salon_at_home/:uuid"
                element={<SalonAtHomeUUID />}
              />
              <Route
                path="/:room_uuid/SATAddService/:salon_uuid/:salon_id/:service_id"
                element={<SATAddService />}
              />
              <Route path="/SATOrderPlaced" element={<SATOrderPlaced />} />
              <Route path="/:room_uuid/spa/:spa_uuid" element={<Spa />} />
              <Route
                path="/:room_uuid/spa/:spa_uuid/service/:spa_id"
                element={<SpaAddService />}
              />
              <Route
                path="/:room_uuid/SpaOrderPlaced"
                element={<SpaOrderPlaced />}
              />
              <Route
                path="/:room_uuid/maintenance/companies"
                element={<Maintenance />}
              />
              <Route
                path="/:room_uuid/maintenance/companies/:uuid"
                element={<Maintenances />}
              />
              <Route
                path="/:room_uuid/MaintenanceOrderPlaced"
                element={<MaintenanceOrderPlaced />}
              />
              <Route
                path="/:room_uuid/luxurios/companies"
                element={<Luxurios />}
              />
              <Route
                path="/:room_uuid/luxurios/categories/:uuid"
                element={<Luxurioss />}
              />
              <Route
                path="/:room_uuid/luxurios-detail/:id"
                element={<LuxuriosDetail />}
              />
              <Route
                path="/:room_uuid/LuxuriosOrderPlaced"
                element={<LuxuriosOrderPlaced />}
              />
              <Route
                path="/:room_uuid/entertainment/:compane"
                element={<Entertainment />}
              />
              <Route
                path="/:room_uuid/entertainment/categories/:uuid"
                element={<Entertainments />}
              />
              <Route
                path="/:room_uuid/EntertainmentDetail/:id"
                element={<EntertainmentDetail />}
              />
              <Route
                path="/:room_uuid/EntertainmentOrderPlaced"
                element={<EntertainmentOrderPlaced />}
              />
              <Route
                path="/:room_uuid/HH_HouseKeepingOrderPlaced"
                element={<HH_HouseKeepingOrderPlaced />}
              />
              <Route
                path="/:room_uuid/hh-house-keeping-companies"
                element={<HH_HouseKeeping />}
              />
              <Route
                path="/:room_uuid/hh-house-keeping-companies/services/:uuid"
                element={<HH_HouseKeepings />}
              />
              <Route
                path="/:room_uuid/HH_HouseKeepingDetail/:company_uuid/:id"
                element={<HH_HouseKeepingDetail />}
              />
              <Route path="/:room_uuid/cars" element={<CarRental />} />
              <Route
                path="/:room_uuid/CarRentalOrderPlaced"
                element={<CarRentalOrderPlaced />}
              />
              <Route path="/:room_uuid/florists" element={<Flowers />} />
              <Route
                path="/:room_uuid/florists/:c_id/:f_id"
                element={<FlowerItem />}
              />
              <Route
                path="/:room_uuid/pharmacy/:c_id/:f_id"
                element={<PharmacyItem />}
              />
              <Route
                path="/:room_uuid/supermarket/:c_id/:f_id"
                element={<SupermarketItem />}
              />
              <Route
                path="/:room_uuid/reastrant/:c_id/:f_id"
                element={<RestaurantItem />}
              />
              <Route
                path="/:room_uuid/flower/category/:flower_uuid/id/:flower_id"
                element={<FlowersCategories />}
              />
              <Route
                path="/:room_uuid/FlowersSubMenu/:flower_id/:flower_uuid/:id"
                element={<FlowersSubMenu />}
              />
              <Route
                path="/FlowerOrderPayment"
                element={<FlowerOrderPayment />}
              />
              <Route
                path="/FlowerOrderPlaced"
                element={<FlowerOrderPlaced />}
              />
              <Route path="/:room_uuid/pharmacies" element={<Pharmacy />} />
              <Route
                path="/:room_uuid/pharmacy/category/:pharmacie_uuid/id/:pharmacie_id"
                element={<PharmacyCategories />}
              />
              <Route
                path="/:room_uuid/PharmacySubCategory/:pharmacie_uuid/:cat_id"
                element={<PharmacySubCategory />}
              />
              <Route
                path="/:room_uuid/PharmacySubMenu/:pharmacy_id/:pharmacy_uuid/:id"
                element={<PharmacySubMenu />}
              />
              <Route
                path="/PharmacyOrderPayment"
                element={<PharmacyOrderPayment />}
              />
              <Route
                path="/PharmacyOrderPlaced"
                element={<PharmacyOrderPlaced />}
              />
              <Route path="/:room_uuid/Stationary" element={<Stationary />} />
              <Route
                path="/:room_uuid/tech_support"
                element={<MeetingEquipment />}
              />
              <Route
                path="/:room_uuid/supermarkets"
                element={<Supermarket />}
              />
              <Route
                path="/:room_uuid/supermarket/:supermarket_uuid"
                element={<SupermarketCategories />}
              />
              <Route
                path="/:room_uuid/orders/supermarket-suggestion/:order_id"
                element={<SupermarketOrderPlaced />}
              />
              <Route
                path="/:room_uuid/SupermarketSubMenu/:supermarket_id/:supermarket_uuid/:id"
                element={<SupermarketSubMenu />}
              />
              <Route
                path="/:room_uuid/SupermarketSubCategory/:supermarket_uuid/:cat_id"
                element={<SupermarketSubCategory />}
              />
              <Route
                path="/SupermarketOrderPayment"
                element={<SupermarketOrderPayment />}
              />
              <Route
                path="/SupermarketOrderPlaced"
                element={<SupermarketOrderPlaced />}
              />
              <Route
                path="/:room_uuid/laundry/:laundry_uuid"
                element={<Laundry />}
              />
              <Route
                path="/:room_uuid/LaundryOrderPayment/:laundry_uuid/:laundry_id/v/:vat/fee/:fee/min/:min"
                element={<LaundryOrderPayment />}
              />
              <Route
                path="/:room_uuid/LaundryOrderPlaced"
                element={<LaundryOrderPlaced />}
              />
              <Route
                path="/:room_uuid/house_keeping"
                element={<HouseKeeping />}
              />
              <Route
                path="/:room_uuid/HKOrderPlaced"
                element={<HKOrderPlaced />}
              />
              <Route
                path="/:room_uuid/BookTable/:restaurant_uuid/:restaurant_id"
                element={<BookTable />}
              />
              <Route path="/:room_uuid/Concierge" element={<Concierge />} />
              <Route
                path="/:room_uuid/luggage-pickup"
                element={<ConciergeOrder />}
              />
              <Route path="/:room_uuid/valet" element={<Valet />} />
              <Route path="/:room_uuid/other" element={<Other />} />
              <Route path="/:room_uuid/shuttle" element={<Shuttle />} />
              <Route path="/:room_uuid/Firestick" element={<Firestick />} />
              <Route
                path="/:room_uuid/request-messenger"
                element={<RequestMessenger />}
              />
              <Route
                path="/:room_uuid/health_care_and_wellness/:com"
                element={<HealthCare />}
              />
              <Route
                path="/:room_uuid/healthCar/categories/:health_uuid"
                element={<HealthCares />}
              />
              <Route
                path="/:room_uuid/HealthcareOrder/:id"
                element={<HealthCareOrder />}
              />
              <Route
                path="/:room_uuid/HCOrderPlaced"
                element={<HCOrderPlaced />}
              />
              <Route path="/:room_uuid/Taxi" element={<Taxi />} />
              <Route
                path="/:room_uuid/hh-taxibooking-companies"
                element={<HHTaxiCompany />}
              />
              <Route
                path="/:room_uuid/hh-taxibooking-companies/:hh_uuid"
                element={<HHTaxi />}
              />
              <Route path="/:room_uuid/Checkout" element={<Checkout />} />
              <Route
                path="/:room_uuid/Checkout-dining"
                element={<Checkout />}
              />
              <Route
                path="/:room_uuid/CheckoutCallback"
                element={<CheckoutCallback />}
              />
              <Route
                path="/:room_uuid/LaundryCallback"
                element={<LaundryCallback />}
              />
              <Route
                path="/:room_uuid/SalonAtHomeCallback"
                element={<SalonAtHomeCallback />}
              />
              <Route
                path="/:room_uuid/EntertainmentCallback"
                element={<EntertainmentCallback />}
              />
              <Route path="/:room_uuid/MyOrders" element={<MyOrders />} />
              <Route path="/:room_uuid/Feedback" element={<Feedback />} />
              <Route path="/:room_uuid/AboutUs" element={<AdboutUs />} />
              <Route path="/:room_uuid/qr-scan" element={<ScanQR />} />
              <Route path="/:room_uuid/scan-qr-code" element={<ScanQrCode />} />
              <Route path="/Extra" element={<Extra />} />
              <Route path="/not-found" element={<NotFoundPage />} />
              <Route path="/room_not_active" element={<NotActiveRoom />} />
              <Route path="/*" element={<ScanQR />} />
            </Route>
          </Routes>

          {/* {!hideFooter && <Footer />} */}
        </div>
      </div>
    </>
  );
}
export default App;
