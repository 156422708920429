import React from "react";
import { Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import BookingCarosle from "../../Components/BookingCarosle";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";

const BookingCard = ({ restaurant }) => {
  console.log("restaurant.images", restaurant.images);
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const { t, i18n } = useTranslation();

  return (
    <div
      className={`contianer booking-card ${
        restaurant.is_closed ? "closed restaurant-option" : "restaurant-option"
      }`}
    >
      <div className="closed-tag"></div>
      {restaurant?.is_closed && (
        <span className="open_at">
          {restaurant.opens_at ? `Open at ${restaurant.opens_at}` : `Closed`}
        </span>
      )}
      <div className="bc-img">
        <BookingCarosle
          noAuto={true}
          images={
            restaurant.real_video
              ? [{ image: restaurant.real_video }, ...restaurant.images]
              : restaurant.images.length == 0
              ? [{ image: restaurant.image }]
              : restaurant.images
          }
          isVideo={restaurant.real_video ? true : false}
        />
        {/* <BookingCarosle noAuto={true} images={restaurant.images.length == 0 ? [restaurant.image] : restaurant.images} /> */}
      </div>
      <div className="bc-btns">
        {restaurant.show_booking == 1 && (
          <Link
            to={`/${room_uuid}/BookTable/${restaurant.uuid}/${restaurant.id}`}
            className="bc-btn-1"
            onClick={() => {
              ReactGA.event({
                category: "Click on Book a Table in Restaurant Booking page",
                action: "Click",
                value: ``,
              });
            }}
          >
            {t("Book a Table")}
          </Link>
        )}

        {restaurant.show_menu == 1 && (
          <Link
            className="bc-btn-2"
            to={`/${room_uuid}/RestaurantBooking/RestaurantMenu/${restaurant.uuid}/${restaurant.id}`}
            onClick={() => {
              ReactGA.event({
                category: "Click on Menu in Restaurant Booking page",
                action: "Click",
                value: ``,
              });
            }}
          >
            {t("Menu")}
          </Link>
        )}
      </div>
      <div className="bc-text">
        {restaurant.show_name == 1 && (
          <p className="bct-1">{restaurant.name}</p>
        )}
        <p className="bct-2">
          {restaurant?.cuisines?.map((cuisine, idx) => {
            if (idx + 1 < restaurant?.cuisines.length) {
              return `${cuisine.name}  |  `;
            } else {
              return `${cuisine.name}  `;
            }
          })}
        </p>
      </div>
    </div>
  );
};

export default BookingCard;
