import React from "react";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import InRoomDining from "../assets/images/option-icons/in-room-dining.png";
import CarRental from "../assets/images/option-icons/Car-Rental.png";
import ConciergeServices from "../assets/images/option-icons/Concierge-Services.png";
import Entertainment from "../assets/images/option-icons/Entertainment.png";
import flowers from "../assets/images/option-icons/flowers.png";
import Housekeeping from "../assets/images/option-icons/Housekeeping.png";
import Laundry from "../assets/images/option-icons/Laundry.png";
import Pharmacy from "../assets/images/option-icons/Pharmacy.png";
import RestaurantBooking from "../assets/images/option-icons/Restaurant-Booking.png";
import Spa from "../assets/images/option-icons/spa.png";
import Supermarket from "../assets/images/option-icons/Supermarket.png";
import Taxi from "../assets/images/option-icons/taxi.png";
import Zoom from "react-reveal/Zoom";

import ReactGA from "react-ga4";
import Meeting from "../assets/images/icons/Meeting.png";
import Stationary from "../assets/images/icons/Stationary.png";
import Valet from "../assets/images/icons/Valet.png";
import { useTranslation } from "react-i18next";

function MenuList({ services, loading, has_extra_services }) {
  const { t, i18n } = useTranslation();

  // console.log("has_extra_services",has_extra_services);
  const [showClass, setShowClass] = useState(true);
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShowClass(true);
  //   }, 3000);

  //   return () => clearTimeout(timer);
  // }, []);

  const routes = {
    Laundry: `/${room_uuid}/Laundry`,
    "Food Orders": `/${room_uuid}/Restaurants`,
    "In Room Dining": `/${room_uuid}/Restaurants`,
    "Restaurant Booking": `/${room_uuid}/RestaurantBooking `,
    Pharmacy: `/${room_uuid}/Pharmacy`,
    Flowers: `/${room_uuid}/Flowers`,
    "Car Rental": `/${room_uuid}/CarRental`,
    Housekeeping: `/${room_uuid}/HouseKeeping`,
    Entertainment: `/${room_uuid}/Entertainment`,
    "Healthcare & Wellness": `/${room_uuid}/Healthcare`,
    "Salon at Home": `/${room_uuid}/SalonAtHome`,
    Spa: `/${room_uuid}/Spa`,
    "HH Taxi Booking": `/${room_uuid}/HHTaxi`,
    "Taxi Booking": `/${room_uuid}/Taxi`,
    "Concierge Services": `/${room_uuid}/Concierge`,
  };
  const lang = localStorage.getItem("lang");

  const changeRoute = (link) => {
    if (link.includes("kit")) {
      return `${room_uuid}/restaurant/categories/10`;
    }
    // if (link.includes("hh-house-keeping-companies")) {
    //   console.log("house_keeping");
    //   return `${room_uuid}/house_keeping`;
    // }
    return link;
  };

  let color = localStorage.getItem("color");
  return (
    <>
      <ul
        className={
          loading ? "animated-background bg-skimmer menu-list" : "menu-list"
        }
      >
        {services?.map((service, idx) => {
          return (
            <li
              key={idx}
              onClick={() => {
                ReactGA.event({
                  category: "Click on Service",
                  action: "Click",
                  value: `service name: ${service.display_name}}`,
                });
              }}
            >
              <Zoom>
                <Link
                  id="trans-anime"
                  className={`trans closed ${
                    service.is_closed && "services-option"
                  } `}
                  // to={routes[service.service_name]}
                  to={!service.is_closed && `/${changeRoute(service.link)}`}
                >
                  <div className="img-pnl  position-relative ">
                    <div className="closed-tag"></div>
                    <img
                      src={service.icon}
                      className=""
                      style={{ width: "45px" }}
                      alt="In-Room Dining"
                    />
                    <div
                      className={
                        !showClass ? "animated-background bg-skimmer" : ""
                      }
                      style={{
                        borderRadius: "50%",
                        left: "-5px",
                        top: "-5px",
                        bottom: "-5px",
                        right: "-5px",
                      }}
                    ></div>
                  </div>
                  <div className="txt-pnl position-relative">
                    <div
                      className={
                        !showClass ? "animated-background bg-skimmer" : ""
                      }
                    ></div>

                    <p className="">
                      {lang == "AR" && service.id == 21
                        ? service.service_name
                        : service.display_name}
                    </p>
                  </div>
                </Link>
              </Zoom>
            </li>
          );
        })}
        {/* {has_extra_services && (
          <>
            <li key={"Stationary"}>
              <Zoom>
                <Link
                  id="trans-anime"
                  className={`trans closed  `}
                  // to={routes[service.service_name]}
                  to={`/${room_uuid}/Stationary`}
                >
                  <div className="img-pnl  position-relative ">
                    <div className="closed-tag"></div>
                    <img
                      src={Stationary}
                      className=""
                      style={{ width: "45px" }}
                      alt="In-Room Dining"
                    />
                    <div
                      className={
                        !showClass ? "animated-background bg-skimmer" : ""
                      }
                      style={{
                        borderRadius: "50%",
                        left: "-5px",
                        top: "-5px",
                        bottom: "-5px",
                        right: "-5px",
                      }}
                    ></div>
                  </div>
                  <div className="txt-pnl position-relative">
                    <div
                      className={
                        !showClass ? "animated-background bg-skimmer" : ""
                      }
                    ></div>

                    <p className=""> {t("Stationary")}</p>
                  </div>
                </Link>
              </Zoom>
            </li>
            <li key={"Tech Support"}>
              <Zoom>
                <Link
                  id="trans-anime"
                  className={`trans closed  `}
                  // to={routes[service.service_name]}
                  to={`/${room_uuid}/Tech-support`}
                >
                  <div className="img-pnl  position-relative ">
                    <div className="closed-tag"></div>
                    <img
                      src={Meeting}
                      className=""
                      style={{ width: "45px" }}
                      alt="In-Room Dining"
                    />
                    <div
                      className={
                        !showClass ? "animated-background bg-skimmer" : ""
                      }
                      style={{
                        borderRadius: "50%",
                        left: "-5px",
                        top: "-5px",
                        bottom: "-5px",
                        right: "-5px",
                      }}
                    ></div>
                  </div>
                  <div className="txt-pnl position-relative">
                    <div
                      className={
                        !showClass ? "animated-background bg-skimmer" : ""
                      }
                    ></div>

                    <p className=""> {t("Tech Support")}</p>
                  </div>
                </Link>
              </Zoom>
            </li>
            <li key={"valet"}>
              <Zoom>
                <Link
                  id="trans-anime"
                  className={`trans closed  `}
                  // to={routes[service.service_name]}
                  to={`/${room_uuid}/valet`}
                >
                  <div className="img-pnl  position-relative ">
                    <div className="closed-tag"></div>
                    <img
                      src={Valet}
                      className=""
                      style={{ width: "45px" }}
                      alt="In-Room Dining"
                    />
                    <div
                      className={
                        !showClass ? "animated-background bg-skimmer" : ""
                      }
                      style={{
                        borderRadius: "50%",
                        left: "-5px",
                        top: "-5px",
                        bottom: "-5px",
                        right: "-5px",
                      }}
                    ></div>
                  </div>
                  <div className="txt-pnl position-relative">
                    <div
                      className={
                        !showClass ? "animated-background bg-skimmer" : ""
                      }
                    ></div>

                    <p className="">{t("Valet")} </p>
                  </div>
                </Link>
              </Zoom>
            </li>
          </>
        )} */}

        {/*  <li>
          <Link
            id="trans-anime"
            className={showClass ? "trans" : ""}
            to="/Restaurants"
          >
            <div className="img-pnl ">
              <img src={InRoomDining} alt="In-Room Dining" />
            </div>
            <div className="txt-pnl">
              <p className="">In-Room Dining</p>
            </div>
          </Link>
        </li>
        <li>
          <Link
            id="trans-anime"
            className={showClass ? "trans" : ""}
            to="/RestaurantBooking"
          >
            <div className="img-pnl">
              <img src={RestaurantBooking} alt="Restaurant Booking" />
            </div>
            <div className="txt-pnl">
              <p>Restaurant Booking</p>
            </div>
          </Link>
        </li>
        <li>
          <Link
            id="trans-anime"
            className={showClass ? "trans" : ""}
            to="/Laundry"
          >
            <div className="img-pnl">
              <img
                src={
                  "https://tdhbucket.s3.me-central-1.amazonaws.com/business_services/8.png"
                }
                className="restaurant-icon"
                alt="Laundry"
              />
            </div>
            <div className="txt-pnl">
              <p>Laundry</p>
            </div>
          </Link>
        </li>
        <li>
          <Link id="trans-anime" className={showClass ? "trans" : ""} to="/Spa">
            <div className="img-pnl">
              <img src={Spa} alt="SPA" />
            </div>
            <div className="txt-pnl">
              <p>SPA</p>
            </div>
          </Link>
        </li>
        <li>
          <Link
            id="trans-anime"
            className={showClass ? "trans" : ""}
            to="/HouseKeeping"
          >
            <div className="img-pnl">
              <img
                src={
                  "https://tdhbucket.s3.me-central-1.amazonaws.com/business_services/6.png"
                }
                className="restaurant-icon"
                alt="Housekeeping"
              />
            </div>
            <div className="txt-pnl">
              <p>Housekeeping</p>
            </div>
          </Link>
        </li>
        <li>
          <Link
            id="trans-anime"
            className={showClass ? "trans" : ""}
            to="/Concierge"
          >
            <div className="img-pnl">
              <img src={ConciergeServices} alt="Concierge Services" />
            </div>
            <div className="txt-pnl">
              <p>Concierge Services</p>
            </div>
          </Link>
        </li>
        <li>
          <Link id="trans-anime" className={showClass ? "trans" : ""} to="/">
            <div className="img-pnl">
              <img src={Supermarket} alt="Supermarket" />
            </div>
            <div className="txt-pnl">
              <p>Supermarket</p>
            </div>
          </Link>
        </li>
        <li>
          <Link
            id="trans-anime"
            className={showClass ? "trans" : ""}
            to="/Pharmacy"
          >
            <div className="img-pnl">
              <img
                src={
                  "https://tdhbucket.s3.me-central-1.amazonaws.com/business_services/2.png"
                }
                className="restaurant-icon"
                alt="Pharmacy"
              />
            </div>
            <div className="txt-pnl">
              <p>Pharmacy</p>
            </div>
          </Link>
        </li>
        <li>
          <Link
            id="trans-anime"
            className={showClass ? "trans" : ""}
            to="/Flowers"
          >
            <div className="img-pnl">
              <img
                src={
                  "https://tdhbucket.s3.me-central-1.amazonaws.com/business_services/1.png"
                }
                className="restaurant-icon"
                alt="Flowers"
              />
            </div>
            <div className="txt-pnl">
              <p>Flowers</p>
            </div>
          </Link>
        </li>
        <li>
          <Link
            id="trans-anime"
            className={showClass ? "trans" : ""}
            to="/CarRental"
          >
            <div className="img-pnl">
              <img src={CarRental} alt="Car Rental" />
            </div>
            <div className="txt-pnl">
              <p>Car Rental</p>
            </div>
          </Link>
        </li>
        <li>
          <Link id="trans-anime" className={showClass ? "trans" : ""} to="/">
            <div className="img-pnl">
              <img
                src={
                  "https://tdhbucket.s3.me-central-1.amazonaws.com/business_services/15.png"
                }
                className="restaurant-icon"
                alt="Supermarket"
              />
            </div>
            <div className="txt-pnl">
              <p>Maintenance Services</p>
            </div>
          </Link>
        </li>
        <li>
          <Link
            id="trans-anime"
            className={showClass ? "trans" : ""}
            to="/Entertainment"
          >
            <div className="img-pnl">
              <img src={Entertainment} alt="Entertainment" />
            </div>
            <div className="txt-pnl">
              <p>Entertainment</p>
            </div>
          </Link>
        </li>
        <li>
          <Link
            id="trans-anime"
            className={showClass ? "trans" : ""}
            to="/Healthcare"
          >
            <div className="img-pnl">
              <img
                src={
                  "https://tdhbucket.s3.me-central-1.amazonaws.com/business_services/17.png"
                }
                className="restaurant-icon"
                alt="Supermarket"
              />
            </div>
            <div className="txt-pnl">
              <p>Healthcare & Wellness</p>
            </div>
          </Link>
        </li>
        <li>
          <Link
            id="trans-anime"
            className={showClass ? "trans" : ""}
            to="/Taxi"
          >
            <div className="img-pnl">
              <img src={Taxi} alt="Taxi Booking" />
            </div>
            <div className="txt-pnl">
              <p>Taxi Booking</p>
            </div>
          </Link>
        </li> */}
      </ul>
    </>
  );
}
export default MenuList;
