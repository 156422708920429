import React, { useState } from "react";
import "./New.scss";
import Banner from "./assets/new_imgs/Group 38650.png";
import Food from "./assets/new_imgs/Mask Group 63.png";
import Sevices from "./assets/new_imgs/Group 38654.png";
import Taxi from "./assets/new_imgs/Group 38651.png";
import Bed from "./assets/new_imgs/Group 38655.png";
import Logo from "./assets/new_imgs/address-downtown-logo.svg";
import Rectangle from "./assets/new_imgs/Rectangle 1072.png";

import { Carousel } from "react-responsive-carousel";
import NewFooter from "./NewFooter";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ShowHomeMenu } from "./API/HomeMenuAPI";
import BannerHome from "./components/banner-home/BannerHome";
import NewLoader from "./components/title/Loader/NewLoader";
import { useTranslation } from "react-i18next";

const NewHome = () => {
  const { t, i18n } = useTranslation();

  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 4000,
  };
  const params = useParams();
  const navigate = useNavigate();
  const [showServices, setShowServices] = useState(false);
  const [openLang, setOpenLang] = useState(true);
  const lang = localStorage.getItem("lang" || "EN");

  const { data, isLoading, error } = ShowHomeMenu(params.room_uuid, lang);

  let languages;
  let headerImages;
  let services;

  if (!isLoading) {
    console.log("data", data);
    const menu = data.data.data;
    languages = data?.data?.data?.Languages;
    services = data?.data?.data?.activeServices;

    headerImages = menu.realvideo
      ? [{ image: menu.realvideo }, ...menu?.header_images]
      : menu?.header_images;
  }

  const getGreeting = () => {
    const currentHour = new Date().getHours();

    if (currentHour >= 6 && currentHour < 12) {
      return t("Good Morning");
    } else if (currentHour >= 12 && currentHour < 18) {
      return t("Good Afternoon");
    } else if (currentHour >= 18 && currentHour < 22) {
      return t("Good Evening");
    } else {
      return "Hello";
    }
  };

  const changeLang = (code) => {
    localStorage.setItem("lang", code);
    i18n.changeLanguage(code);

    setOpenLang(false);
  };

  let langArray = [];
  languages?.map((language) => {
    if (language.code == "EN") {
      console.log("tes");
      langArray.push({ ...language, name: "English" });
    }
    if (language.code == "AR") {
      langArray.push({ ...language, name: "العربية" });
    }
    if (language.code == "FR") {
      langArray.push({ ...language, name: "Français" });
    }
    if (language.code == "RU") {
      langArray.push({ ...language, name: "Русский" });
    }
    if (language.code == "DI") {
      langArray.push({ ...language, name: "Deutsch" });
    }
    if (language.code == "CH") {
      langArray.push({ ...language, name: "中文" });
    }
    if (language.code == "HI") {
      langArray.push({ ...language, name: "한국어" });
    }
  });

  return (
    <div className={`new_page ${lang == "AR" && "arabic"}`}>
      {isLoading ? (
        <NewLoader />
      ) : (
        <>
          <div className={`box_lang ${openLang ? "" : "not_show"}`}>
            <div className="logo">
              <img src={Logo} alt="" className="adress_logo" />
            </div>
            <h4>
              {getGreeting()} <br /> {t("Please select your language to begin")}
            </h4>
            <ul className="languages_list">
              {langArray.map((item, idx) => {
                return (
                  <li
                    onClick={() => changeLang(item.code)}
                    key={idx}
                    className={item.code == lang ? "lang_active" : ""}
                  >
                    {item.name}
                  </li>
                );
              })}
            </ul>
          </div>
          <BannerHome
            className={"dot-bar less-margin small-dots"}
            headerImages={headerImages}
            isFirst={true}
          />
          <div className="quick_services">
            {showServices ? (
              <div className="services_box">
                <Link>{t("Do not Disturb")}</Link>
                <Link>{t("Make my Room")}</Link>
                <Link>{t("Pickup my Tray")}</Link>
                <Link>{t("Feedback / Complaint")}</Link>
              </div>
            ) : (
              <button onClick={(e) => setShowServices(true)}>
                {t("Quick Services")}
              </button>
            )}
          </div>
          <div className="our_offering">
            <h3>{t("Explore Our Offerings")}</h3>
            <Slider {...settings}>
              <div
                className="image_carousel"
                onClick={() =>
                  navigate(`/${params.room_uuid}/food-and-beverages`, {
                    state: { metadata: services },
                  })
                }
              >
                <img src={Food} alt="" />
                <img src={Rectangle} alt="" className="img_overlay" />
                <h6 className="img_title">{t("Food & Beverages")}</h6>
              </div>
              <Link
                to={`/${params.room_uuid}/hotel-services`}
                state={{ metadata: services }}
                className="image_carousel"
              >
                <img src={Sevices} alt="" />
                <img src={Rectangle} alt="" className="img_overlay" />
                <h6 className="img_title">{t("Hotel Services")}</h6>
              </Link>
              <Link
                to={`/${params.room_uuid}/spa-wellness`}
                state={{ metadata: services }}
                className="image_carousel"
              >
                <img src={Bed} alt="" />
                <img src={Rectangle} alt="" className="img_overlay" />
                <h6 className="img_title">{t("Spa & Wellness")}</h6>
              </Link>
              <Link
                to={`/${params.room_uuid}/external-services`}
                state={{ metadata: services }}
                className="image_carousel"
              >
                <img src={Taxi} alt="" />
                <img src={Rectangle} alt="" className="img_overlay" />
                <h6 className="img_title">{t("External Services")}</h6>
              </Link>
            </Slider>
          </div>
          <div className="language_box">
            <div className="btn_lang" onClick={() => setOpenLang(true)}>
              {lang}
            </div>
          </div>
          <NewFooter />
        </>
      )}
    </div>
  );
};

export default NewHome;
