import React, { useRef, useState } from "react";
import { Button, Modal, Dropdown, Form } from "react-bootstrap";
import HeaderInner from "../../Components/HeaderInner";
import CarBookModal from "../../Components/CarBookModal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { useEffect } from "react";
import { ShowOther } from "../../API/Concierge";
import ReactGA from "react-ga4";

import axios from "../../axios";
import { Toast } from "primereact/toast";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { useTranslation } from "react-i18next";
import TaxInclusive from "../../Components/TaxInclusive.";
import Title from "../../components/title/Title";
import NewFooter from "../../NewFooter";
import BannerServices from "../../components/banner-home/BannerServices";
function Other() {
  const navigate = useNavigate();
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const [count, setCount] = useState(1);
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState("");
  const [note, setNote] = useState();
  const [dateInput, setDateInput] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const { t, i18n } = useTranslation();
  const toast = useRef(null);
  const lang = localStorage.getItem("lang");
  // const theme = location.search;
  const new_design = localStorage.getItem("new_design");
  const theme = new_design == "true";

  let other;
  let bgColor;
  let color;
  let text;
  const { data, isLoading, error } = ShowOther(room_uuid, lang);
  if (!isLoading) {
    let newData = data.data.data;
    other = newData;
    color = newData?.color;
    bgColor = newData?.header_color;
    text = newData?.center_header;

    // console.log("ShowOther", newData);
  }

  const [errorEmail, setErrorEmail] = useState("");

  const checkValEmail = email.length > 0 && !/\S+@\S+\.\S+/.test(email);
  // console.log("checkemail", !/\S+@\S+\.\S+/.test(spaService.email));
  const checkInputs = () => {
    if (email.length > 0 && !/\S+@\S+\.\S+/.test(email)) {
      setErrorEmail("The email must be a valid email address.");
    } else {
      setErrorEmail("");
    }
  };

  const sendData = {
    type_id: "other",
    email,
    phone,
    note,
  };
  const handelBook = () => {
    checkInputs();
    if (!checkValEmail) {
      setIsDisabled(true);
      ReactGA.event({
        category: "Book service concierge ",
        action: "Click",
        value: `concierge type: other`,
      });
      axios
        .post(`/${room_uuid}/concierge/book-service`, sendData, {
          headers: {
            lang: lang,
          },
        })
        .then((res) => {
          if (res.data.status == false) {
            toast.current.show({
              severity: "error",
              summary: t("Error"),
              detail: t(`${res.data.message}`),
              life: 4000,
            });
          }
          // console.log("res", res);
          if (res.data.status) {
            navigate(`/${room_uuid}/HKOrderPlaced`, {
              state: {
                metadata: {
                  res: res.data.data,
                },
              },
            });
          }

          setIsDisabled(false);
        })
        .catch((err) => {
          setIsDisabled(false);
          console.log(err);
        });
    }
  };

  return (
    <div className={theme ? "food" : ""}>
      {theme ? <Title title={text} /> : <HeaderInner headerName={text} />}
      <main className="home-main bg-white back-white book-table pad-top-0">
        <Toast ref={toast} position="bottom-center" />

        {isLoading ? (
          <SpaLoader />
        ) : (
          <>
            {theme ? (
              <BannerServices headerImages={[other.image]} />
            ) : (
              <div className="con-order-img">
                <img src={other.image} alt="" />
              </div>
            )}

            <p className="bk-title mt-3">{t(`${other.title}`)}</p>
            <div className="px-5">
              <div className="con-email">
                <Form.Group
                  className="mb-3  align-items-center dark-placeholder justify-content-start "
                  controlId="formBasicname"
                >
                  <Form.Control
                    type="email"
                    placeholder={t("Your Email (Optional)")}
                    className="w-100 text-center py-3"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                {errorEmail != "" && (
                  <span className="err_msg">{errorEmail}</span>
                )}
              </div>
              <div className="con-phone">
                <Form.Group className="mb-3 w-100 ">
                  <PhoneInput
                    country={"ae"}
                    containerclassName="ml-4"
                    placeholder="50 123 4567"
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                  />
                </Form.Group>
              </div>
              <Form.Group className="mb-3 dark-placeholder i-f-small w-100">
                <Form.Label>{t("Add note")} </Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder={t("Your Notes")}
                  name="note"
                  onChange={(e) => setNote(e.target.value)}
                  style={{ height: "106px" }}
                />
              </Form.Group>
            </div>
            <div className="padd-div text-center mt-5">
              <Button
                className="reg-btn book lg"
                disabled={isDisabled}
                onClick={handelBook}
                // to={`/${room_uuid}/HKOrderPlaced`}
              >
                {t("Book")}
              </Button>
            </div>
            <div className="padd-div text-center">
              {/* <h3 className="text-center">
                <TaxInclusive />
              </h3>{" "} */}
            </div>
            <div className="spacer-40 my-5"></div>
          </>
        )}
      </main>
      {theme && <NewFooter />}
    </div>
  );
}
export default Other;
