import React from "react";
import { ShowPharmacies } from "../API/Pharmacy";
import PharmacyLoader from "./Loaders/PharmacyLoader";
import { Link, useNavigate } from "react-router-dom";
import { Zoom } from "react-reveal";

const PharmacyComponent = ({ room_uuid, ph_uuid, ph_id }) => {
  // console.log("phuuid", ph_uuid);
  const lang = localStorage.getItem("lang");
  const navigate = useNavigate();
  let pharmacies;
  let bgColor;
  let color;
  let text;
  let terms_and_conditions;
  const new_design = localStorage.getItem("new_design");
  const theme = new_design == "true";
  const { data, isLoading, error } = ShowPharmacies(room_uuid, ph_uuid, lang);

  if (!isLoading) {
    if (data.data.status == false) {
      navigate(`/${room_uuid}/qr-scan`);
    } else {
      pharmacies = data.data.data.categories;
      color = data.data.data.color;
      bgColor = data.data.data.header_color;
      text = data.data.data.center_header;
      terms_and_conditions = data.data.data?.pharmacy?.terms_and_conditions;
      console.log("pharmacies", pharmacies);
    }
  }

  return (
    <>
      {terms_and_conditions != "" &&
        (terms_and_conditions != undefined ? (
          <>
            {/* <p className="text-center">Terms & Conditions</p> */}
            <div
              className="mx-4 divhtml"
              dangerouslySetInnerHTML={{
                __html: terms_and_conditions,
              }}
            />
          </>
        ) : null)}
      <ul className="display-list suber_market_cat three-items phamacy  v2 v3">
        {isLoading ? (
          <PharmacyLoader />
        ) : pharmacies ? (
          pharmacies.map((pharmacy, i) => {
            return (
              <>
                <li key={pharmacy.id}>
                  <Zoom>
                    <Link
                      to={
                        pharmacy?.url.includes("?cat=") &&
                        pharmacy?.url.includes("pharmacy/categories")
                          ? `/${room_uuid}/PharmacySubCategory/${ph_uuid}/${pharmacy.id}`
                          : // `/${room_uuid}/PharmacySubMenu/${ph_id}/${ph_uuid}/${pharmacy.id}/`
                            `/${room_uuid}/PharmacySubMenu/${ph_id}/${ph_uuid}/${pharmacy.id}/`
                      }
                      state={{ theme: theme }}
                    >
                      <div
                        className="img-pnl"
                        style={{
                          backgroundImage: `url(${pharmacy.image})`,
                        }}
                      ></div>
                      <img src={pharmacy.image} alt="" />
                      <div></div>
                      {/* <div className="bg-layer"></div> */}
                      <p className="text-black">
                        {!pharmacy?.name?.includes(" ") &&
                        pharmacy?.name?.length > 12
                          ? `${pharmacy?.name?.slice(0, 12)}...`
                          : pharmacy?.name}
                      </p>{" "}
                    </Link>
                  </Zoom>
                </li>
              </>
            );
          })
        ) : null}
      </ul>
    </>
  );
};

export default PharmacyComponent;
