import React from "react";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import AddCartPost from "./AddCartPost";
function ViewCartModal() {
  return (
    <>
      <div className="add-post-container full-post">
        <AddCartPost />
      </div>
      <div className="full-heading flex grey-bg m-0">
        <h3>Juice</h3>
      </div>
      <div className="check-list-container">
        <Form>
          {["radio"].map((type) => (
            <ul key={`juice-${type}`} className="check-list">
              <li>
                <p>Orange</p>
                <Form.Check
                  checked
                  label=""
                  name="group1"
                  type={type}
                  id={`juice-${type}-1`}
                />
              </li>
              <li>
                <p>Peanapple</p>
                <div className="d-flex">
                  <p>(+ AED 8)</p>
                  <Form.Check
                    label=""
                    name="group1"
                    type={type}
                    id={`juice-${type}-2`}
                  />
                </div>
              </li>
              <li>
                <p>Apple</p>
                <div className="d-flex">
                  <p>(+ AED 8)</p>
                  <Form.Check
                    label=""
                    name="group1"
                    type={type}
                    id={`juice-${type}-3`}
                  />
                </div>
              </li>
            </ul>
          ))}
        </Form>
      </div>
      <div className="full-heading flex grey-bg m-0">
        <h3>Your choice of cereal</h3>
      </div>
      <div className="check-list-container">
        <Form>
          {["radio"].map((type) => (
            <ul key={`cereal-${type}`} className="check-list">
              <li>
                <p>Cornflakes</p>
                <Form.Check
                  reverse
                  label=""
                  name="group2"
                  type={type}
                  id={`cereal-${type}-1`}
                />
              </li>
              <li>
                <p>Frostiest</p>
                <Form.Check
                  reverse
                  label=""
                  name="group2"
                  type={type}
                  id={`cereal-${type}-2`}
                />
              </li>
            </ul>
          ))}
        </Form>
      </div>
      <div className="full-heading flex grey-bg m-0">
        <h3>Yogurt</h3>
      </div>
      <div className="check-list-container">
        <Form>
          {["radio"].map((type) => (
            <ul key={`cereal-${type}`} className="check-list">
              <li>
                <p>Plain</p>
                <Form.Check
                  reverse
                  label=""
                  name="group3"
                  type={type}
                  id={`yogurt-${type}-1`}
                />
              </li>
              <li>
                <p>fruit</p>
                <div className="d-flex">
                  <p>(+ AED 6)</p>
                  <Form.Check
                    reverse
                    label=""
                    name="group3"
                    type={type}
                    id={`yogurt-${type}-2`}
                  />
                </div>
              </li>
            </ul>
          ))}
        </Form>
      </div>

      <div className="full-heading flex grey-bg m-0">
        <h3>Coffee</h3>
      </div>
      <div className="check-list-container">
        <Form>
          {["checkbox"].map((type) => (
            <ul key={`coffee-${type}`} className="check-list">
              <li>
                <p>freshly brewed</p>
                <Form.Check
                  reverse
                  label=""
                  name="group4"
                  type={type}
                  id={`coffee-${type}-1`}
                />
              </li>
              <li>
                <p>regular</p>
                <Form.Check
                  reverse
                  label=""
                  name="group4"
                  type={type}
                  id={`coffee-${type}-2`}
                />
              </li>
              <li>
                <p>decaffeinated</p>
                <div className="d-flex">
                  <p>(+ AED 6)</p>
                  <Form.Check
                    reverse
                    label=""
                    name="group4"
                    type={type}
                    id={`coffee-${type}-2`}
                  />
                </div>
              </li>
            </ul>
          ))}
        </Form>
        <div className="full-div">
          <Link
            className="reg-btn big w-100 flex-div"
            to="/RestaurantOrderPayment"
          >
            <div>
              <i className="fa fa-plus-square"></i> Add to Cart
            </div>
            <div>AED 110.00</div>
          </Link>
        </div>
      </div>
    </>
  );
}
export default ViewCartModal;
