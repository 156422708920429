import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import SpaCarosle from "../../Components/SpaCarosle";
import Post1 from "../../assets/images/Spa/Post-1.png";
import Post2 from "../../assets/images/Spa/Post-2.png";
import Post3 from "../../assets/images/Spa/Post-3.png";
import Post4 from "../../assets/images/Spa/Post-4.png";
import Post5 from "../../assets/images/Spa/Post-5.png";
import Post6 from "../../assets/images/Spa/Post-6.png";
import Post7 from "../../assets/images/Spa/Post-7.png";
import Post8 from "../../assets/images/Spa/Post-8.png";
import Post9 from "../../assets/images/Spa/Post-9.png";
import Image from "react-bootstrap/Image";
import { Button, Modal } from "react-bootstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";
import axios from "../../axios";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import Slide from "react-reveal/Slide";
import { useTranslation } from "react-i18next";
import { Zoom } from "react-reveal";

function SalonAtHome() {
  const [enableClass, setEnableClass] = useState(true);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [modalImage, setModalImage] = useState(Post1);
  const [showCart, setShowCart] = useState(false);
  const [show, setShow] = useState(false);
  const [headerImages, setHeaderImages] = useState();
  const [salon_uuid, setSalon_uuid] = useState();
  const [spaServices, setSpaServices] = useState([]);
  const [salons, setSalons] = useState([]);
  const [title, setTitle] = useState();
  const [salonId, setSalonId] = useState();
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  // setTimeout(() => {
  //   setEnableClass(false);
  // }, 3000);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCartClose = () => setShowCart(false);
  const handleCartShow = () => setShowCart(true);
  const handleIncrease = () => {
    setCount((prev) => {
      if (prev <= 0) {
        return 0;
      }
      return prev - 1;
    });
  };
  const handleDecrease = () => {
    setCount((prev) => prev + 1);
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      axios
        .get(`/${room_uuid}/salon_at_home`)
        .then((res) => {
          if (res.data.status === false) {
            navigate(`/${room_uuid}/qr-scan`);
          } else {
            const data = res.data.data;
            // setSalon_uuid(data.uuid);
            // setHeaderImages(data.names[0].banners[0].image);
            // setSpaServices(data.services);
            // setSalonId(data.id);data)
            // console.log("MYdata", salons[0].uuid);
            setSalons(data.salons);
            setTitle(data.center_header);
            setLoading(false);
            if (data?.salons.length == 1) {
              navigate(`/${room_uuid}/salon_at_home/${data.salons[0].uuid}`, {
                replace: true,
              });
            }
          }
        })
        .catch((err) => {
          if (err?.response.status == 404) {
            navigate(`/not-found`);
          }
        });
    };
    getData();
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  return (
    <>
      <HeaderInner headerName={title} />
      <main className="home-main bg-white back-white pad-top-0">
        {loading ? (
          <SpaLoader />
        ) : (
          salons?.map((salon, idx) => {
            return (
              <div key={idx}>
                <Zoom>
                  <div
                    className="con-img-container v3"
                    onClick={() =>
                      navigate(`/${room_uuid}/salon_at_home/${salon.uuid}`)
                    }
                  >
                    <div className="con-img">
                      <img src={salon.image} alt="" />
                      <p></p>
                    </div>
                  </div>
                  <div className="con-text-container">
                    <p>{salon.name}</p>
                    <hr className="mb-0 dark" />
                  </div>
                </Zoom>
              </div>
            );
          })
        )}
      </main>
    </>
  );
}
export default SalonAtHome;
