import React from "react";
import { Carousel } from "react-responsive-carousel";
import Slide3 from "../assets/images/slide-3.png";
import Slide4 from "../assets/images/slide-4.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
function BookingCarosle({ className, noAuto, single, images, isVideo }) {
  const Slide1 =
    "https://tdhbucket.s3.me-central-1.amazonaws.com/uploaded_files/images/restaurants/banners/xMgyjUULwBH1OeX0PbYmF6VqrOANC4IHOFSzWNDR.jpg";
  const Slide2 =
    "https://tdhbucket.s3.me-central-1.amazonaws.com/uploaded_files/images/restaurants/banners/iC8QPpTljNdViUkGNfcHCwlJfXOK3Cx7MHN82qB2.jpg";

  const Slide3 =
    "https://tdhbucket.s3.me-central-1.amazonaws.com/uploaded_files/images/restaurants/banners/Yi2Dsh2Our5c5OvQtXEWLxdTl8gDBHe7ZCF1KHzl.jpg";

  // console.log("images", images[0]);
  console.log("djdjdjdjjd", images);
  if (isVideo) {
    const first = images[0];
    const second = images[1];
    const newimages = images.slice(2);
    images = [second, first, ...newimages];
  }
  const arr = single ? [Slide2] : [Slide1, Slide2, Slide3];
  images = images ? images : arr;
  return (
    <div className={"dot-bar booking-carosle"}>
      <Carousel
        className="mb-5 noSelect"
        swipeable={true}
        autoPlay={noAuto ? false : true}
        infiniteLoop={true}
        showIndicators={images.length > 1}
        showArrows={false}
        showThumbs={false}
        emulateTouch={true}
        showStatus={false}
      >
        {images.map((slide) => {
          if (
            ["mp4", "avi", "mkv", "mov", "webm"].includes(
              slide.image.split(".").pop().toLowerCase()
            )
          ) {
            return (
              <div className="carousel-item-custom carousel-restaurant  noSelect">
                <video
                  width="100%"
                  height="165"
                  autoPlay
                  muted
                  playsinline=""
                  loop
                >
                  <source src={slide.image} type="video/mp4" />
                </video>
              </div>
            );
          }
          return (
            <div className="carousel-item-custom noSelect">
              <img src={slide.image} alt="Slide" />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}
export default BookingCarosle;
