import React, { useEffect, useRef, useState } from "react";
import { Button, Modal, Dropdown } from "react-bootstrap";
import HeaderInner from "../../Components/HeaderInner";
import Car1 from "../../assets/images/Car-Rental/Car-1.png";
import Car2 from "../../assets/images/Car-Rental/Car-2.png";
import Car3 from "../../assets/images/Car-Rental/Car-3.png";
import Car4 from "../../assets/images/Car-Rental/Car-4.png";
import Car5 from "../../assets/images/Car-Rental/Car-5.png";
import Car6 from "../../assets/images/Car-Rental/Car-6.png";
import Car7 from "../../assets/images/Car-Rental/Car-7.png";
import Car8 from "../../assets/images/Car-Rental/Car-8.png";
import Car9 from "../../assets/images/Car-Rental/Car-9.png";
import Car10 from "../../assets/images/Car-Rental/Car-10.png";
import CarBookModal from "../../Components/CarBookModal";
import axios from "../../axios";
import CarRentalPost from "../../Components/CarRentalPost";
import { useLocation, useNavigate } from "react-router-dom";
import CarRentalLoader from "../../Components/Loaders/CarRentalLoader";
import Bounce from "react-reveal/Bounce";
import { useTranslation } from "react-i18next";
import Search from "../../Components/Search";
import { useInfiniteQuery } from "react-query";
import { CarsAPI } from "../../API/CarsAPI";
import ReactGA from "react-ga4";

function Entertainment() {
  const [show, setShow] = useState(false);
  const [cars, setCars] = useState();
  const lang = localStorage.getItem("lang");
  const { t, i18n } = useTranslation();

  // const [carSettings, setCarSettings] = useState({
  //   carModels: [],
  //   carTypes: [],
  //   years_list: [],
  // });
  const [selectedModel, setSelectedModel] = useState();
  const [selectedType, setSelectedType] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const [loading, setLoading] = useState(false);
  const [make, setMake] = useState("");
  const [makeName, setMakeName] = useState("");
  const [model, setModel] = useState("");
  const [year, setYear] = useState("");

  // useEffect(() => {
  //   const getData = async () => {
  //     setLoading(true);
  //     axios
  //       .get(`/${room_uuid}/cars?make=&model=&year=&page=1`, {
  //         headers: {
  //           lang: lang,
  //         },
  //       })
  //       .then((res) => {
  //         const data = res.data.data;
  //         const updatedCarSettings = { ...carSettings };
  //         updatedCarSettings.carModels = data.carModels;
  //         updatedCarSettings.carTypes = data.carTypes;
  //         updatedCarSettings.years_list = data.years_list;
  //         setCarSettings(updatedCarSettings);
  //         setCars(data.cars.data);
  //         console.log(data);
  //         setLoading(false);
  //       })
  //       .catch((err) => {
  //         if (err?.response.status == 404) {
  //           navigate(`/not-found`);
  //         }
  //       });
  //   };
  //   getData();
  // }, []);

  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //   });
  // }, []);
  // useEffect(() => {
  //   const getSpecificData = async () => {
  //     setLoading(true);
  //     axios
  //       .post(`/${room_uuid}/cars`, {
  //         year: selectedYear,
  //         // model: selectedModel,
  //         make: selectedType.id,
  //       })
  //       .then((res) => {
  //         const data = res.data.data;
  //         const cars = data.cars.data;
  //         if (Array.isArray(cars)) {
  //           console.log("og", cars);
  //           setCars(cars);
  //           setLoading(false);
  //         } else if (typeof cars === "object") {
  //           // If the response is an object, convert it into an array
  //           let response = Object.values(cars);
  //           setCars(response);
  //           setLoading(false);

  //           // Now, 'response' is an array containing the values from the object
  //           // ...
  //         }
  //         // console.log(data.cars.data);
  //       });
  //   };
  //   getSpecificData();
  // }, [selectedType, selectedYear]);

  let carSettings = {
    carModels: [],
    carTypes: [],
    years_list: [],
  };
  const {
    data,
    isLoading,
    isError,
    error,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery(
    `cars-${lang}-${make?.id ? make?.id : ""}-${
      model?.id ? model?.id : ""
    }-${year}`,
    ({ pageParam = 1 }) =>
      CarsAPI({
        room_uuid,
        make: make?.id ? make?.id : "",
        model: model?.id ? model?.id : "",
        year,
        pageParam,
        lang,
      }),
    {
      getNextPageParam: (data) => {
        const lastPage = data?.data?.data?.cars?.last_page;
        const currentPage = data?.data?.data?.cars?.current_page;
        return currentPage < lastPage ? currentPage + 1 : undefined;
      },
    }
  );

  let title;
  if (!isLoading) {
    if (data.pages[0].data.status == false) {
      navigate(`/${room_uuid}/qr-scan`);
    } else {
      console.log("datadnjddjd", data);
      title = data?.pages[0]?.data.data.title;
      carSettings.carModels = data.pages[0]?.data.data.carModels;
      carSettings.carTypes = data.pages[0]?.data.data.carTypes;
      carSettings.years_list = data.pages[0]?.data.data.years_list;
    }
  }
  let loadMoreRef = useRef();

  useEffect(() => {
    if (!hasNextPage) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => entry.isIntersecting && fetchNextPage()),
      {
        root: null,
        margin: "0px",
        treshold: 1.0,
      }
    );

    const el = loadMoreRef && loadMoreRef.current;

    if (!el) {
      return;
    }

    observer.observe(el);
  }, [loadMoreRef.current, hasNextPage]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  return (
    <>
      <HeaderInner headerName="Car Rental" />
      <main className="home-main bg-white back-fe pad-top-0 car_rental">
        {isLoading ? (
          <CarRentalLoader />
        ) : (
          <>
            <Search
              placeholder="Search for A Car"
              room_uuid={room_uuid}
              category="Car Rental"
            />

            <div className="padd-div car-drop-flex flex-div">
              <Dropdown className="car-drop">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  disabled={carSettings.carTypes.length == 0}
                >
                  {make.name ? make.name : `${t("Make")}`}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setMake("")}>
                    {t("Make")}
                  </Dropdown.Item>
                  {carSettings.carTypes
                    ? carSettings.carTypes.map((type) => {
                        return (
                          <Dropdown.Item
                            onClick={() => {
                              ReactGA.event({
                                category: "Select Make in car page",
                                action: "Click",
                                value: `make: ${type.name}`,
                              });
                              setMake(type);
                            }}
                          >
                            {type.name}
                          </Dropdown.Item>
                        );
                      })
                    : null}
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="car-drop">
                <Dropdown.Toggle
                  variant="success"
                  disabled={carSettings.carModels.length == 0}
                  id="dropdown-basic"
                >
                  {model.name ? model.name : `${t("Model")}`}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setModel("")}>
                    {t("Model")}
                  </Dropdown.Item>
                  {carSettings.carModels
                    ? carSettings.carModels.map((model) => {
                        return (
                          <Dropdown.Item
                            onClick={() => {
                              setModel(model);
                              ReactGA.event({
                                category: "Select Model in car page",
                                action: "Click",
                                value: `make: ${model.name}`,
                              });
                            }}
                          >
                            {model.name}
                          </Dropdown.Item>
                        );
                      })
                    : null}
                </Dropdown.Menu>
              </Dropdown>
              {/* <Dropdown className="car-drop">
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  disabled={carSettings.years_list.length == 0}
                >
                  {year ? year : `${t("Year")}`}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setYear("")}>
                    {t("Year")}
                  </Dropdown.Item>
                  {carSettings.years_list
                    ? carSettings.years_list.map((year) => {
                        return (
                          <Dropdown.Item
                            onClick={() => {
                              setYear(year);
                              ReactGA.event({
                                category: "Select Year in car page",
                                action: "Click",
                                value: `make: ${year}`,
                              });
                            }}
                          >
                            {year}
                          </Dropdown.Item>
                        );
                      })
                    : null}
                </Dropdown.Menu>
              </Dropdown> */}
            </div>
            <div className="spacer-40 "></div>

            <ul className="display-list car-list two-items v2">
              {data?.pages?.map((page) =>
                page?.data?.data?.cars?.data?.map((item) => {
                  // console.log("pageee", page);
                  return <CarRentalPost car={item} room_uuid={room_uuid} />;
                })
              )}

              {/* {cars && cars.length > 0
                ? cars?.map((car) => {
                    return <CarRentalPost car={car} room_uuid={room_uuid} />;
                  })
                : null} */}
            </ul>
            <div
              className="ref_loading"
              ref={loadMoreRef}
              onClick={() => fetchNextPage()}
              disabled={!hasNextPage || isFetchingNextPage}
            ></div>
            <div>
              {isFetchingNextPage ? (
                <div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden"></span>
                  </div>
                </div>
              ) : hasNextPage ? (
                <div class="d-flex justify-content-center">
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden"></span>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </>
        )}
      </main>
    </>
  );
}
export default Entertainment;
