import axios from "axios";
import { API_URL } from "../constants/api";
import { useQuery } from "react-query";

const APIURL = axios.create({
  baseURL: API_URL,
});

const Laundry = async (uuid, l_id, lang) => {
  return await APIURL.get(`/${uuid}/laundry/${l_id}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowLaundry = (uuid, l_id, lang) => {
  return useQuery(`laundry-${lang}`, () => Laundry(uuid, l_id, lang));
};
