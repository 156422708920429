import React, { useEffect } from "react";
import HeaderInner from "../Components/HeaderInner";
import ErrorPage from "../Components/ErrorPage";
import { redirect } from "../constants/api";
const NotFoundPage = () => {
  useEffect(() => {
    window.location.replace(redirect);
  }, []);
  return (
    <>
      {/* <HeaderInner /> */}
      <main className="home-main bg-white back-white pad-top-0">
        {/* <ErrorPage des="Page Not Found" /> */}
      </main>
    </>
  );
};

export default NotFoundPage;
