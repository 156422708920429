import React from 'react';
import NavBar from '../src/Components/NavBar';
function Whatsappchat() {
    return (
        <>
            <NavBar />
            <main className='home-main'>
                <div className='chat-container'>
                    <p>Hi! I'm HASHTAG HOLIDAY HOME Helper</p>
                </div>
            </main>
        </>
    );
}
export default Whatsappchat; 