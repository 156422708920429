import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { Zoom } from "react-reveal";
import { ShowEntertainments } from "../../API/EntertainmentAPI";
import { ShowMaintenances } from "../../API/MaintenanceAPI";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import ModalMaintenance from "./ModalMaintenance";
import Search from "../../Components/Search";

function Maintenances() {
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const euuid = rawPath.split("categories/")[1];
  const navigate = useNavigate();
  const params = useParams();
  const lang = localStorage.getItem("lang");

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState();
  const [category, setCategory] = useState();
  const [textMod, setTextMod] = useState();
  // const handleClose = () => setShow(false);
  const handleShow = (id, text) => {
    // console.log("id", id, "color:green");
    setCategory(id);
    setTextMod(text);
    setShow(true);
  };
  const { t, i18n } = useTranslation();
  // console.log("category", category);
  let flowers;
  let bgColor;
  let color;
  let text;
  let entertainmentCategories;
  const { data, isLoading, error } = ShowMaintenances(
    room_uuid,
    params.uuid,
    lang
  );
  if (!isLoading) {
    if (error) {
      if (error?.response.status == 404) {
        navigate(`/not-found`);
      }
    } else {
      if (data.data.status === false) {
        navigate(`/${room_uuid}/qr-scan`);
      } else {
        flowers = data.data.data;
        entertainmentCategories = flowers.maintenanceCategories;
        color = flowers.color;
        bgColor = flowers.header_color;
        text = flowers.title;

        // console.log("ShowEntertainmentss", flowers);
      }
    }
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  // useEffect(() => {
  //   const getData = async () => {
  //     setLoading(true)
  //     axios
  //       .get(
  //         `/${room_uuid}/entertainment/categories/1bb893d8-e5b0-42ba-a684-7f563c2e8ca5`
  //       )
  //       .then((res) => {
  //         const data = res.data.data;
  //         setEntertainmentCategories(data.entertainment_categories);
  //         setTitle(data?.title)
  //         console.log("data,entertainment/companies", data);
  //         setLoading(false)
  //       });
  //   };
  //   getData();
  // }, []);

  return (
    <>
      <HeaderInner headerName={text} />
      <main
        className="home-main bg-white maintenances"
        style={{ paddingTop: "80px" }}
      >
        <Search
          placeholder="Search for Categories..."
          room_uuid={room_uuid}
          category="Pharmacy"
        />

        <div className="display-list three-items v2 v3  ">
          {isLoading ? (
            <SpaLoader />
          ) : entertainmentCategories ? (
            entertainmentCategories.map((x, idx) => {
              return (
                <li
                  id={x.name}
                  key={idx}
                  onClick={(id) => handleShow(x.id, x.name)}
                >
                  <Zoom>
                    <a onClick={(id) => handleShow(x.id, x.name)}>
                      <div
                        className="img-pnl"
                        // style={{ backgroundImage: `url(${x.image})` }}
                      ></div>
                      <img src={x.image} alt="" />
                      <div></div>
                      <div className="bg-layer"></div>
                    </a>
                    <p>{x.name}</p>
                  </Zoom>
                </li>
                // <li id={x.name} key={idx}>
                //   <Zoom>
                //     <div
                //       className="con-img-container v3"
                //       onClick={(id) => handleShow(x.id, x.image)}
                //     >
                //       <div className="con-img">
                //         <img src={x.image} alt="" />
                //         <p></p>
                //       </div>
                //     </div>
                //     <div className="con-text-container">
                //       <p>{x.name}</p>
                //       <hr className="mb-0 dark" />
                //     </div>
                //   </Zoom>
                // </li>
              );
            })
          ) : null}
        </div>
      </main>
      <ModalMaintenance
        show={show}
        setShow={setShow}
        category={category}
        room_uuid={room_uuid}
        text={textMod}
      />
    </>
  );
}
export default Maintenances;
