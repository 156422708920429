import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import Entertainment1 from "../../assets/images/Entertainment/Entertainment-1.png";
import Entertainment2 from "../../assets/images/Entertainment/Entertainment-2.png";
import Entertainment3 from "../../assets/images/Entertainment/Entertainment-3.png";
import Entertainment4 from "../../assets/images/Entertainment/Entertainment-4.png";
import Overllay from "../../assets/new_imgs/Rectangle 1072.png";

import axios from "../../axios";
import { useEffect } from "react";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { Zoom } from "react-reveal";
import { ShowEntertainment } from "../../API/EntertainmentAPI";
import Title from "../../components/title/Title";

function EntertainmentService() {
  const [enableClass, setEnableClass] = useState(true);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState(false);
  // const [entertainmentCategories, setEntertainmentCategories] = useState();
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const navigate = useNavigate();

  // console.log("rawPath", rawPath);
  const lang = localStorage.getItem("lang");

  // setTimeout(() => {
  //   setEnableClass(false);
  // }, 3000);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  let flowers;
  let bgColor;
  let color;
  let text;
  let entertainmentCategories;
  const { data, isLoading, error } = ShowEntertainment(room_uuid, lang);
  if (!isLoading) {
    if (error) {
      if (error?.response.status == 404) {
        navigate(`/not-found`);
      }
    } else {
      if (data.data.status === false) {
        navigate(`/${room_uuid}/qr-scan`);
      } else {
        flowers = data.data.data;
        entertainmentCategories = flowers?.entertainment_companies;
        color = flowers?.color;
        bgColor = flowers?.header_color;
        text = flowers?.title;
        if (entertainmentCategories?.length == 1) {
          navigate(
            `/${room_uuid}/entertainment/categories/${entertainmentCategories[0].uuid}`,
            { replace: true }
          );
        }
      }
      // console.log("ShowEntertainment", flowers);
    }
  }

  return (
    <div className="food">
      <Title title={title} />
      <div className="box_food">
        {isLoading ? (
          <SpaLoader />
        ) : //   `/${room_uuid}/entertainment/categories/${x.uuid}`
        entertainmentCategories.length > 1 ? (
          entertainmentCategories.map((x, idx) => {
            return (
              <div key={idx}>
                <Link to={`/${room_uuid}/entertainment/categories/${x.uuid}`}>
                  <img src={x?.image} alt="" className="booking_img" />
                  <img src={Overllay} alt="" className="overlay" />
                  <div className="text_food">
                    <h5 className="">{x.name}</h5>
                  </div>{" "}
                </Link>
              </div>
            );
          })
        ) : null}
      </div>
    </div>
  );
}
export default EntertainmentService;
