import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import HeaderInner from "../../Components/HeaderInner";
import { ShowRestaurantCategories } from "../../API/In-Room-DiningAPI";
import Carosle from "../../Components/Carosle";
import { useTranslation } from "react-i18next";
import axios from "../../axios";
import { Toast } from "primereact/toast";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import ReactGA from "react-ga4";
import "../../pages/hotel-services/HotelServices.scss";
import Title from "../../components/title/Title";
import NewFooter from "../../NewFooter";
import BannerServices from "../../components/banner-home/BannerServices";
import BannerHome from "../../components/banner-home/BannerHome";

const BookTable = () => {
  const toast = useRef(null);
  const [count, setCount] = useState(1);
  const [timeInput, setTimeInput] = useState();
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const rawPath = location.pathname;
  let room_uuid = rawPath.split("/")[1];
  const timeref = useRef(null);
  // const theme = location.search;
  const new_design = localStorage.getItem("new_design");
  const theme = new_design == "true";

  // const [selectedDate, setSelectedDate] = useState(minDate);
  const [dateInput, setDateInput] = useState(new Date());

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);
  const lang = localStorage.getItem("lang");

  const handleDecrease = () => {
    setCount((prev) => {
      if (prev <= 0) {
        return 0;
      }
      return parseInt(prev) - 1;
    });
  };
  const handleIncrease = () => {
    setCount((prev) => parseInt(prev) + 1);
  };
  const days = [
    { Mon: "Monday" },
    { Tue: "Tuesday" },
    { Wed: "Wednesday" },
    { Thu: "Thursday" },
    { Fri: "Friday" },
    { Sat: "Saturday" },
    { Sun: "Sunday" },
  ];
  let start_time = "00:00:00";
  let end_time = "23:00:00";
  let isOff = 0;
  let is_all_day = 1;
  let nameDay;
  let day_name;

  let restaurant;
  let restaurantCategories;
  let images = [];
  let isVideo = false;
  let bgColor;
  let color;
  let text;
  let working_times;
  let isbooking = "";
  const { data, isLoading, error } = ShowRestaurantCategories(
    room_uuid,
    params.restaurant_uuid,
    lang,
    isbooking
  );

  if (!isLoading) {
    if (!error) {
      restaurant = data.data.data;
      // setHeaderName=restaurant.center_header;
      // let restaurant_images = [...restaurant.restaurant?.images]
      isVideo = false;
      images = restaurant?.banners;
      working_times = restaurant.restaurant?.working_times;
      bgColor = data.data.data.header_color;
      text = data.data.data.center_header;

      if (working_times.length > 0) {
        const dayOfWeek = new Date(dateInput).toLocaleString("en-us", {
          weekday: "short",
        });
        console.log("dayOfWeek", dayOfWeek);
        nameDay = days?.find((dayObj) => dayOfWeek in dayObj)[dayOfWeek];
        console.log("nameDay", nameDay);
        const dayCur = working_times?.filter((time) => {
          if (time?.working_day?.names[0]?.name == nameDay) {
            day_name = time?.working_day?.name;
            return time;
          }
        });

        isOff = dayCur[0]?.is_off;
        is_all_day = dayCur[0]?.is_all_day;
        console.log("start_timestart_time", dayCur[0]);
        if (isOff != 1) {
          start_time = dayCur[0]?.start_time;
          let hours_count =
            dayCur[0]?.hours_count == 24 ? 23 : dayCur[0]?.hours_count;

          const [hours_, minutes_, seconds_] = start_time
            ?.split(":")
            .map(Number);

          let totalHours = hours_ + hours_count;

          if (totalHours >= 24) {
            totalHours = totalHours - 24;
          }

          end_time = `${String(totalHours).padStart(2, "0")}:${String(
            minutes_
          ).padStart(2, "0")}:${String(seconds_).padStart(2, "0")}`;
          // timeref.current.max = end_time;
          // timeref.current.min = start_time;
        } else {
          start_time = "00:00:00";
          end_time = "23:00:00";
        }
      }

      // console.log(" new Date(dateInput)", new Date(dateInput));

      console.log("start_time", start_time);
      console.log("end_time", end_time);
      // console.log("restaurant_imagesrestaurant_images", restaurant);
      // window.scrollTo({
      //   top: 0,
      //   left: 0,
      // });
    }
  }

  const handleTime = (e) => {
    setTimeInput(e.target.value);
  };

  const handleDate = (e) => {
    const dayOfWeek = new Date(e.target.value).toLocaleString("en-us", {
      weekday: "short",
    });
    nameDay = days.find((dayObj) => dayOfWeek in dayObj)[dayOfWeek];

    const dayCur = working_times.filter((time) => {
      if (time.working_day.en_name == nameDay) {
        return time;
      }
    });
    isOff = dayCur[0]?.is_off;
    is_all_day = dayCur[0]?.is_all_day;
    if (isOff == 0) {
      start_time = dayCur[0]?.start_time;
      let hours_count =
        dayCur[0]?.hours_count == 24 ? 23 : dayCur[0]?.hours_count;

      const [hours_, minutes_, seconds_] = start_time.split(":").map(Number);

      let totalHours = hours_ + hours_count;

      if (totalHours >= 24) {
        totalHours = totalHours - 24;
      }

      end_time = `${String(totalHours).padStart(2, "0")}:${String(
        minutes_
      ).padStart(2, "0")}:${String(seconds_).padStart(2, "0")}`;
    } else {
      start_time = "00:00:00";
      end_time = "23:00:00";
    }
    timeref.current.max = end_time;
    timeref.current.min = start_time;

    setDateInput(e.target.value);
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  useEffect(() => {
    const currentTime = new Date();

    // Format the time as HH:mm (hours and minutes)
    let hours = (currentTime.getHours() + 1).toString().padStart(2, "0");

    const minutes = currentTime.getMinutes().toString().padStart(2, "0");

    const currentDate = new Date();
    // console.log("formattedTime", formattedTime);
    // Format the date as YYYY-MM-DD (required by input type="date")

    const formattedDate = currentDate.toISOString().slice(0, 10);
    let day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear().toString().padStart(2, "0");
    console.log("hours", hours);
    if (hours == "24") {
      hours = "01";
      // console.log("day", day);
      day = day + 1;
    }
    const formattedTime = `${hours}:${minutes}`;
    const formatDate = `${year}-${month}-${day}`;
    setDateInput(formatDate);

    // console.log("formatDate", formatDate);
    setTimeInput(formattedTime);
  }, []);
  console.log("newDat", new Date().toISOString().slice(0, 10));
  const currentTimem = new Date(`${dateInput}T${timeInput}`);
  const dubaiTime = currentTimem.toLocaleString("en-US", {
    timeZone: "Asia/Dubai",
  });

  const dateObj = new Date(dubaiTime);
  const hoursD = dateObj.getHours().toString().padStart(2, "0");
  const minutesD = (dateObj.getMinutes() + 1).toString().padStart(2, "0");
  const time = `${hoursD}:${minutesD}`;
  console.log("localTime", time);

  const [errorEmail, setErrorEmail] = useState("");

  const checkValEmail = email.length > 0 && !/\S+@\S+\.\S+/.test(email);

  const checkInputs = () => {
    if (email.length > 0 && !/\S+@\S+\.\S+/.test(email)) {
      setErrorEmail("The email must be a valid email address.");
    } else {
      setErrorEmail("");
    }
  };

  const dataForm = {
    date: dateInput,
    time: time,
    people_no: count,
    restaurant_id: params.restaurant_id,
    email: email,
    phone: phone,
    note: note,
  };

  const handelBook = (e) => {
    e.preventDefault();

    if (isOff == 0) {
      checkInputs();
      if (!checkValEmail) {
        setLoading(true);
        axios
          .post(`/${room_uuid}/restaurant/book_table`, dataForm)
          .then((res) => {
            console.log("res", res);
            if (res.data.status == false) {
              toast.current.show({
                severity: "error",
                summary: "Error",
                detail: t(`${res.data.message}`),
                life: 5000,
              });
            }
            if (res.data.status == true) {
              ReactGA.event({
                category: "Book table ",
                action: "Click",
                value: ``,
              });
              navigate(`/${room_uuid}/HKOrderPlaced?theme=true`);
            }
            setLoading(false);
          })
          .catch((err) => {
            // toast.current.show({
            //   severity: "error",
            //   summary: "Error",
            //   detail: "Message Content",
            //   life: 3000,
            // });
            console.log("err", err);
            setLoading(false);
          });
      }
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: `We are off on ${day_name} at ${timeInput}, please choose another time`,
        life: 5000,
      });
    }
  };
  // console.log("cur", new Date() );
  // console.log("cur___", new Date(Date.now() + (3600 * 1000 * 25)) );

  const handleInvalid = (event, index) => {
    const min = timeref.current.min.split(":");
    const max = timeref.current.max.split(":");
    const hourStartTime = min[0];
    const formatStartTime =
      Number(hourStartTime) > 12
        ? `${hourStartTime - 12}:${min[1]} PM`
        : `${hourStartTime}:${min[1]} AM`;

    const hourEndTime = max[0];
    const formatEndTime =
      Number(hourEndTime) > 12
        ? `${hourEndTime - 12}:${max[1]} PM`
        : `${hourEndTime}:${max[1]} AM`;
    console.log("start_time", formatStartTime);
    console.log("end_time", formatEndTime);
    event.target.setCustomValidity(
      `The time must be between ${formatStartTime} and ${formatEndTime}`
    );
    // console.log("timeref value", timeref.current.value);
    // console.log("timeref min", timeref.current.min);
    // <HeaderInner headerName={"Restaurant Booking"} bgColor={bgColor} />
    // console.log("timeref max", timeref.current.max);
    // <div className="book-table">
  };
  return (
    <div className={theme ? "food book-table" : "book-table"}>
      {theme ? null : (
        <HeaderInner headerName={"Restaurant Booking"} bgColor={bgColor} />
      )}
      {isLoading ? (
        <SpaLoader />
      ) : (
        <main className="bg-white  pad-top-0 back-white book_">
          <Toast ref={toast} position="bottom-center" />

          {theme ? (
            <BannerHome
              className={"bot_rad"}
              headerImages={images}
              isVideo={isVideo}
              isOverllay={true}
            />
          ) : (
            <Carosle headerImages={images} isVideo={isVideo} />
          )}

          <div className="container p-2 mt-4">
            <p className="bk-title">{t("Book a Table")}</p>
            <form onSubmit={handelBook}>
              <div className="bk-date">
                <Form.Group
                  className="mb-3 row align-items-center justify-content-start"
                  controlId="formBasicname"
                >
                  <p className="col-3 m-0 "> {t("Booking Date")}</p>
                  <Form.Control
                    type="date"
                    value={dateInput}
                    defaultValue={dateInput}
                    min={new Date().toISOString().slice(0, 10)}
                    onChange={handleDate}
                    placeholder={t("Enter Your Email")}
                    className="col-8 ml-2 date-input dt-input m_16"
                  />
                </Form.Group>
              </div>
              <div className="bk-time mb-4">
                <Form.Group
                  className="mb-3 row align-items-center justify-content-start "
                  controlId="formBasicname"
                >
                  <p className="col-3 m-0 no-break">{t("Booking Time")}</p>
                  <Form.Control
                    type="time"
                    value={timeInput}
                    onChange={handleTime}
                    // max="15:00:00"
                    min={start_time}
                    max={end_time}
                    ref={timeref}
                    onInvalid={handleInvalid}
                    placeholder="Enter Your Email"
                    className="col-8 ml-2 time-input dt-input m_16"
                  />
                </Form.Group>
              </div>
              <div className="bk-people">
                <Form.Group
                  className="mb-3 row align-items-center justify-content-start"
                  controlId="formBasicname"
                >
                  <p className="col-5 m-0 ">{t("Number of People")}</p>
                  <div className="num quantity">
                    <span
                      className="removeItem"
                      onClick={handleDecrease}
                      id="plus"
                    >
                      <i className="fa fa-minus text-secondary"></i>
                    </span>
                    <input
                      type="text"
                      className="purchase_quantity up"
                      id="purchase_quantity"
                      min="1"
                      max="99"
                      delta="0"
                      cost=""
                      name="people_no"
                      value={count}
                      onChange={(e) => setCount(parseInt(e.target.value))}
                    />
                    <span
                      className=" addItem"
                      id="dec"
                      onClick={handleIncrease}
                    >
                      <i className="fa fa-plus text-secondary"></i>
                    </span>
                  </div>
                </Form.Group>
              </div>
              <Row className="px-2">
                <Col xs="12">
                  <Form.Group
                    className="mb-4 dark-placeholder"
                    controlId="formBasicname"
                  >
                    <Form.Control
                      type="email"
                      className=""
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder={t("Your Email (Optional)")}
                    />
                  </Form.Group>
                  {errorEmail != "" && (
                    <span className="err_msg">{errorEmail}</span>
                  )}
                </Col>
                <Col xs="12">
                  <Form.Group
                    className="mb-5 dark-placeholder"
                    controlId="formBasicname"
                  >
                    <Form.Control
                      type="text"
                      className=""
                      onChange={(e) => setPhone(e.target.value)}
                      placeholder={t("97150000000000 (Optional)")}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicname">
                    <p className="note">{t("Add note")}</p>
                    <Form.Control
                      rows={2}
                      onChange={(e) => setNote(e.target.value)}
                      as="textarea"
                      placeholder={t("Your Notes")}
                      style={{ height: "72px" }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="padd-div text-center ">
                <Button
                  type="submit"
                  className="reg-btn order-btn less-pad bg-text"
                  disabled={loading}
                >
                  {t("Book")}
                </Button>
              </div>
            </form>
          </div>
        </main>
      )}
      {theme && <NewFooter />}
    </div>
  );
};

export default BookTable;
