import axios from "axios";
import { API_URL } from "../constants/api";
import { useQuery } from "react-query";

const APIURL = axios.create({
  baseURL: API_URL,
});

const RoomDining = async (uuid, lang) => {
  return await APIURL.get(`/${uuid}/restaurants?b=kit`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowRoomDining = (uuid, lang) => {
  return useQuery(`in_room_dining-${lang}`, () => RoomDining(uuid, lang));
};
const ScanRoomDining = async (uuid, lang) => {
  return await APIURL.get(`/${uuid}/restaurant-menu/categories`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowScanRoomDining = (uuid, lang) => {
  return useQuery(`ScanRoomDining-${uuid}-${lang}`, () =>
    ScanRoomDining(uuid, lang)
  );
};
const ScanResturants = async (uuid, lang) => {
  return await APIURL.get(`/${uuid}/restaurant-menu/restaurants-booking`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowScanResturants = (uuid, lang) => {
  return useQuery(`ScanResturants-${uuid}-${lang}`, () =>
    ScanResturants(uuid, lang)
  );
};
const ScanCategory = async (uuid, lang, cat_id) => {
  return await APIURL.get(`/${uuid}/restaurant-menu?cat=${cat_id}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowScanCategory = (uuid, lang, cat_id) => {
  return useQuery(`ScanCategory-${uuid}-${lang}-${cat_id}`, () =>
    ScanCategory(uuid, lang, cat_id)
  );
};
const ScanSubCategory = async (uuid, lang, cat_id) => {
  return await APIURL.get(`/${uuid}/restaurant-menu/categories?cat=${cat_id}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowScanSubCategory = (uuid, lang, cat_id) => {
  return useQuery(`ScanSubCategory-${uuid}-${lang}-${cat_id}`, () =>
    ScanSubCategory(uuid, lang, cat_id)
  );
};
export const RestaurantSubMenuAPI = async ({
  room_uuid,
  restaurant_uuid,
  selected,
  pageParam,
  lang,
  isbooking,
}) => {
  return await APIURL.get(
    `/${room_uuid}/restaurant/${restaurant_uuid}?cat=${selected}&page=${pageParam}${isbooking}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        lang: lang,
      },
    }
  );
};
// export const ShowRestaurantSubMenu = (uuid, id, c_id) => {
//     return useQuery(`restaurant_submenu-${id}-${c_id}`, () => RestaurantSubMenu(uuid, id, c_id));
// }
const RestaurantCategories = async (uuid, r_uuid, lang, isbooking) => {
  return await APIURL.get(
    `/${uuid}/restaurant/categories/${r_uuid}${isbooking}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        lang: lang,
      },
    }
  );
};
export const ShowRestaurantCategories = (uuid, r_uuid, lang, isbooking) => {
  return useQuery(`restaurant_category-${lang}-${r_uuid}`, () =>
    RestaurantCategories(uuid, r_uuid, lang, isbooking)
  );
};
const RestaurantSubCategories = async (uuid, r_uuid, lang, cat, isbooking) => {
  return await APIURL.get(
    `/${uuid}/restaurant/categories/${r_uuid}?cat=${cat}${isbooking}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        lang: lang,
      },
    }
  );
};
export const ShowRestaurantSubCategories = (
  uuid,
  r_uuid,
  lang,
  cat,
  isbooking
) => {
  return useQuery(`restaurant_sub-category-${lang}-${r_uuid}-${cat}`, () =>
    RestaurantSubCategories(uuid, r_uuid, lang, cat, isbooking)
  );
};

const RestaurantItem = async (uuid, f_id, lang) => {
  return await APIURL.get(`/${uuid}/restaurant/get-item-restaurant/${f_id}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowRestaurantItem = (uuid, f_id, lang) => {
  return useQuery(`RestaurantItem-${lang}-${f_id}`, () =>
    RestaurantItem(uuid, f_id, lang)
  );
};
const ComboMealsItems = async (uuid, restauran_uuid, lang) => {
  return await APIURL.get(`/${uuid}/restaurant/combo-meals/${restauran_uuid}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowComboMealsItems = (uuid, restauran_uuid, lang) => {
  return useQuery(`ComboMealsItems-${lang}-${restauran_uuid}`, () =>
    ComboMealsItems(uuid, restauran_uuid, lang)
  );
};
const ScanComboMealsItems = async (uuid, lang) => {
  return await APIURL.get(`/${uuid}/restaurant-menu/combo-meals`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowScanComboMealsItems = (uuid, lang) => {
  return useQuery(`ScanComboMealsItems-${lang}`, () =>
    ScanComboMealsItems(uuid, lang)
  );
};
