import axios from "axios";
import { API_URL } from "../constants/api";
import { useQuery } from "react-query";

const APIURL = axios.create({
  baseURL: API_URL,
});

const Entertainment = async (uuid, lang) => {
  return await APIURL.get(`/${uuid}/entertainment/companies`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowEntertainment = (uuid, lang) => {
  return useQuery(`show_entertainment-${lang}`, () =>
    Entertainment(uuid, lang)
  );
};

const Entertainments = async (uuid, e_uuid, lang) => {
  return await APIURL.get(`/${uuid}/entertainment/categories/${e_uuid}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowEntertainments = (uuid, e_uuid, lang) => {
  return useQuery(`entertainments-${lang}-${e_uuid}`, () =>
    Entertainments(uuid, e_uuid, lang)
  );
};
const PharmacySubMenu = async (uuid, ph_uuid, id, lang) => {
  return await APIURL.get(`/${uuid}/pharmacy/${ph_uuid}?cat=${id}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};

export const ShowPharmacySubMenu = (uuid, ph_uuid, id, lang) => {
  return useQuery(`pharmacy_sub_menu-${lang}-${id}`, () =>
    PharmacySubMenu(uuid, ph_uuid, id, lang)
  );
};
