import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";

import PharmacyLoader from "../../Components/Loaders/PharmacyLoader";
import Fade from "react-reveal/Fade";
import { ShowFlowers } from "../../API/FlowersAPI";
import FlowersComponent from "../../Components/FlowersComponent";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { Zoom } from "react-reveal";
import { useTranslation } from "react-i18next";
import { ShowHHTaxi } from "../../API/HHTaxiAPI";
import Title from "../../components/title/Title";
import Overllay from "../../assets/new_imgs/Rectangle 1072.png";
import NewFooter from "../../NewFooter";

function HHTaxiCompany() {
  const [flowerCategories, setFlowerCategories] = useState();
  const [title, setTitle] = useState();
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  // setTimeout(() => {
  //   setEnableClass(false);
  // }, 3000);
  const lang = localStorage.getItem("lang");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  const navigate = useNavigate();
  let flowers;
  let hh_taxi_booking_companies;
  let bgColor;
  let color;
  let text;
  const { data, isLoading, error } = ShowHHTaxi(room_uuid, lang);
  if (!isLoading) {
    flowers = data.data.data;
    console.log("ShowHHTaxi", ShowHHTaxi);
    if (flowers.hh_taxi_booking_companies.length > 0) {
      color = flowers.color;
      hh_taxi_booking_companies = flowers.hh_taxi_booking_companies;
      bgColor = flowers.header_color;
      text = flowers.title;
    }
    // console.log("flowers", flowers);
    if (hh_taxi_booking_companies?.length == 1) {
      navigate(
        `/${room_uuid}/hh-taxibooking-companies/${hh_taxi_booking_companies[0].uuid}`,
        { replace: true }
      );
    }
    // console.log("ShowFlowers", flowers);
  }
  const new_design = localStorage.getItem("new_design");
  const theme = new_design == "true";
  //  <HeaderInner headerName={text} bgColor={bgColor} color={color} />
  return (
    <div className={theme ? "food" : ""}>
      {theme ? (
        <Title title={text} />
      ) : (
        <HeaderInner headerName={text} bgColor={bgColor} color={color} />
      )}
      <main className="home-main bg-white  pad-top-0 flowers">
        <div className={theme ? "box_food" : ""}>
          {isLoading ? (
            <SpaLoader />
          ) : hh_taxi_booking_companies?.length > 1 ? (
            hh_taxi_booking_companies?.map((flower, idx) => {
              return theme ? (
                <div key={idx}>
                  <Link
                    to={`/${room_uuid}/hh-taxibooking-companies/${flower.uuid}`}
                  >
                    <img src={flower?.logo} alt="" className="booking_img" />
                    <img src={Overllay} alt="" className="overlay" />
                    <div className="text_food">
                      <h5 className="">{flower.name}</h5>
                    </div>{" "}
                  </Link>
                </div>
              ) : (
                <div key={idx}>
                  <Zoom>
                    <div
                      className="con-img-container v3"
                      onClick={() =>
                        navigate(
                          `/${room_uuid}/hh-taxibooking-companies/${flower.uuid}`
                        )
                      }
                    >
                      <div className="con-img">
                        <img src={flower.logo} alt="" />
                        <p></p>
                      </div>
                    </div>
                    <div className="con-text-container">
                      <p>{flower.name}</p>
                      <hr className="mb-0 dark" />
                    </div>
                  </Zoom>
                </div>
              );
            })
          ) : null}
        </div>
      </main>
      {theme && <NewFooter />}
    </div>
  );
}
export default HHTaxiCompany;
