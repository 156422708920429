import React from "react";
import { Link } from "react-router-dom";
import "./NewLoader.scss";
const NewLoader = () => {
  return (
    <>
      <div className="general-post-container restaurant-post">
        <Link to="" className="general-post">
          <div className="closed-tag"></div>
          <div className={"img-pnl animated-background smooth"}>
            <img src="" alt="Post" className="" />
          </div>
          <div className="txt-pnl">
            <h2 className="animated-background"></h2>
            <p className="animated-background"></p>
          </div>
        </Link>
      </div>

      <div className="add-post-container add-cart">
        <div className="add-cart-post new spa ">
          <div className="add-cart-post-inner">
            <div className="animated-background bg-skimmer"></div>
            <span className="img-pnl navigate">
              <img src="" />
            </span>
            <div
              className="txt-pnl navigate"

              // onClick={() => navigate("/SpaAddService")}
            >
              <h2></h2>
              <p></p>
              <div className="flex-div align-item-center">
                <h3></h3>
                <Link to="" className="reg-btn service"></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="add-post-container add-cart">
        <div className="add-cart-post new spa ">
          <div className="add-cart-post-inner">
            <div className="animated-background bg-skimmer"></div>
            <span className="img-pnl navigate">
              <img src="" />
            </span>
            <div
              className="txt-pnl navigate"

              // onClick={() => navigate("/SpaAddService")}
            >
              <h2></h2>
              <p></p>
              <div className="flex-div align-item-center">
                <h3></h3>
                <Link to="" className="reg-btn service"></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="add-post-container add-cart">
        <div className="add-cart-post new spa ">
          <div className="add-cart-post-inner">
            <div className="animated-background bg-skimmer"></div>
            <span className="img-pnl navigate">
              <img src="" />
            </span>
            <div
              className="txt-pnl navigate"

              // onClick={() => navigate("/SpaAddService")}
            >
              <h2></h2>
              <p></p>
              <div className="flex-div align-item-center">
                <h3></h3>
                <Link to="" className="reg-btn service"></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="add-post-container add-cart">
        <div className="add-cart-post new spa ">
          <div className="add-cart-post-inner">
            <div className="animated-background bg-skimmer"></div>
            <span className="img-pnl navigate">
              <img src="" />
            </span>
            <div
              className="txt-pnl navigate"

              // onClick={() => navigate("/SpaAddService")}
            >
              <h2></h2>
              <p></p>
              <div className="flex-div align-item-center">
                <h3></h3>
                <Link to="" className="reg-btn service"></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="add-post-container add-cart">
        <div className="add-cart-post new spa ">
          <div className="add-cart-post-inner">
            <div className="animated-background bg-skimmer"></div>
            <span className="img-pnl navigate">
              <img src="" />
            </span>
            <div
              className="txt-pnl navigate"

              // onClick={() => navigate("/SpaAddService")}
            >
              <h2></h2>
              <p></p>
              <div className="flex-div align-item-center">
                <h3></h3>
                <Link to="" className="reg-btn service"></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewLoader;
