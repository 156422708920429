import React, { useRef, useState } from "react";
import { Button, Modal, Dropdown, Form } from "react-bootstrap";
import HeaderInner from "../../Components/HeaderInner";
import CarBookModal from "../../Components/CarBookModal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { useEffect } from "react";
import { ShowOther, ShowShuttle } from "../../API/Concierge";

import axios from "../../axios";
import { Toast } from "primereact/toast";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { useTranslation } from "react-i18next";
import TaxInclusive from "../../Components/TaxInclusive.";
import "../../pages/hotel-services/HotelServices.scss";
import Title from "../../components/title/Title";
import NewFooter from "../../NewFooter";
import BannerServices from "../../components/banner-home/BannerServices";

function Shuttle() {
  const navigate = useNavigate();
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  // const theme = location.search;
  const new_design = localStorage.getItem("new_design");
  const theme = new_design == "true";

  const [isDisabled, setIsDisabled] = useState(false);
  const { t, i18n } = useTranslation();
  const toast = useRef(null);
  const lang = localStorage.getItem("lang");
  let other;
  let bgColor;
  let color;
  let text;
  const { data, isLoading, error } = ShowShuttle(room_uuid, lang);
  if (!isLoading) {
    let newData = data.data.data;
    other = newData;
    color = newData?.color;
    bgColor = newData?.header_color;
    text = newData?.center_header;

    // console.log("ShowOther", newData);
  }

  const sendData = {
    type_id: "ofirestick",
  };
  const handelBook = () => {
    setIsDisabled(true);
    axios
      .post(`/${room_uuid}/concierge/book-service`, sendData, {
        headers: {
          lang: lang,
        },
      })
      .then((res) => {
        if (res.data.status == false) {
          toast.current.show({
            severity: "error",
            summary: t("Error"),
            detail: t(`${res.data.message}`),
            life: 4000,
          });
        }
        // console.log("res", res);
        if (res.data.status) {
          navigate(`/${room_uuid}/HKOrderPlaced`, {
            state: {
              metadata: {
                res: res.data.data,
              },
            },
          });
        }
        setIsDisabled(false);
      })
      .catch((err) => {
        setIsDisabled(false);
        console.log(err);
      });
  };

  return (
    <div className={theme ? "food" : ""}>
      {theme ? <Title title={text} /> : <HeaderInner headerName={text} />}
      <main className="home-main bg-white back-white book-table pad-top-0">
        <Toast ref={toast} position="bottom-center" />

        {isLoading ? (
          <SpaLoader />
        ) : (
          <>
            {theme ? (
              <BannerServices headerImages={[other.image]} />
            ) : (
              <div className="con-order-img">
                <img src={other.image} alt="" />
              </div>
            )}
            <p className="bk-title mt-3">{t(`${other.title}`)}</p>
            {/* <div className="padd-div text-center mt-5">
              <Button
                className="reg-btn book lg"
                disabled={isDisabled}
                onClick={handelBook}
                // to={`/${room_uuid}/HKOrderPlaced`}
              >
                {t("Book")}
              </Button>
            </div> */}
            {other?.destinations?.length > 0 && (
              <table>
                <tbody>
                  <tr class="main">
                    <th> {t(`Destination`)} </th>
                    <th> {t(`Timing`)} </th>
                  </tr>
                  {other?.destinations.map((item) => {
                    return (
                      <tr>
                        <td>{item?.name}</td>
                        <td>{item?.timing}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            {/* <table>
              <tbody>
                <tr>
                  <th>Destination</th>
                  <th>Timing</th>
                </tr>
                <tr>
                  <td>Destination (1)</td>
                  <td>Timing</td>
                </tr>
                <tr>
                  <td>
                    <p>Destination (2)</p>
                  </td>

                  <td>Timing</td>
                </tr>
              </tbody>
            </table> */}

            {/* <h3 className="text-center">
              <TaxInclusive />
            </h3> */}
            <div className="spacer-40 my-5"></div>
          </>
        )}
      </main>
      {location.search && <NewFooter />}
    </div>
  );
}
export default Shuttle;
