import React, { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "./banner.scss";

const BannerServices = ({ className, headerImages, isFirst, isVideo }) => {
  console.log("headerImages", headerImages);
  return (
    <div className={className}>
      <Carousel
        className="banner banner-services noSelect mb-2"
        swipeable={true}
        autoPlay
        infiniteLoop={true}
        showIndicators={false}
        showArrows={false}
        showThumbs={false}
        emulateTouch={true}
      >
        {headerImages?.map((slide) => {
          return (
            <div className="carousel-item-custom noSelect" data-interval={3000}>
              <img src={slide} alt="Slide" />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default BannerServices;
