import React, { useEffect } from "react";
import Title from "../../components/title/Title";
import { Link, useLocation, useParams } from "react-router-dom";
import LaundrayImg from "../../assets/new_imgs/hotel/Group 3865.png";
import HousekeepingImg from "../../assets/new_imgs/hotel/Group 386579.png";
import ConceirgeImg from "../../assets/new_imgs/hotel/Group 386612.png";
import MaintenanceImg from "../../assets/new_imgs/hotel/Group 38668.png";
import Overllay from "../../assets/new_imgs/Rectangle 1072.png";
import NewFooter from "../../NewFooter";
import "../food-and-beverages/FoodAndBeverages.scss";
import NewLoader from "../../components/title/Loader/NewLoader";
import { t } from "i18next";

const ExternalServices = () => {
  const params = useParams();
  const lang = localStorage.getItem("lang");
  const location = useLocation();
  const metadata = location.state?.metadata;
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  console.log("metadata", metadata);
  return (
    <div className={`food ${lang == "AR" && "arabic"}`}>
      <Title title={"External Services"} />

      {/* <NewLoader /> */}
      <div className="box_food">
        {metadata.map((service) => {
          if (service.id == 10) {
            return (
              <Link to={`/${params.room_uuid}/external-services/pharmacy`}>
                <img
                  src={
                    "https://d3l5wxnahfuscp.cloudfront.net/static/pharmacy.png"
                  }
                  alt=""
                />
                <img src={Overllay} alt="" className="overlay" />
                <h6 className="sub_title">{t("Pharmacy")}</h6>
              </Link>
            );
          }
          if (service.id == 11) {
            return (
              <Link to={`/${params.room_uuid}/external-services/flowers`}>
                <img
                  src={
                    "https://d3l5wxnahfuscp.cloudfront.net/static/flowers.png"
                  }
                  alt=""
                />
                <img src={Overllay} alt="" className="overlay" />
                <h6 className="sub_title">{t("Flowers")}</h6>
              </Link>
            );
          }
          if (service.id == 9) {
            return (
              <Link to={`/${params.room_uuid}/external-services/supermarket`}>
                <img
                  src={
                    "https://d3l5wxnahfuscp.cloudfront.net/static/supermarket.png"
                  }
                  alt=""
                />
                <img src={Overllay} alt="" className="overlay" />
                <h6 className="sub_title">{t("Supermarket")}</h6>
              </Link>
            );
          }
          if (service.id == 16) {
            return (
              <Link to={`/${params.room_uuid}/external-services/entertainment`}>
                <img
                  src={
                    "https://d3l5wxnahfuscp.cloudfront.net/static/entertainment.png"
                  }
                  alt=""
                />
                <img src={Overllay} alt="" className="overlay" />
                <h6 className="sub_title">{t("Entertainment")}</h6>
              </Link>
            );
          }
          if (service.id == 13) {
            return (
              <Link to={`/${params.room_uuid}/external-services/cars`}>
                <img
                  src={
                    "https://d3l5wxnahfuscp.cloudfront.net/static/rental.png"
                  }
                  alt=""
                />
                <img src={Overllay} alt="" className="overlay" />
                <h6 className="sub_title">{t("Car Rental")}</h6>
              </Link>
            );
          }
          if (service.id == 6) {
            return (
              <Link to={`/${params.room_uuid}/external-services/taxi`}>
                <img
                  src={"https://d3l5wxnahfuscp.cloudfront.net/static/taxi.png"}
                  alt=""
                />
                <img src={Overllay} alt="" className="overlay" />
                <h6 className="sub_title">{t("Taxi")}</h6>
              </Link>
            );
          }
          if (service.id == 21) {
            return (
              <Link to={`/${params.room_uuid}/external-services/hh-taxi`}>
                <img
                  src={"https://d3l5wxnahfuscp.cloudfront.net/static/taxi.png"}
                  alt=""
                />
                <img src={Overllay} alt="" className="overlay" />
                <h6 className="sub_title">{t("Taxi Booking")}</h6>
              </Link>
            );
          }
          if (service.id == 14) {
            return (
              <Link
                to={`/${params.room_uuid}/external-services/hh-housekeeping`}
              >
                <img
                  src={
                    "https://d3l5wxnahfuscp.cloudfront.net/static/Housekeeping.png"
                  }
                  alt=""
                />
                <img src={Overllay} alt="" className="overlay" />
                <h6 className="sub_title">{service?.display_name}</h6>
              </Link>
            );
          }
        })}
      </div>
      <NewFooter />
    </div>
  );
};

export default ExternalServices;
