import React, { useEffect } from "react";
import Title from "../../components/title/Title";
import { Link, useParams } from "react-router-dom";
import InRoom from "../../assets/new_imgs/foods/Group 38661.png";
import Booking from "../../assets/new_imgs/foods/Group 38662.png";
import Overllay from "../../assets/new_imgs/Rectangle 1072.png";
import NewFooter from "../../NewFooter";
import "./FoodAndBeverages.scss";
import NewLoader from "../../components/title/Loader/NewLoader";

const FoodAndBeverages = () => {
  const params = useParams();
  const lang = localStorage.getItem("lang");
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  return (
    <div className={`food ${lang == "AR" && "arabic"}`}>
      <Title title={"Food & Beverages"} />
      {/* <NewLoader /> */}
      <div className="box_food">
        <Link to={`/${params.room_uuid}/food-and-beverages/in-room-dining`}>
          <img
            src={"https://d3l5wxnahfuscp.cloudfront.net/static/Dining.png"}
            alt=""
          />
          <img src={Overllay} alt="" className="overlay" />
          <h6 className="sub_title">In-Room Dining</h6>
        </Link>
        <Link to={`/${params.room_uuid}/food-and-beverages/restaurant-booking`}>
          <img
            src={"https://d3l5wxnahfuscp.cloudfront.net/static/FB.png"}
            alt=""
          />
          <img src={Overllay} alt="" className="overlay" />
          <h6 className="sub_title">Restaurant Booking</h6>
        </Link>
      </div>
      <NewFooter />
    </div>
  );
};

export default FoodAndBeverages;
