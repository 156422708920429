import axios from "axios";
import { API_URL } from "../constants/api";
import { useQuery } from "react-query";

const APIURL = axios.create({
  baseURL: API_URL,
});

const Flowers = async (uuid, lang) => {
  return await APIURL.get(`/${uuid}/florists`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowFlowers = (uuid, lang) => {
  return useQuery(`flowers-${lang}`, () => Flowers(uuid, lang));
};

const AllFlowers = async (uuid, ph_uuid, lang) => {
  return await APIURL.get(`/${uuid}/florists/categories/${ph_uuid}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowAllFlowers = (uuid, ph_uuid, lang) => {
  return useQuery(`all_flowers-${lang}-${ph_uuid}`, () =>
    AllFlowers(uuid, ph_uuid, lang)
  );
};
export const FlowerSubMenuAPI = async ({
  room_uuid,
  Flower_uuid,
  selected,
  pageParam,
  lang,
}) => {
  return await APIURL.get(
    `/${room_uuid}/florists/${Flower_uuid}?cat=${selected}&page=${pageParam}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        lang: lang,
      },
    }
  );
};

// export const ShowFlowerSubMenu = (uuid, ph_uuid, id) => {
//     return useQuery(`flower_sub_menu-${id}`, () => FlowerSubMenu(uuid, ph_uuid, id));
// }

const FlowerItem = async (uuid, f_id, lang) => {
  return await APIURL.get(`/${uuid}/florists/get-item-florist/${f_id}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowFlowerItem = (uuid, f_id, lang) => {
  return useQuery(`FlowerItem-${lang}-${f_id}`, () =>
    FlowerItem(uuid, f_id, lang)
  );
};
