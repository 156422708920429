import React from "react";
import HeaderInner from "../../Components/HeaderInner";
import Textheader from "../../Components/Textheader";
import Clientinfo from "../../Components/Clientinfo";
import Post1 from "../../assets/images/Car-Rental/Car-1.png";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Title from "../../components/title/Title";
import NewFooter from "../../NewFooter";
function EntertainmentOrderPlaced() {
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const data = location.state?.metadata;
  console.log("location.state?.metadata", location.state?.metadata);
  const { t, i18n } = useTranslation();
  const currency = localStorage.getItem("currency");
  const new_design = localStorage.getItem("new_design");
  const theme = new_design == "true";
  return (
    <div className={theme ? "food" : ""}>
      {theme ? <Title title={"Order Detail"} /> : <HeaderInner />}
      <main className="bg-white pad-top-0 back-white">
        <Textheader />
        <Clientinfo
          roomNumber={data.res.roomNumber}
          orderNumber={data.res.order_number}
          orderedAt={data.res.orderedAt}
        />

        <div className="full-heading grey-bg flex">
          <h2 className="blck co-header">{t("Order Details")}</h2>
        </div>
        <div className="full-div padd-div">
          <div className="booking-order-detail">
            <div className="img-pnl">
              <img src={data.data.img} alt="Spa" />
            </div>
            <div className="txt-pnl">
              <p>
                {data.data.name} {data.data.year}
              </p>
              <h3>
                {currency} {data.data.price}
              </h3>
            </div>
          </div>
        </div>
        <div className="spacer-20"></div>
        <div className="full-div padd-div text-center">
          <Link className="reg-btn large" to={`/${room_uuid}/qr-code`}>
            {t("Back to Home")}
          </Link>
        </div>
      </main>
      {theme && <NewFooter />}
    </div>
  );
}
export default EntertainmentOrderPlaced;
