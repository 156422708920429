import React, { useState, useRef, useEffect } from "react";
import HeaderInner from "../../Components/HeaderInner";
import { Button, Modal } from "react-bootstrap";
import ViewPharmacyCartModal from "../../Components/ViewPharmacyCartModal";
import PharmacyCartPost from "../../Components/PharmacyCartPost";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import useDraggableScroll from "use-draggable-scroll";
import { useCartStore } from "../../store/useStore";
import PharmacySubMenuLoader from "../../Components/Loaders/PharmacySubMenuLoader";
import ViewCart from "../../Components/ViewCart";
import { useInfiniteQuery } from "react-query";
import Search from "../../Components/Search";
import {
  ShowSupermarketSubCatAPI,
  ShowSupermarketSubMenuAPI,
} from "../../API/Supermarket";
import PharmacyLoader from "../../Components/Loaders/PharmacyLoader";
import { Zoom } from "react-reveal";
import ImageSuperMarket from "../../Components/ImageSuperMarket";
import SupermarketCarosle from "../../Components/SupermarketCarosle";
import Title from "../../components/title/Title";
import NewFooter from "../../NewFooter";

function SupermarketSubCategory() {
  const params = useParams();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [selected, setSelected] = useState(params.id);
  // const [pharmacyItems, setPharmacyItems] = useState();
  const [total, setTotal] = useState(0);
  let ref = useRef(null);
  const { onMouseDown } = useDraggableScroll(ref);
  const { cart } = useCartStore((state) => ({
    cart: state.cart,
  }));
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  console.log("sel", location);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const pharmacy_uuid = params.supermarket_uuid;
  const cat_id = params.cat_id;

  // const theme = location.state?.theme;
  const new_design = localStorage.getItem("new_design");
  const theme = new_design == "true";

  console.log("pharmacy_uuid", pharmacy_uuid);
  console.log("cat_id", cat_id);
  const lang = localStorage.getItem("lang");
  let pharmacies;
  let title;
  let subermarktID;
  let headerImages;
  let breadcrumbs;
  let supermarket;
  const { isLoading, data } = ShowSupermarketSubCatAPI(
    room_uuid,
    pharmacy_uuid,
    cat_id,
    lang
  );
  if (!isLoading) {
    if (data.data.status === false) {
      navigate(`/${room_uuid}/qr-scan`);
    } else {
      // console.log("subCa", data);
      title = data.data.data.center_header;
      subermarktID = data.data.data.supermarket.id;
      supermarket = data.data.data.supermarket;
      headerImages = data.data.data.supermarket.banners;
      pharmacies = data.data.data.categories;
      breadcrumbs = data.data.data.breadcrumbs;

      // pharmacies?.map((item) => {
      //   if (item.id == cat_id) {
      //     active_sub_categories = item.activeSubCategories;
      //   }
      // });
      // console.log("active_sub_categories", active_sub_categories);
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  return (
    <div className={theme ? "food" : ""}>
      {theme ? <Title title={title} /> : <HeaderInner headerName={title} />}
      {isLoading ? (
        <PharmacyLoader />
      ) : (
        <main className="home-main bg-white back-white ">
          {headerImages?.length > 0 && (
            <header className="img-header m-0 mb-3">
              <SupermarketCarosle headerImages={headerImages} />
            </header>
          )}
          <Search
            placeholder="Search for Categories..."
            room_uuid={room_uuid}
            category="Supermarket"
          />
          <span className="supermarket_name">
            <span
              onClick={() =>
                navigate(
                  `/${room_uuid}/supermarket/${supermarket?.uuid}`,

                  { state: { theme: theme } }
                )
              }
            >
              Categories
            </span>
            {breadcrumbs?.all_parents?.parent?.name && (
              <>
                <i class="fas fa-angle-right"></i>
                <span onClick={() => navigate(-2)}>
                  {breadcrumbs?.all_parents?.parent?.name}
                </span>
              </>
            )}
            {breadcrumbs?.all_parents?.name && (
              <>
                <i class="fas fa-angle-right"></i>
                <span onClick={() => navigate(-1)}>
                  {breadcrumbs?.all_parents?.name}
                </span>
              </>
            )}
            {breadcrumbs?.name && (
              <>
                <i class="fas fa-angle-right"></i>
                {breadcrumbs?.name}
              </>
            )}
          </span>
          <ul className="display-list three-items suber_market_cat v2 v3">
            {pharmacies?.map((pharmacy, i) => {
              return (
                <>
                  <li key={i}>
                    <Zoom>
                      <Link
                        className="super_link"
                        to={
                          pharmacy?.show_categories == 0 &&
                          pharmacy.activeSubCategories.length > 0
                            ? `/${room_uuid}/SupermarketSubMenu/${subermarktID}/${pharmacy_uuid}/${pharmacy.id}/`
                            : pharmacy.activeSubCategories.length > 0
                            ? `/${room_uuid}/SupermarketSubCategory/${pharmacy_uuid}/${pharmacy.id}`
                            : `/${room_uuid}/SupermarketSubMenu/${subermarktID}/${pharmacy_uuid}/${pharmacy.id}/`
                        }
                        state={{ theme: theme }}
                      >
                        <div
                          className="img-pnl"
                          style={{
                            backgroundImage: `url(${pharmacy.image})`,
                          }}
                        ></div>
                        <ImageSuperMarket src={pharmacy.image} />
                        {/* <img src={pharmacy.image} alt="" /> */}
                        <div></div>
                        {/* <div className="bg-layer"></div> */}
                        <p className="text-black">{pharmacy.name}</p>
                      </Link>
                    </Zoom>
                  </li>
                </>
              );
            })}
          </ul>
        </main>
      )}
      {theme && <NewFooter />}
    </div>
  );
}
export default SupermarketSubCategory;
