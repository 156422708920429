import React, { useEffect } from "react";
import Title from "../../components/title/Title";
import { Link, useLocation, useParams } from "react-router-dom";
import LaundrayImg from "../../assets/new_imgs/hotel/Group 3865.png";
import HousekeepingImg from "../../assets/new_imgs/hotel/Group 386579.png";
import ConceirgeImg from "../../assets/new_imgs/hotel/Group 386612.png";
import MaintenanceImg from "../../assets/new_imgs/hotel/Group 38668.png";
import Overllay from "../../assets/new_imgs/Rectangle 1072.png";
import NewFooter from "../../NewFooter";
import "../food-and-beverages/FoodAndBeverages.scss";
import NewLoader from "../../components/title/Loader/NewLoader";
import { t } from "i18next";

const HotelServices = () => {
  const params = useParams();
  const lang = localStorage.getItem("lang");
  const location = useLocation();
  const metadata = location.state?.metadata;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  console.log("metadata", metadata);
  return (
    <div className={`food ${lang == "AR" && "arabic"}`}>
      <Title title={"Hotel Services"} />

      {/* <NewLoader /> */}
      <div className="box_food">
        {metadata.map((service) => {
          // if (service.id == 4) {
          //   return (
          //     <Link
          //       to={`/${params.room_uuid}/hotel-services/spa/${
          //         service.link.split(`${params.room_uuid}/spa/`)[1]
          //       }`}
          //     >
          //       <img src={ConceirgeImg} alt="" />
          //       <img src={Overllay} alt="" className="overlay" />
          //       <h6 className="sub_title">{service?.display_name}</h6>
          //     </Link>
          //   );
          // }
          if (service.id == 18) {
            return (
              <Link to={`/${params.room_uuid}/hotel-services/salon`}>
                <img src={ConceirgeImg} alt="" />
                <img src={Overllay} alt="" className="overlay" />
                <h6 className="sub_title">{service?.display_name}</h6>
              </Link>
            );
          }

          if (service.id == 3) {
            return (
              <Link
                to={`/${params.room_uuid}/hotel-services/laundry/${
                  service.link.split(`${params.room_uuid}/laundry/`)[1]
                }`}
              >
                <img
                  src={
                    "https://d3l5wxnahfuscp.cloudfront.net/static/Laundry.png"
                  }
                  alt=""
                />
                <img src={Overllay} alt="" className="overlay" />
                <h6 className="sub_title">{service?.display_name}</h6>
              </Link>
            );
          }
          if (service.id == 5) {
            return (
              <Link to={`/${params.room_uuid}/hotel-services/houseKeeping`}>
                <img
                  src={
                    "https://d3l5wxnahfuscp.cloudfront.net/static/Housekeeping.png"
                  }
                  alt=""
                />
                <img src={Overllay} alt="" className="overlay" />
                <h6 className="sub_title">{service?.display_name}</h6>
              </Link>
            );
          }
          if (service.id == 7) {
            return (
              <Link to={`/${params.room_uuid}/hotel-services/conceirge`}>
                <img
                  src={
                    "https://d3l5wxnahfuscp.cloudfront.net/static/Conceirge.png"
                  }
                  alt=""
                />
                <img src={Overllay} alt="" className="overlay" />
                <h6 className="sub_title">{service?.display_name}</h6>
              </Link>
            );
          }
        })}
      </div>
      <NewFooter />
    </div>
  );
};

export default HotelServices;
