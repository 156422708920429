import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import { Button, Col, Form, Row } from "react-bootstrap";
import Post from "../../assets/images/Spa/Post-3.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "../../axios";
import { array, number, object, string } from "yup";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import { Toast } from "primereact/toast";
import ReactGA from "react-ga4";
import "../../pages/hotel-services/HotelServices.scss";
import { useTranslation } from "react-i18next";
import TaxInclusive from "../../Components/TaxInclusive.";
import Title from "../../components/title/Title";
import NewFooter from "../../NewFooter";

function SpaAddService() {
  const toast = useRef(null);

  const [enableClass, setEnableClass] = useState(true);

  const [selectedTheraphist, setSelectedTheraphist] = useState();
  const [price_expression, setPrice_expression] = useState();
  const [timeInput, setTimeInput] = useState();
  const [currHoursPlusThree, setCurrHoursPlusThree] = useState();
  const [dateInput, setDateInput] = useState(new Date());
  const [isDisabled, setIsDisabled] = useState(false);
  const [loadign, setLoadign] = useState(false);
  const [bookLoadign, setBookLoadign] = useState(false);
  const [service, setService] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const [selectedPrice, setSelectedPrice] = useState();
  const [spaID, setSpaID] = useState();
  const [working_times, setWorking_times] = useState();
  const [day_name, setDay_name] = useState();
  const [therapists, setTherapists] = useState();
  const [phone, setPhone] = useState();
  // const [start_time, setStart_time] = useState
  const [spaService, setSpaService] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const currency = localStorage.getItem("currency");

  const formatTimeFunction = (time) => {
    console.log("formatTimeFunction", time);
    const f_time = time.split(":");

    return `${f_time[0]}:${f_time[1]}`;
  };

  const checkCurrentTimeWithMinTimeTheSpa = (cr_time) => {
    // console.log("cr_time", cr_time);
    const min_time_spa = timeref.current.min;
    const max_time_spa = timeref.current.max;
    const current_time_spa = timeref.current.value;
    console.log("max_time_spa", max_time_spa);
    console.log("min_time_spa", min_time_spa);
    console.log("current_time_spa", current_time_spa);

    const currentDate = new Date(dateInput);

    let day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear().toString().padStart(2, "0");
    // console.log("hours", hours);
    if (current_time_spa > max_time_spa) {
      // console.log("day", day);
      day = Number(day) + 1;
      const formatDate = `${year}-${month}-${day.toString().padStart(2, "0")}`;
      setDateInput(formatDate);
      toast.current.show({
        severity: "warn",
        summary: t("Warning"),
        detail: "The time has been adjusted as per the spa schedule",
        life: 3000,
      });
      //
      timeref.current.value = timeref.current.min;
    }

    // console.log("formatDate", formatDate);
  };

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const id = params.spa_id;
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const spa_uuid = rawPath.split("spa/")[1];
  // const theme = location.search;
  const new_design = localStorage.getItem("new_design");
  const theme = new_design == "true";
  // console.log("params.id", params.spa_id);

  let spaSchema = object({
    name: string(),
    email: string().email(),
    phone: string(),
    date: string(),
    time: string(),
    // therapist_id: number(),
    options: array().min(1),
    service_id: number(),
    spa_id: number(),

    // address: string().required(),
    // createdOn: date().default(() => new Date()),
  });
  const room_number = localStorage.getItem("room_number");
  setTimeout(() => {
    setEnableClass(false);
  }, 3000);

  const handleTime = (e) => {
    // const currHoursPlusThree = timeInput;
    e.target.setCustomValidity(``);

    checkCurrentTimeWithMinTimeTheSpa(e.target.value);
    // setTimeInput(e.target.value);
    const newDate = new Date();
    let hours = (newDate.getHours() + 6).toString().padStart(2, "0");

    const minutes = newDate.getMinutes().toString().padStart(2, "0");
    const formattedTime = `${hours}:${minutes}`;
    setCurrHoursPlusThree(formattedTime);

    if (
      newDate.toLocaleDateString() == new Date(dateInput).toLocaleDateString()
    ) {
      if (currHoursPlusThree < e.target.value) {
        setTimeInput(e.target.value);
      } else {
        setTimeInput(currHoursPlusThree);
        toast.current.show({
          severity: "warn",
          summary: t("Warning"),
          detail: "The booking time should be after 3 hours at least",
          life: 3000,
        });
      }
    } else {
      setTimeInput(e.target.value);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSpaService((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [errName, setErrName] = useState("");
  const [errorEmail, setErrorEmail] = useState("");

  const checkValEmail =
    spaService.email.length > 0 && !/\S+@\S+\.\S+/.test(spaService.email);
  // console.log("checkemail", !/\S+@\S+\.\S+/.test(spaService.email));
  const checkInputs = () => {
    if (spaService.name == "") {
      setErrName("The name field is required.");
    } else {
      setErrName("");
    }
    if (spaService.email.length > 0 && !/\S+@\S+\.\S+/.test(spaService.email)) {
      setErrorEmail("The email must be a valid email address.");
    } else {
      setErrorEmail("");
    }
  };

  useEffect(() => {
    const currentTime = new Date();

    // Format the time as HH:mm (hours and minutes)
    let hours = (currentTime.getHours() + 6).toString().padStart(2, "0");

    const minutes = currentTime.getMinutes().toString().padStart(2, "0");

    const currentDate = new Date();
    // console.log("formattedTime", formattedTime);
    // Format the date as YYYY-MM-DD (required by input type="date")

    const formattedDate = currentDate.toISOString().slice(0, 10);
    let day = currentDate.getDate().toString().padStart(2, "0");
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const year = currentDate.getFullYear().toString().padStart(2, "0");
    // console.log("hours", hours);
    if (hours == "24") {
      hours = "01";
      // console.log("day", day);
      day = day + 1;
    }

    const formattedTime = `${hours}:${minutes}`;
    const formatDate = `${year}-${month}-${day}`;
    setDateInput(formatDate);
    // console.log("formatDate", formatDate);
    setTimeInput(formattedTime);
    setCurrHoursPlusThree(formattedTime);

    console.log("time", timeInput);

    checkCurrentTimeWithMinTimeTheSpa();
  }, []);
  const currentTimem = new Date(`${dateInput}T${timeInput}`);
  const dubaiTime = currentTimem.toLocaleString("en-US", {
    timeZone: "Asia/Dubai",
  });
  const dateObj = new Date(dubaiTime);
  const hoursD = (dateObj.getHours() + 3).toString().padStart(2, "0");
  const minutesD = (dateObj.getMinutes() + 1).toString().padStart(2, "0");
  const time = `${hoursD}:${minutesD}`;
  const [isOff, setIsOff] = useState(0);
  console.log("hoursD", hoursD);
  const days = [
    { Mon: "Monday" },
    { Tue: "Tuesday" },
    { Wed: "Wednesday" },
    { Thu: "Thursday" },
    { Fri: "Friday" },
    { Sat: "Saturday" },
    { Sun: "Sunday" },
  ];
  //  start_time;
  //  end_time;
  let start_time = "00:00:00";
  let end_time = "23:00:00";
  // let isOff;
  let is_all_day;
  let nameDay;
  // let day_name;

  const timeref = useRef(null);

  // console.log("timeInput", timeInput);
  const { t, i18n } = useTranslation();
  const lang = localStorage.getItem("lang");
  useEffect(() => {
    setLoadign(true);
    const getData = async () => {
      axios
        .get(
          `/${room_uuid}/spa/${params.spa_uuid}/add-service/${params.spa_id}`,
          {
            headers: {
              lang: lang,
            },
          }
        )
        .then((res) => {
          const data = res.data.data;
          // console.log("working_times", data?.spa?.working_times);
          setService(data.service);
          setWorking_times(data?.spa?.working_times);
          setSpaID(data.spaId);
          setTherapists(data.service.therapists);
          setSelectedOption(data.service?.extras[0]?.options[0].id);
          setSelectedPrice(
            data.service?.extras[0]?.options[0].discount_price
              ? data.service?.extras[0]?.options[0].discount_price
              : data.service?.extras[0]?.options[0].price
          );

          setPrice_expression(data.price_expression);

          const dayOfWeek = new Date(dateInput).toLocaleString("en-us", {
            weekday: "short",
          });

          if (data?.spa?.working_times?.length > 0) {
            nameDay = days?.find((dayObj) => dayOfWeek in dayObj)[dayOfWeek];
            const dayCur = data?.spa?.working_times?.filter((time) => {
              // console.log("time ****************", time);
              if (time?.working_day?.en_name == nameDay) {
                setDay_name(time?.working_day?.name);
                return time;
              }
            });

            console.log("dayCur[0]", nameDay);

            setIsOff(dayCur[0]?.is_off);
            if (dayCur[0]?.is_off == 0) {
              // console.log("start_timestart_time****************", isOff);
              is_all_day = dayCur[0]?.is_all_day;
              if (dayCur[0]?.is_all_day != 1) {
                start_time = dayCur[0]?.start_time;
                let hours_count =
                  dayCur[0]?.hours_count == 24 ? 23 : dayCur[0]?.hours_count;

                const [hours_, minutes_, seconds_] = dayCur[0]?.start_time
                  ?.split(":")
                  .map(Number);

                let totalHours = hours_ + hours_count;

                if (totalHours >= 24) {
                  totalHours = totalHours - 24;
                }

                end_time = `${String(totalHours).padStart(2, "0")}:${String(
                  minutes_
                ).padStart(2, "0")}:${String(seconds_).padStart(2, "0")}`;
                // timeref.current.max = end_time;
                // timeref.current.min = start_time;
              } else {
                start_time = "00:00:00";
                end_time = "23:00:00";
              }
              timeref.current.max = end_time;
              timeref.current.min = start_time;
            }
          }

          setLoadign(false);
          // console.log("MY", data);
          window.scrollTo({
            top: 0,
            left: 0,
          });
        });
    };

    getData();
  }, []);

  const handleDate = (e) => {
    const newDate = new Date();
    if (
      newDate.toLocaleDateString() ==
      new Date(e.target.value).toLocaleDateString()
    ) {
      let hours = (newDate.getHours() + 6).toString().padStart(2, "0");

      const minutes = newDate.getMinutes().toString().padStart(2, "0");
      const formattedTime = `${hours}:${minutes}`;
      timeref.current.setCustomValidity(``);
      setCurrHoursPlusThree(formattedTime);
      setTimeInput(formattedTime);
    }

    if (working_times?.length > 0) {
      const dayOfWeek = new Date(e.target.value).toLocaleString("en-us", {
        weekday: "short",
      });
      nameDay = days.find((dayObj) => dayOfWeek in dayObj)[dayOfWeek];

      // console.log("nameDay********** ", nameDay);
      const dayCur = working_times.filter((time) => {
        if (time.working_day.en_name == nameDay) {
          setDay_name(time?.working_day?.name);
          return time;
        }
      });
      // console.log("dayCur***********", dayCur[0]?.is_off);
      setIsOff(dayCur[0]?.is_off);
      is_all_day = dayCur[0]?.is_all_day;
      if (dayCur[0]?.is_off == 0) {
        start_time = dayCur[0]?.start_time;
        let hours_count =
          dayCur[0]?.hours_count == 24 ? 23 : dayCur[0]?.hours_count;

        const [hours_, minutes_, seconds_] = dayCur[0]?.start_time
          .split(":")
          .map(Number);

        let totalHours = hours_ + hours_count;

        if (totalHours >= 24) {
          totalHours = totalHours - 24;
        }

        end_time = `${String(totalHours).padStart(2, "0")}:${String(
          minutes_
        ).padStart(2, "0")}:${String(seconds_).padStart(2, "0")}`;
      } else {
        start_time = "00:00:00";
        end_time = "23:00:00";
      }

      timeref.current.max = end_time;
      timeref.current.min = start_time;
    }
    setDateInput(e.target.value);
    console.log(" timeref.current.max = end_time", end_time);
    console.log(" timeref.current.min = start_time", start_time);
    console.log(" timeref.current.value", timeref.current.value);
    const current_time = timeref.current.value;

    console.log(" current_time > end_time", current_time > end_time);
    if (current_time > end_time) {
      timeref.current.value = start_time;
      setTimeInput(start_time);
    }
  };
  // console.log("working_times", working_times);

  // console.log("selectedOption", selectedOption);
  // const therapists = [{ name: "Therapist 1" }, { name: "Therapist 2" }];

  // console.log("isOff", isOff);
  const handleBook = async (e) => {
    e.preventDefault();

    if (isOff == 0) {
      checkInputs();
      if (spaService.name != "" && !checkValEmail) {
        setBookLoadign(true);
        const booked = {
          name: spaService.name,
          email: spaService.email,
          phone: spaService.phone,
          date: dateInput,

          time: formatTimeFunction(timeInput),
          // therapist_id: therapist.id,
          spa_id: spaID,
          service_id: id,
          options: [selectedOption],
          // company_name: company.company_name,
          // company_id: company.company_id,
          // lat: "25.276987",
          // lng: "55.296249",
        };
        // console.log("SENDING", booked);
        // const anser = await spaSchema.validate(booked);
        axios
          .post(`/${room_uuid}/spa/book-services`, booked, {
            headers: {
              lang: lang,
            },
          })
          .then((res) => {
            setIsDisabled(false);

            setBookLoadign(false);
            console.log("res", res);
            if (res.data.status == false) {
              toast.current.show({
                severity: "error",
                summary: t("Error"),
                detail: t(`${res.data.message}`),
                life: 4000,
              });
            }
            if (res.data.status) {
              navigate(`/${room_uuid}/SpaOrderPlaced`, {
                state: { metadata: res.data.data, theme: true },
              });
              // console.log("COMPOLETE");
            }
          })
          .catch((err) => {
            setIsDisabled(false);
            setBookLoadign(false);
            console.log(err);
          });
        // navigate("/HCOrderPlaced");
      }
    } else {
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: `We are off on ${day_name} at ${timeInput}, please choose another time`,
        life: 5000,
      });
    }
  };
  // console.log("start_time", start_time);
  // console.log("end_time", end_time);

  const handleInvalid = (event, index) => {
    const min = timeref.current.min.split(":");
    const max = timeref.current.max.split(":");
    const hourStartTime = min[0];
    const formatStartTime =
      Number(hourStartTime) > 12
        ? `${hourStartTime - 12}:${min[1]} PM`
        : `${hourStartTime}:${min[1]} AM`;

    const hourEndTime = max[0];
    const formatEndTime =
      Number(hourEndTime) > 12
        ? `${hourEndTime - 12}:${max[1]} PM`
        : `${hourEndTime}:${max[1]} AM`;
    console.log("start_time", formatStartTime);
    console.log("end_time", formatEndTime);
    event.target.setCustomValidity(
      `The time must be between ${formatStartTime} and ${formatEndTime}`
    );
    // console.log("timeref value", timeref.current.value);
    // console.log("timeref min", timeref.current.min);
    // console.log("timeref max", timeref.current.max);
  };
  return (
    <div className={theme ? "food" : ""}>
      {theme ? <Title title={"Spa"} /> : <HeaderInner headerName={"Spa"} />}
      <main className="bg-white back-white">
        <Toast ref={toast} position="bottom-center" />

        {loadign ? (
          <SpaLoader />
        ) : (
          <>
            <div className="add-post-container add-cart mt-2">
              <div className="add-cart-post new spa gray ">
                <div className="add-cart-post-inner">
                  {/* <div
                className={
                  enableClass ? "animated-background bg-skimmer" : "d-none"
                }
              ></div> */}
                  <span
                    className="img-pnl navigate"
                    // onClick={() => {
                    //   handleShow();
                    //   setModalImage(Post1);
                    // }}
                  >
                    <img src={service ? service.image : Post} />
                  </span>
                  <div
                    className="cross-btn-red"
                    onClick={() =>
                      navigate(
                        theme
                          ? `/${room_uuid}/hotel-services/spa/${params.spa_uuid}`
                          : `/${room_uuid}/spa/${params.spa_uuid}`
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 23 23"
                    >
                      <path
                        id="Icon_ionic-ios-close-circle"
                        data-name="Icon ionic-ios-close-circle"
                        d="M14.875,3.375a11.5,11.5,0,1,0,11.5,11.5A11.5,11.5,0,0,0,14.875,3.375Zm2.914,15.663-2.914-2.914-2.914,2.914a.884.884,0,1,1-1.25-1.25l2.914-2.914-2.914-2.914a.884.884,0,0,1,1.25-1.25l2.914,2.914,2.914-2.914a.884.884,0,0,1,1.25,1.25l-2.914,2.914,2.914,2.914a.888.888,0,0,1,0,1.25A.878.878,0,0,1,17.789,19.038Z"
                        transform="translate(-3.375 -3.375)"
                        fill="#f11027"
                      ></path>{" "}
                    </svg>
                  </div>
                  <div
                    className="txt-pnl navigate"
                    // onClick={handleCartShow}
                    // onClick={() => navigate("/SpaAddService")}
                  >
                    <h2>{service ? service.name : "loading"}</h2>
                    <p></p>
                    <div className="flex-div align-item-center">
                      <Link
                        to={
                          theme
                            ? `/${room_uuid}/hotel-services/spa/${params.spa_uuid}`
                            : `/${room_uuid}/spa/${params.spa_uuid}`
                        }
                        className="reg-btn service"
                      >
                        {t("Change")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="description-text">
              <p>{service?.description && service.description}</p>
            </div>
            <div className="duration-div spa_form">
              <p className="title start">Duration</p>
              {service?.extras[0].options &&
                service.extras[0].options.map(
                  ({ id, name, price, discount_price }) => {
                    return (
                      <Row className="px-3" key={id}>
                        <Col xs="6">
                          <p className="time">{name}</p>
                        </Col>
                        <Col xs="6" className="d-flex justify-content-end">
                          <div className="check-div">
                            <Form.Check
                              type={"radio"}
                              id={id}
                              name={currency}
                              // value={selectedOption}
                              checked={id == selectedOption}
                              onClick={() => {
                                setSelectedOption(id);

                                setSelectedPrice(
                                  discount_price != 0 ? discount_price : price
                                );

                                ReactGA.event({
                                  category: "Select option in SPA",
                                  action: "Click",
                                  value: `option: ${name}, price: ${
                                    discount_price != 0 ? discount_price : price
                                  }`,
                                });
                              }}
                              label={
                                <span
                                  className="d-flex"
                                  style={{ minWidth: "125px" }}
                                >
                                  {discount_price != 0 && (
                                    <span className="spa_discount">
                                      {currency} {price}
                                    </span>
                                  )}
                                  {`${currency} ${
                                    discount_price != 0 ? discount_price : price
                                  }`}
                                </span>
                              }
                              // reverse
                            />
                          </div>
                        </Col>
                      </Row>
                    );
                  }
                )}

              <Row>
                <Col xs="6">
                  <p className="title"> {t("Order Total")}</p>
                </Col>
                <Col xs="6" className="d-flex justify-content-end">
                  <p className="title">
                    {currency} {selectedPrice}
                  </p>
                </Col>
              </Row>
            </div>
          </>
        )}
        <form onSubmit={handleBook}>
          <div className="padd-div spa-inputs">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                // value={`Room number ${}`}
                value={`${t("Room number")} ${room_number}`}
                // placeholder={`Room number ${room_number}`}
                readonly
                disabled={true}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicname">
              <Form.Control
                type="text"
                placeholder={`${t("Your Name")}`}
                name="name"
                value={spaService.name}
                onChange={handleInputChange}
              />
            </Form.Group>
            {errName != "" && <span className="err_msg">{errName}</span>}
            <Form.Group className="mb-3" controlId="formBasicname">
              <Form.Control
                type="emial"
                placeholder={`${t("Your Email (Optional)")}`}
                name="email"
                value={spaService.email}
                onChange={handleInputChange}
              />
            </Form.Group>
            {errorEmail != "" && <span className="err_msg">{errorEmail}</span>}
            <Row>
              <Col xs="6">
                <Form.Group className="mb-3" controlId="formBasicname">
                  <Form.Control
                    type="date"
                    value={dateInput}
                    min={new Date().toISOString().slice(0, 10)}
                    onChange={handleDate}
                    defaultValue={dateInput}
                  />
                </Form.Group>
              </Col>
              <Col xs="6">
                <Form.Group className="mb-3" controlId="formBasicname">
                  <Form.Control
                    type="time"
                    value={timeInput}
                    ref={timeref}
                    min={start_time}
                    max={end_time}
                    onInvalid={handleInvalid}
                    required
                    onChange={handleTime}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Form.Group className="mb-3 w-100">
              <PhoneInput
                country={"ae"}
                value={spaService.phone}
                onChange={(phone) => {
                  setSpaService((prev) => ({ ...prev, phone }));
                }}
              />
            </Form.Group>
          </div>
          <div className="spacer-40 mt-3"></div>
          <div className="padd-div text-center ">
            <Button
              type="submit"
              className="reg-btn order fw-normal"
              // onClick={handleBook}
              disabled={bookLoadign}
            >
              {t("Book")}
            </Button>
          </div>
        </form>
        <div className="padd-div text-center">
          <h3 className="text-center">
            <TaxInclusive />
          </h3>
        </div>
        <div className="spacer-40 my-5"></div>
      </main>
      {theme && <NewFooter />}
    </div>
  );
}
export default SpaAddService;
