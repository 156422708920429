import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const StationaryButton = ({
  item,
  setService,
  service,
  countSer,
  setCountSer,
}) => {
  const [count, setCount] = useState(0);
  const { t, i18n } = useTranslation();
  const toggleService = (input, name, count) => {
    if (service.includes(input)) {
      setService((prev) => {
        return prev.filter((x) => x !== input);
      });
    } else {
      setService((prev) => [...prev, input]);
    }
    console.log(service);
    console.log("countSer", countSer);
  };
  const handelCount = (id, seCount) => {
    setCount(seCount);
    if (seCount < 0) {
      setCount(0);
    }
    if (seCount > 0) {
      if (!service.includes(id)) {
        setService((prev) => [...prev, id]);
      }
    }
    setCountSer((prev) => ({
      ...prev,
      [id]: seCount,
    }));
  };
  return (
    <Button
      className={
        service.includes(item.id)
          ? `reg-btn outline-btn dark d-flex `
          : "reg-btn outline-btn d-flex  "
      }
      style={{ justifyContent: "space-between " }}
    >
      <span
        className="sta-span"
        onClick={() => toggleService(item.id, item.name, item.count)}
      >
        {t(`${item.name}`)}
      </span>

      <div className="meet_price">
        <span className="dec" onClick={() => handelCount(item.id, count - 1)}>
          -
        </span>
        <span className="cou">{count}</span>
        <span className="inc" onClick={() => handelCount(item.id, count + 1)}>
          +
        </span>
      </div>
    </Button>
  );
};

export default StationaryButton;
