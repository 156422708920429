import React, { useEffect, useRef, useState } from "react";
import HeaderInner from "../../Components/HeaderInner";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../axios";
import SpaLoader from "../../Components/Loaders/SpaLoader";
import Iframe from "react-iframe";
import Title from "../../components/title/Title";
import NewFooter from "../../NewFooter";

function Taxi() {
  const navigate = useNavigate();
  const [loading, setLaoding] = useState(false);

  const [dataInfo, setDataInfo] = useState(null);

  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const new_design = localStorage.getItem("new_design");
  const theme = new_design == "true";

  useEffect(() => {
    const getData = async () => {
      setLaoding(true);
      axios
        .get(`/${room_uuid}/location`)
        .then((res) => {
          if (res.data.status === false) {
            navigate(`/${room_uuid}/qr-scan`);
          } else {
            const data = res.data.data;
            setDataInfo(res.data.data);
            setLaoding(false);
          }
        })
        .catch((err) => {
          if (err?.response.status == 404) {
            navigate(`/not-found`);
          }
        });
    };
    getData();
  }, []);

  // <HeaderInner headerName={"Taxi booking"} />
  return (
    <div className={theme ? "food" : ""}>
      {theme ? (
        <Title title={"Taxi booking"} />
      ) : (
        <HeaderInner headerName={"Taxi booking"} />
      )}
      <main className="home-main bg-white back-white book-table pad-top-0 taxi_book">
        {loading ? (
          <SpaLoader />
        ) : (
          <Iframe
            url={`https://dtcbooking.mobilityae.com/signin#/partner/660a628c9b2a1234a4f340ad?room=${dataInfo?.uuid}&lat=${dataInfo?.lat}&lng=${dataInfo?.long}`}
            // url="https://www.wikipedia.org/"
            width="100%"
            // height="80vh"
            id=""
            className="iframe_layar"
            display="block"
            // position="relative"
          />
        )}
      </main>
      {theme && <NewFooter />}
    </div>
  );
}
export default Taxi;
