import React from "react";
import MillAR from "../assets/images/MillAR.jpg";
import MillEN from "../assets/images/MillEN.jpg";
import { t } from "i18next";
const MillenniumTaxi = () => {
  const lang = localStorage.getItem("lang");
  return (
    <div className="hh-taxi">
      <div>
        <a href="https://onelink.to/d8kx7v">
          <img src={lang == "AR" ? MillAR : MillEN} alt="" width={"100"} />
        </a>
      </div>
      <h6>{t("tax_inclusive")}</h6>
      <h6>{t("download_app")}</h6>
    </div>
  );
};

export default MillenniumTaxi;
