import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Form, Modal } from "react-bootstrap";
import Post from "../assets/images/food.png";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Image from "react-bootstrap/Image";
import { useCartStore } from "../store/useStore";
import { addToCart } from "../utils/cartUtils";
import axios from "../axios";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { Zoom } from "react-reveal";
import { object } from "yup";
import ReactGA from "react-ga4";

function AddCartPost({
  item,
  restaurantId,
  isOffCategory,
  restaurant_is_closed,
  isbooking,
  currency,
  selected,
  restaurantInfo,
}) {
  const new_design = localStorage.getItem("new_design");
  const theme = new_design == "true";
  // console.log("isOffCategory", restaurant_is_closed);
  // console.log("restaurant_is_closed", !isOffCategory || !restaurant_is_closed);
  let res = false;
  if (isOffCategory) {
    res = true;
  } else if (restaurant_is_closed) {
    res = true;
  } else if (isbooking) {
    res = true;
  } else {
    res = false;
  }
  // console.log("res", res);
  // const [enableClass, setEnableClass] = useState(true);
  const [show, setShow] = useState(false);
  const [count, setCount] = useState(1);
  const [showCart, setShowCart] = useState(false);
  const [loading, setLoading] = useState(false);
  const [extras, setExtras] = useState([]);
  const [priceOption, setPriceOption] = useState(0);

  // const isRequired = item?.extrasWithOptions[0]?.is_required;
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  // console.log("itemitemitem", item);
  const queryClient = useQueryClient();

  const { cart, setCart, cartCount, setCartCount } = useCartStore((state) => ({
    cart: state.cart,
    setCart: state.setCart,
    cartCount: state.cartCount,
    setCartCount: state.setCartCount,
  }));

  // setTimeout(() => {
  //   setEnableClass(false);
  // }, 3000);
  // const currency = localStorage.getItem("currency");
  const { t, i18n } = useTranslation();
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
    ReactGA.event({
      category: "Choose an item from the restaurant ",
      action: "Click",
      value: `item name: ${item?.name}}`,
    });
    console.log(
      "item?.extrasWithOptions?.length > 0",
      item?.extrasWithOptions?.length > 0
    );
  };

  useEffect(() => {
    const checkboxes = document.getElementsByClassName("checkboxes");
    console.log("checkboxes");
    Object.values(checkboxes).map((check) => {
      console.log("check", check.children[0].required);
      if (check.children[0].required == true) {
        check.children[0].setCustomValidity("Please select 1 option at least");
      }
    });
  }, []);

  useEffect(() => {
    console.log("item?.extrasWithOptions?.length > 0");
  }, [show]);

  const handleCartClose = () => {
    setShowCart(false);
    setCount(1);
    setExtras([]);
  };
  const handleCartShow = () => setShowCart(true);
  const handleIncrease = () => {
    setCount((prev) => prev + 1);
  };
  const handleDecrease = () => {
    setCount((prev) => {
      if (prev <= 1) {
        return 1;
      }
      return prev - 1;
    });
  };

  const lang = localStorage.getItem("lang");
  console.log("languahg", lang);
  // const SetCartItem = (item) => {
  //   const tempCart = [...JSON.parse(JSON.stringify(cart)), item];
  //   setCart(tempCart);
  // };
  // const [myObject, setMyObject] = useState([]);
  // const obje = [];
  const formData = new FormData();
  // const setExtramm = (id, idx) => {
  //   formData.append(`extras[${idx}]`, id);
  //   // setMyObject(...myObject, id);
  //   // console.log("myObjmmdmdmect", id);
  // };
  formData.append("item_id", item.id);
  formData.append("quantity", count);
  formData.append("restaurant_id", parseInt(restaurantId));
  //   useEffect(() => {
  //     const input_ch = document.getElementsByClassName("input_ch");
  // console.log("item?.extrasWithOptions?.length", item?.extrasWithOptions?.length);
  //   }, []);
  const [optionsIds, setOptionsIds] = useState({});

  const handleInvalid = (event, index) => {
    event.target.setCustomValidity("Please select 1 option at least");
  };
  const handleHandleOptionsChanges = (
    event,
    optionName,
    optionId,
    type,
    price,
    idx,
    max_op
  ) => {
    event.target.setCustomValidity("");
    // console.log("max_op", max_op);

    if (type == "radio") {
      // if (state == "remove") {
      //   const { [optionName]: _, ...rest } = optionsIds;

      //   setOptionsIds(rest);
      // } else {
      setOptionsIds((prev) => ({
        ...prev,
        [optionName]: optionId,
      }));
      // }
    } else {
      const idex = document.getElementById(`idx${idx}`);
      var inputNodes = idex.getElementsByTagName("INPUT");
      var inputLang = idex.querySelectorAll(
        'input[type="checkbox"]:checked'
      ).length;
      // console.log("inputNodes", inputNodes);
      Object.values(inputNodes).map((inp) => {
        if (inputLang >= 1) {
          inp.required = false;
          inp.setCustomValidity("");
        } else if (inputLang == 0) {
          inp.required = true;
          inp.setCustomValidity("Please select 1 option at least");
        }
        if (inputLang == max_op) {
          // console.log("inputLang", inputLang);
          if (inp.checked == false) {
            inp.disabled = true;
          }
        } else {
          if (inp.checked == false) {
            inp.disabled = false;
          }
        }
      });

      // Check if the object is present in the array
      const index = extras.findIndex(
        (obj) => JSON.stringify(obj) === JSON.stringify(optionId)
      );

      if (index !== -1) {
        // If found, remove the object from the array
        extras.splice(index, 1);
        setPriceOption(priceOption - Number(price * count));
      } else {
        // If not found, add the object to the array
        extras.push(optionId);
        setPriceOption(priceOption + Number(price * count));
      }
      console.log("extrasextras", extras);
      // if (extras.includes(optionId)) {

      //   const tempExtras = JSON.parse(JSON.stringify(extras));
      //   console.log("tempExtras", tempExtras);
      //   const filtered = tempExtras.filter((temp) => {
      //     return temp !== optionId;
      //   });
      //   setExtras(filtered);
      //   console.log("filtered", filtered);
      // } else {
      //   setPriceOption(priceOption + Number(price));

      //   setExtras((prev) => [optionId, ...prev]);
      // }
    }
  };

  // console.log("price", priceOption);
  // console.log("optionsIds", Object.values(optionsIds));
  // useEffect(() => console.log("optionsIds", optionsIds, extras), [optionsIds]);

  // const objectOpt = [Object.values(optionsIds)]
  // console.log();
  let text_extras = [];
  const addToCartV2 = (e) => {
    const optionsArray = [...Object.values(optionsIds), ...extras];
    const formatArray = optionsArray.map((option) => {
      return {
        extra_id: option.extra_id,
        option_id: option.id,
        price: option.price,
      };
    });
    // console.log("...Object.values(optionsIds), ...extras]", formatArray);
    e.preventDefault();

    let extraText = document.getElementsByClassName("ex_class");
    if (extraText.length > 0) {
      Object.values(extraText).map((item) => {
        if (item.value != "") {
          text_extras.push({ extra_id: item.id, text: item.value });
        }
      });
    }
    setLoading(true);
    if (text_extras.length > 0) {
      axios
        .post(
          `/${room_uuid}/restaurant/order/order-item`,
          {
            restaurant_id: parseInt(restaurantId),
            item_id: item.id,
            quantity: count,
            extras: formatArray,
            text_extras: text_extras,
          },
          {
            headers: {
              lang: lang,
            },
          }
        )
        .then((res) => {
          setLoading(false);

          queryClient.invalidateQueries("checkout");
          queryClient.invalidateQueries(
            `restaurant_submenu-${lang}-${restaurantInfo?.uuid}-${selected}`
          );
          const data = res.data.data;
          handleCartClose();
          setCartCount(cartCount + 1);

          console.log(res);
        });
    } else {
      axios
        .post(
          `/${room_uuid}/restaurant/order/order-item`,
          {
            restaurant_id: parseInt(restaurantId),
            item_id: item.id,
            quantity: count,
            extras: formatArray,
          },
          {
            headers: {
              lang: lang,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          queryClient.invalidateQueries(
            `restaurant_submenu-${lang}-${restaurantInfo?.uuid}-${selected}`
          );
          queryClient.invalidateQueries("checkout");
          const data = res.data.data;
          handleCartClose();
          setCartCount(cartCount + 1);

          console.log(res);
        });
    }
  };
  const setExtra = (name, type) => {
    console.log(extras, type);

    // if (extras.length <= 2) {
    console.log("name");
    if (type == "radio") {
      setExtras([name]);
    } else {
      if (extras.includes(name)) {
        const tempExtras = JSON.parse(JSON.stringify(extras));
        const filtered = tempExtras.filter((temp) => {
          return temp !== name;
          console.log("filtered", filtered);
        });
        setExtras(filtered);
      } else {
        setExtras((prev) => [name, ...prev]);
      }
    }

    // }
  };
  let newpice = 0;
  extras?.map((ext) => {
    newpice = newpice + ext.price;
  });
  newpice = newpice * count;

  const orderWithCount = (count, type) => {
    let newCount = count;
    if (type == "plus") {
      newCount = 1;
    }
    if (type == "minus") {
      if (count == 0) {
        newCount = 0;
      } else {
        newCount = -1;
      }
    }
    axios
      .post(`/${room_uuid}/restaurant/order/order-item`, {
        restaurant_id: parseInt(restaurantId),
        item_id: item.id,
        quantity: newCount,
      })
      .then((res) => {
        setLoading(false);
        queryClient.invalidateQueries(
          `restaurant_submenu-${lang}-${restaurantInfo?.uuid}-${selected}`
        );
        queryClient.invalidateQueries("checkout");
        const data = res.data.data;
        handleCartClose();
        setCartCount(cartCount + 1);

        console.log(res);
      });

    console.log("newCount", newCount);
  };

  return (
    <>
      {item ? (
        <Zoom>
          <div className="add-cart-post new">
            <div className="add-cart-post-inner">
              <div className={"d-none"}></div>
              <div
                onClick={handleShow}
                className="img-pnl position-relative navigate cover animated-background"
                // className="img-pnl animated-background"
              >
                <Image src={encodeURI(item.image)} alt="Post" />
              </div>
              <div className="txt-pnl navigate">
                <div onClick={handleCartShow}>
                  <h2

                  //    className="animated-background"
                  >
                    {lang == "EN" ? item?.display_name : item?.name}
                  </h2>
                  {item.restaurant_name && (
                    <h6 className="s_title">{item.restaurant_name}</h6>
                  )}

                  {item.description?.length > 0 && (
                    <p
                    // className="animated-background"
                    >
                      {item.description}
                    </p>
                  )}
                </div>
                <div className="flex-div">
                  <h3 className={`${item.restaurant_name ? "" : "mt7"}`}>
                    {restaurantInfo?.show_prices == 1 ? (
                      item.price == "0.00" ? (
                        <span style={{ fontSize: "10px" }}>
                          {t("Price upon selection")}
                        </span>
                      ) : (
                        `${currency || "AED"} ${item.price}`
                      )
                    ) : null}
                  </h3>
                  {!res &&
                    (item?.in_cart ? (
                      <div
                        className="count-order-amount in_cart"
                        onClick={handleCartShow}
                      >
                        <Button className="small">
                          <i className="fa fa-minus"></i>
                        </Button>
                        <span>{item.in_cart_count}</span>
                        <Button className=" small">
                          <i className="fa fa-plus"></i>
                        </Button>
                      </div>
                    ) : (
                      <Button
                        className={`add-button ${lang}`}
                        onClick={handleCartShow}
                      >
                        {t("Add To Cart")}
                      </Button>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </Zoom>
      ) : null}

      {item ? (
        <Modal
          show={show}
          // style={{ maxWidth: "430px" }}
          id={"restaurant-order-modal"}
          className={`custom-width img p-2 ${theme ? "food-modal" : ""}`}
          onHide={handleClose}
        >
          <Button className="close-btn img" onClick={handleClose}>
            <div className="close-btn-wrap"></div>
            <AiOutlineCloseCircle />
          </Button>
          <Modal.Body style={{ padding: "20px" }}>
            <div className="modal-img-container">
              <Image src={item.image} alt="Post" />
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <Modal
          show={show}
          // style={{ maxWidth: "430px" }}
          id={"restaurant-order-modal"}
          className={`custom-width img ${theme ? "food-modal" : ""}`}
          onHide={handleClose}
        >
          <Button className="close-btn img" onClick={handleClose}>
            <div className="close-btn-wrap"></div>
            <AiOutlineCloseCircle />
          </Button>
          <Modal.Body style={{ padding: "7px" }}>
            <div className="modal-img-container">
              <Image
                src={
                  "https://tdhbucket.s3.me-central-1.amazonaws.com/restaurant_items/item1.png"
                }
                alt="Post"
              />
            </div>
          </Modal.Body>
        </Modal>
      )}
      {item ? (
        <Modal
          show={showCart}
          className={`custom-width ${theme ? "food-modal" : ""}`}
          onHide={handleCartClose}
        >
          <Button className="close-btn" onClick={handleCartClose}>
            {/* <i className="fa fa-times-circle-o"></i> */}
            <div className="close-btn-wrap"></div>
            <AiOutlineCloseCircle />
          </Button>
          <Modal.Body>
            <div className="modal-img-container">
              <Image src={item.image} alt="Post" />
            </div>
            <div className="modal-text-container sub-menu">
              <h2 className="text-1">
                {lang == "EN" ? item?.display_name : item?.name}
              </h2>
              <p className="text-2">
                {item.description ? item.description : ""}
              </p>
              <div className="price-container">
                <p>
                  {restaurantInfo?.show_prices == 1 ? (
                    item.price == "0.00" ? (
                      <span style={{ fontSize: "10px" }}>
                        {t("Price upon selection")}
                      </span>
                    ) : (
                      `${currency || "AED"} ${item.price}`
                    )
                  ) : null}
                </p>
                {!res && (
                  <div className="count-order-amount">
                    <Button className="small" onClick={handleDecrease}>
                      <i className="fa fa-minus"></i>
                    </Button>
                    <p>{count}</p>
                    <Button className=" small" onClick={handleIncrease}>
                      <i className="fa fa-plus"></i>
                    </Button>
                  </div>
                )}
              </div>
              {/* <div className="d-flex justify-content-center cart-btn-container">
              <Button className="reg-btn small w-100">Add To Cart</Button>
            </div> */}
              <form action="" onSubmit={addToCartV2}>
                {item?.extrasWithOptions?.length > 0
                  ? item?.extrasWithOptions.map((opti, idx) => {
                      return (
                        <>
                          <div className="extras-heading" key={idx}>
                            <p className="text">{opti.name}</p>
                            <p className="required">
                              {" "}
                              {opti?.is_required ? t("Required") : ""}
                            </p>
                          </div>
                          {opti.extra_type_name == "text" && (
                            <div className="extra_input">
                              <input
                                type="text"
                                id={opti.extra_id}
                                className="ex_class"
                              />
                            </div>
                          )}
                          <div className="extras-items" id={`idx${idx}`}>
                            {opti?.option
                              ? opti?.option.map((extra, index) => {
                                  return (
                                    <div className="extras-item" id={index}>
                                      <p className="item-text">{extra.name}</p>
                                      <div className="res_options" key={index}>
                                        {extra?.option_has_price && (
                                          <p>{`+ ${extra.currency} ${extra.price}`}</p>
                                        )}

                                        {opti.extra_type_name == "radio" ? (
                                          <Form.Check
                                            type={opti.extra_type_name}
                                            name={opti.name}
                                            id={opti.name}
                                            className="item-check"
                                            required={opti.is_required}
                                            // disabled={
                                            //   extras.length >= 2 &&
                                            //   !extras.includes(extra.name)
                                            // }
                                            onChange={(event) =>
                                              handleHandleOptionsChanges(
                                                event,
                                                opti.name,
                                                (extra = {
                                                  ...extra,
                                                  extra_id: opti.extra_id,
                                                }),
                                                opti.extra_type_name,
                                                extra.price
                                              )
                                            }
                                            aria-label="radio 1"
                                          />
                                        ) : (
                                          <Form.Check
                                            type={opti.extra_type_name}
                                            name={extra.name}
                                            id={extra.name}
                                            className="item-check"
                                            required={
                                              index == 0
                                                ? opti.is_required
                                                : false
                                            }
                                            onInvalid={handleInvalid}
                                            onChange={(event) =>
                                              handleHandleOptionsChanges(
                                                event,
                                                opti.name,
                                                (extra = {
                                                  ...extra,
                                                  extra_id: opti.extra_id,
                                                }),
                                                opti.extra_type_name,
                                                extra.price,
                                                idx,
                                                opti.max_options
                                              )
                                            }
                                            aria-label="radio 1"
                                          />
                                        )}
                                      </div>
                                    </div>
                                  );
                                })
                              : null}
                          </div>
                        </>
                      );
                    })
                  : null}
                <div className="full-div mt-4 modal-add-cart">
                  {!res && (
                    <Button
                      type="submit"
                      // onClick={addToCartV2}
                      className="reg-btn big w-100 flex-div"
                      disabled={loading}
                    >
                      <div className="plus-container">
                        <i className="fa fa-plus-square"></i>{" "}
                        <span>{t("Add To Cart")}</span>
                      </div>
                      <div>
                        {restaurantInfo?.show_prices == 1 ? (
                          item.price == "0.00" && priceOption == 0 ? (
                            <span style={{ fontSize: "12px" }}>
                              {t("Price upon selection")}
                            </span>
                          ) : (
                            `${currency || "AED"} ${parseFloat(
                              item.price * count + newpice
                            ).toFixed(2)}`
                          )
                        ) : null}
                      </div>
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  );
}
export default AddCartPost;
