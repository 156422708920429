import React from "react";
import HeaderInner from "../../Components/HeaderInner";
import Textheader from "../../Components/Textheader";
import Clientinfo from "../../Components/Clientinfo";
import Post1 from "../../assets/images/Entertainment/Entertainment-1.png";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
function MaintenanceOrderPlaced() {
  const location = useLocation();
  const rawPath = location.pathname;
  const room_uuid = rawPath.split("/")[1];
  const data = location.state?.metadata;
  const { t, i18n } = useTranslation();
  // console.log("location.state?.metadata", location.state?.metadata);
  const currency = localStorage.getItem("currency");

  return (
    <>
      <HeaderInner />
      <main className="bg-white pad-top-0 back-white">
        <Textheader />
        <Clientinfo
          roomNumber={data?.res?.roomNumber || data?.roomNumber}
          orderNumber={data?.res?.orderNumber || data?.orderNumber}
          orderedAt={data?.res?.orderedAt || data?.orderedAt}
        />

        <div className="full-heading grey-bg  flex">
          <h2 className="blck co-header">{t("Order Details")}</h2>
        </div>
        <div className="full-div padd-div">
          <div className="booking-order-detail">
            <div className="ma_images">
              {data?.res?.images.map((image, idx) => {
                return <img key={idx} src={image} alt="" />;
              })}
            </div>
            <div className="txt-pnl">
              <p>{data?.res?.note || data?.note}</p>
            </div>
          </div>
        </div>
        <div className="spacer-20"></div>
        <div className="full-div padd-div text-center">
          <Link className="reg-btn big" to={`/${room_uuid}/qr-code`}>
            {t("Back to Home")}
          </Link>
        </div>
      </main>
    </>
  );
}
export default MaintenanceOrderPlaced;
