import axios from "axios";
import { API_URL } from "../constants/api";
import { useQuery } from "react-query";

const APIURL = axios.create({
  baseURL: API_URL,
});

const RestaurantBooking = async (uuid, lang) => {
  return await APIURL.get(`/${uuid}/restaurants?type=booking`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      lang: lang,
    },
  });
};
export const ShowRestaurantBooking = (uuid, lang) => {
  return useQuery(`restaurant_booking-${lang}`, () =>
    RestaurantBooking(uuid, lang)
  );
};
