// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCA4FF3aU3GvrAgGwtkBYGi66xBkgQpGE4",
  authDomain: "project-not.firebaseapp.com",
  projectId: "project-not",
  storageBucket: "project-not.appspot.com",
  messagingSenderId: "671123466164",
  appId: "1:671123466164:web:e1c5138a946185e505b4e1",
  measurementId: "G-1BB3RMZ73E",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestForToken = () => {
  Notification.requestPermission().then((permission) => {
    if (permission == "granted") {
      return getToken(messaging, {
        vapidKey:
          "BDZmExHhAxUnl-AxggXIXjjBYoZX3L7c5JlskwpcRxNOD_LLwCLW4trdkuzDl1burdUdL1-DhwO42H7QSETb10E",
      })
        .then((currentToken) => {
          if (currentToken) {
            console.log("current token for client: ", currentToken);
            // Perform any other neccessary action with the token
          } else {
            // Show permission request UI
            console.log(
              "No registration token available. Request permission to generate one."
            );
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
        });
    }
  });
};
// export const requestForToken = () => {

// };

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });
