import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import SpaCarosle from "../../Components/SpaCarosle";
import axios from "../../axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Fimage from "../../assets/images/feedback.jpg";
import ReactGA from "react-ga4";
import Title from "../../components/title/Title";
import NewFooter from "../../NewFooter";

function Feedback() {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const rawPath = location.pathname;
  let room_uuid = rawPath.split("/")[1];
  const new_design = localStorage.getItem("new_design");
  const theme = new_design == "true";
  const lang = localStorage.getItem("lang");

  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorEmail, setErrorEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  const checkValEmail = email.length > 0 && !/\S+@\S+\.\S+/.test(email);

  const checkInputs = () => {
    if (note == "") {
      setErrorMsg("The message field is required.");
    } else {
      setErrorMsg("");
    }
    if (email.length > 0 && !/\S+@\S+\.\S+/.test(email)) {
      setErrorEmail("The email must be a valid email address.");
    } else {
      setErrorEmail("");
    }
  };
  const dataForm = {
    name: name,
    email: email,
    phone: phone,
    message: note,
  };

  const handelBook = () => {
    checkInputs();
    if (note != "" && !checkValEmail) {
      setLoading(true);
      axios
        .post(`/${room_uuid}/feedback/send-feedback`, dataForm, {
          headers: {
            lang: lang,
          },
        })
        .then((res) => {
          console.log("res", res);

          if (res.data.status == true) {
            toast.success(t(`${res.data.data.message}`));
            ReactGA.event({
              category: "Send Feedback",
              action: "Click",
              value: ``,
            });
            navigate(`/${room_uuid}/qr-code`);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
        });
    }
  };
  return (
    <div className={theme ? "food" : ""}>
      {theme ? (
        <Title title={"Send a Feedback"} />
      ) : (
        <HeaderInner headerName={"Send a Feedback"} />
      )}
      <main className="home-main pad-top-0 back-white">
        <SpaCarosle
          noDots={true}
          autoPlay={true}
          isFeedback={true}
          img={[Fimage]}
        />
        <p className="title">{t("Waiting Your Feedback")} </p>
        <div className="input-pnl px-3">
          <Row className="justify-content-center">
            <Col xs="12">
              <Form.Group className="mb-3" controlId="formBasicname">
                <Form.Control
                  type="text"
                  placeholder={t("Your Name (Optional)")}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col xs="12">
              <Form.Group className="mb-3" controlId="formBasicname">
                <Form.Control
                  type="email"
                  placeholder={t("Your Email (Optional)")}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              {errorEmail != "" && (
                <span className="err_msg">{errorEmail}</span>
              )}
            </Col>
          </Row>
          <Form.Group className="mb-3 w-100">
            <PhoneInput
              country={"ae"}
              value={phone}
              onChange={(phone) => setPhone(phone)}
            />
          </Form.Group>
          <Form.Group className="mb-3 w-100">
            <Form.Control
              as="textarea"
              placeholder={t("Your Message")}
              style={{ height: "106px" }}
              onChange={(e) => setNote(e.target.value)}
            />
          </Form.Group>
          {errorMsg != "" && <span className="err_msg">{errorMsg}</span>}
        </div>

        <div className="padd-div text-center mb-2 ">
          <Button
            className="reg-btn order-btn"
            onClick={handelBook}
            disabled={loading}
          >
            {t("Send")}
          </Button>
        </div>
      </main>
      {theme && <NewFooter />}
    </div>
  );
}
export default Feedback;
