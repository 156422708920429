import React, { useEffect } from "react";
import Title from "../../components/title/Title";
import { Link, useNavigate, useParams } from "react-router-dom";
import InRoom from "../../assets/new_imgs/foods/Group 38663.png";
import Booking from "../../assets/new_imgs/foods/Group 38664.png";
import One from "../../assets/new_imgs/foods/Group 38665.png";
import Tow from "../../assets/new_imgs/foods/Group 38666.png";
import Overllay from "../../assets/new_imgs/Rectangle 1072.png";
import NewFooter from "../../NewFooter";
import "./FoodAndBeverages.scss";
import { ShowRestaurantBooking } from "../../API/RestaurantBookingAPI";
import NewLoader from "../../components/title/Loader/NewLoader";
import { ShowRoomDining } from "../../API/In-Room-DiningAPI";

const InRoomDining = () => {
  const params = useParams();
  const lang = localStorage.getItem("lang");
  const navigate = useNavigate();
  let restaurants;
  let menu;
  let color;
  let bgColor;
  let text;
  const { data, isLoading, error } = ShowRoomDining(params.room_uuid, lang);
  if (!isLoading) {
    if (data.data.status === false) {
      // navigate(`/${room_uuid}/qr-scan`);
    } else {
      menu = data.data.data;
      restaurants = menu?.restaurants;
      color = menu?.color;
      bgColor = menu?.header_color;
      text = menu?.center_header;
      // console.log("restaurants", restaurants);

      if (restaurants?.length == 1) {
        navigate(
          `/${params.room_uuid}/food-and-beverages/in-room-dining/${restaurants[0].uuid}`,
          { replace: true }
        );
      }
    }
  }
  if (!isLoading) {
    console.log("restaurants", restaurants);
  }
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);

  return (
    <div className={`food ${lang == "AR" && "arabic"}`}>
      {isLoading ? (
        <NewLoader />
      ) : (
        <>
          <Title title={text} />

          <div className="box_food">
            {restaurants?.length > 1 &&
              restaurants?.map((item, idx) => {
                return (
                  <Link
                    key={idx}
                    to={`/${params.room_uuid}/food-and-beverages/in-room-dining/${item.uuid}`}
                  >
                    <img src={item?.image} alt="" className="booking_img" />
                    <img src={Overllay} alt="" className="overlay" />
                    <div className="text_food">
                      <h5 className="">{item.name}</h5>
                      <p>
                        {item?.cuisines?.map((cuisine, idx) => {
                          if (idx + 1 < item?.cuisines.length) {
                            return `${cuisine.name}  |  `;
                          } else {
                            return `${cuisine.name}  `;
                          }
                        })}
                      </p>
                    </div>{" "}
                  </Link>
                );
              })}

            {/* <Link
              to={`/${params.room_uuid}/food-and-beverages/restaurant-booking`}
            >
              <img src={Booking} alt="" className="booking_img" />
              <img src={Overllay} alt="" className="overlay" />
              <div className="text_food">
                <h5 className="">Restaurant Booking</h5>
                <p>Light Bites</p>
              </div>{" "}
            </Link>
            <Link
              to={`/${params.room_uuid}/food-and-beverages/restaurant-booking`}
            >
              <img src={One} alt="" className="booking_img" />
              <img src={Overllay} alt="" className="overlay" />
              <div className="text_food">
                <h5 className="">Restaurant Booking</h5>
                <p>Light Bites</p>
              </div>{" "}
            </Link>
            <Link
              to={`/${params.room_uuid}/food-and-beverages/restaurant-booking`}
            >
              <img src={Tow} alt="" className="booking_img" />
              <img src={Overllay} alt="" className="overlay" />
              <div className="text_food">
                <h5 className="">Restaurant Booking</h5>
                <p>Light Bites</p>
              </div>
            </Link> */}
          </div>
          <NewFooter />
        </>
      )}
    </div>
  );
};

export default InRoomDining;
