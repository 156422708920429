import React, { useState } from "react";
import { Link } from "react-router-dom";
import HeaderInner from "../../Components/HeaderInner";
import PaymentOptionList from "../../Components/PaymentOptionList";
import { Form, Button } from "react-bootstrap";
import Post from "../../assets/images/Pharmacy/Medicine.png";
function SupermarketOrderPayment() {
  const [enableClass, setEnableClass] = useState(true);
  setTimeout(() => {
    setEnableClass(false);
  }, 3000);
  return (
    <>
      <HeaderInner />
      <main className="bg-white">
        <div className="add-post-container">
          <div className="add-cart-post">
            <div className="add-cart-post-inner">
              {" "}
              <div
                className={enableClass ? "animated-background bg-skimmer" : ""}
              ></div>
              <button className="post-delet-btn">
                <i className="fa fa-times-circle-o"></i>
              </button>
              <Link to="#" className="img-pnl">
                <img src={Post} alt="Post" />
              </Link>
              <div className="txt-pnl">
                <Link to="#">
                  <h2>Brufen Rapid 400Mg</h2>
                </Link>
                <p>
                  - 10 Purple Orchids
                  <br></br>- Wrapped in Pink Paper
                  <br></br>- Tied with Pink Ribbon
                </p>
                <div className="flex-div">
                  <h3>AED 32.25</h3>
                  <div>
                    <Button className="reg-btn small">Add To Cart</Button>
                  </div>
                  <div className="count-order-amount">
                    <Button className="small">
                      <i className="fa fa-minus"></i>
                    </Button>
                    <p>1</p>
                    <Button className=" small">
                      <i className="fa fa-plus"></i>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="full-div padd-div">
          <Link className="reg-btn big w-100 text-center" to="/">
            <i className="fa fa-plus-square"></i> Add More Items
          </Link>
        </div>
        <div className="voucher-panel">
          <h3>
            <i className="fa fa-tag"></i> voucher code
          </h3>
          <div className="d-flex">
            <Form.Control type="text" placeholder="" />
            <Button className="reg-btn small">apply</Button>
          </div>
        </div>

        <div className="full-heading grey-text flex m-0">
          <h2>discount</h2>
          <h2>AED 02.25</h2>
        </div>

        <div className="full-heading flex">
          <h2>order total</h2>
          <h2>AED 64.25</h2>
        </div>
        <div className="padd-div">
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="text"
                placeholder="Enter Your Room # (Optional)"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicname">
              <Form.Control
                type="text"
                placeholder="Enter Your Name (Optional)"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicemail">
              <Form.Control
                type="email"
                placeholder="Enter Your Email (Optional)"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicname">
              <Form.Control
                type="text"
                placeholder="Enter Your Number (Optional)"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicname">
              <Form.Control
                type="text"
                placeholder="note/special request (Optional)"
              />
            </Form.Group>
          </Form>
        </div>
        <div className="full-heading flex">
          <h2>Payment Method</h2>
        </div>
        <PaymentOptionList />
        <div className="padd-div d-flex mb-3">
          <Form.Check type="checkbox" label="" />
          <p>
            I agree to <Link>Terms & Conditions</Link>
          </p>
        </div>
        <div className="padd-div text-center">
          <Link className="reg-btn" to="/PharmacyOrderPlaced">
            order
          </Link>
        </div>
      </main>
    </>
  );
}
export default SupermarketOrderPayment;
